const items = [
  {
    latin_name: "Acacia baileyana 'Purpurea'",
    common_name: "Purple-Leaf Acacia",
    family_name: "Fabaceae",
    plantType: "Tree (evergreen)",
    bloomTime: "Spring",
    flower_color: "Yellow",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    associated_wildlife: "Birds;Butterflies",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "2 (B)",
    additional_characteristices_notes: "Fast grower, high maintenance.",
    photocredit01:
      "SelecTree. \"Acacia baileyana 'Purpurea' Photo Record.\" 1995-2012. Jul 3, 2014.",
    photocredit02:
      "SelecTree. \"Acacia baileyana 'Purpurea' Photo Record.\" 1995-2012. Jul 3, 2014.",
    photocredit03:
      "SelecTree. \"Acacia baileyana 'Purpurea' Photo Record.\" 1995-2012. Jul 3, 2014.",
    photocredit04:
      "SelecTree. \"Acacia baileyana 'Purpurea' Photo Record.\" 1995-2012. Jul 3, 2014.",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Acer buergeranum",
    common_name: "Trident Maple",
    family_name: "Sapindaceae Aceraceae",
    plantType: "Tree (deciduous)",
    bloomTime: "Spring",
    sizeAtMaturity: "13-24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    waterNeeds: "Low",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "2 (B)",
    additional_characteristices_notes: "Prefers heat, wind protection.",
    photocredit01:
      'SelecTree. "Acer buergerianum Photo Record." 1995-2012. Jul 3, 2014.',
    photocredit02:
      'SelecTree. "Acer buergerianum Photo Record." 1995-2012. Jul 3, 2014.',
    photocredit03:
      'SelecTree. "Acer buergerianum Photo Record." 1995-2012. Jul 3, 2014.',
    photocredit04:
      'SelecTree. "Acer buergerianum Photo Record." 1995-2012. Jul 3, 2014.',
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Acer macrophyllum",
    common_name: "Bigleaf Maple",
    family_name: "Sapindaceae",
    plantType: "Tree (deciduous)",
    bloomTime: "Spring",
    flower_color: "Orange;Red;Yellow",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Riparian",
    suitableSiteConditions: "Sun;Shade",
    soil_type: "Clay;Loam;Sand;Rock",
    waterNeeds: "Moderate",
    habitat_value: "Pollinator;Nesting",
    associated_wildlife: "Bees;Birds;Butterflies",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "18",
    street_tree_list: "NA",
    additional_characteristices_notes: "Provides shelter for wildlife",
    photocredit01:
      "J. E.(Jed) and Bonnie McClellan © California Academy of Sciences",
    photocredit02: "Charles Webber © California Academy of Sciences",
    photocredit03: "©2003 Barry Breckling (CC BY-NC-SA 3.0)",
    photocredit04: "Walter Siegmund (CC BY-SA 3.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Acer negundo",
    common_name: "Box Elder",
    family_name: "Sapindaceae",
    plantType: "Tree (deciduous)",
    bloomTime: "Spring",
    flower_color: "Pink",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Chaparral;Grassland/prairie;Riparian;Woodland",
    suitableSiteConditions: "Sun;Shade",
    soil_type: "Clay;Loam;Sand;Rock",
    waterNeeds: "Moderate",
    habitat_value: "Buds/greens;Nesting",
    associated_wildlife: "Bees;Birds;Butterflies;Insects",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes: "Withstands wind.",
    photocredit01: "©2005 Sten Porse (CC BY-SA 3.0)",
    photocredit02: "©2013 Jean Pawek",
    photocredit03: "©2006 Louis-M. Landry",
    photocredit04: "©Max Licher",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Acer rubrum",
    common_name: "Red Maple",
    family_name: "Sapindaceae",
    plantType: "Tree (deciduous)",
    bloomTime: "Spring",
    flower_color: "Red",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Moderate",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "2 (B)",
    additional_characteristices_notes: "Prefers heat, wind protection",
    photocredit01:
      'SelecTree. "Acer rubrum Photo Record." 1995-2012. Jul 3, 2014.',
    photocredit02:
      'SelecTree. "Acer rubrum Photo Record." 1995-2012. Jul 3, 2014.',
    photocredit03:
      'SelecTree. "Acer rubrum Photo Record." 1995-2012. Jul 3, 2014.',
    photocredit04:
      'SelecTree. "Acer rubrum Photo Record." 1995-2012. Jul 3, 2014.',
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Achillea millefolium",
    common_name: "Yarrow",
    family_name: "Sapindaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "White",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Coastal scrub;Grassland/prairie;Dunes",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand;Rock",
    waterNeeds: "None",
    habitat_value: "Buds/greens;Pollinator",
    associated_wildlife: "Bees;Butterflies;Insects",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden;Sidewalk;Roof",
    suggested_green_connection_routes: "6",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Recommended.  Travels by runners, self sowing, and can go anywhere.",
    nurseries:
      "Oaktown;Native Here;Mission Blue;Candlestick;Flora Grubb;Bay Natives;East Bay Wilds;Sutro Stewards;Watershed Nursery;",
    photocredit01: "Charles Webber © California Academy of Sciences",
    photocredit02: "©2001 Jeff Abbas",
    photocredit03: "Charles Webber © California Academy of Sciences",
    photocredit04: "© Br. Alfred Brousseau, Saint Mary's College",
    special_list: "Sandy Soil;Top 20",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1",
    super60: "Y",
    super60_int: "1",
    top20_int: "1",
    sandy_soil: "Sandy Soil",
    sandy_soil_int: "1"
  },
  {
    latin_name: "Acmispon glaber",
    common_name: "Deerweed",
    family_name: "Fabaceae",
    former_latin_name: "Lotus scoparius",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "Red;Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Coastal scrub;Dunes",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand;Rock",
    waterNeeds: "None",
    habitat_value: "Buds/greens;Cover;Fruit;Pollinator",
    associated_wildlife: "Butterflies;bees",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden;Roof",
    suggested_green_connection_routes: "21",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Practical and attractive plant. Attracts Black-tailed jackrabbit, Grey Hairstreak (Strymon melinus), and Green Hairstreak (Callophrys viridis) butterflies. Grows in disturbed soil.",
    photocredit01: "©2012 Aaron Arthur",
    photocredit02: "©2013 Debra L. Cook",
    photocredit03: "©2011 Barry Breckling (CC BY-NC-SA 3.0)",
    photocredit04: "©2013 Debra L. Cook",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Aesculus californica",
    common_name: "California Buckeye",
    family_name: "Sapindaceae",
    plantType: "Tree (deciduous)",
    bloomTime: "Summer",
    flower_color: "White;Pink",
    sizeAtMaturity: "13-24 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Riparian;Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand;Rock",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees;Birds;Butterflies;Hummingbirds;Insects",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "11;18",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Recommended where there is space (ie: Palou Phelps park).  Attracts Western Tiger Swallowtail (Papilio rutulus) butterfly.",
    nurseries:
      "Oaktown;Native Here;Mission Blue;Candlestick;Flora Grubb;Bay Natives;East Bay Wilds;Watershed Nursery;",
    photocredit01: "Gladys Lucille Smith © California Academy of Sciences",
    photocredit02: "William R. Hewlett © California Academy of Sciences",
    photocredit03:
      'SelecTree. "Aesculus californica Tree Record." 1995-2014. Feb 7, 2014',
    photocredit04: "©1999 James B. Gratiot",
    habitat: "Yes",
    habitat_int: "1",
    super60: "Y",
    super60_int: "1"
  },
  {
    latin_name: "Aesculus x carnea",
    common_name: "Red Horsechestnut",
    family_name: "Sapindaceae",
    plantType: "Tree (deciduous)",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    waterNeeds: "Low",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "2 (B)",
    additional_characteristices_notes:
      "gets windburn easily in summer even in protected sites.",
    photocredit01:
      'SelecTree. "Aesculus × carnea Photo Record." 1995-2012. Jul 3, 2014.',
    photocredit02:
      'SelecTree. "Aesculus × carnea Photo Record." 1995-2012. Jul 3, 2014.',
    photocredit03:
      'SelecTree. "Aesculus × carnea Photo Record." 1995-2012. Jul 3, 2014.',
    photocredit04:
      'SelecTree. "Aesculus × carnea Photo Record." 1995-2012. Jul 3, 2014.',
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Agonis flexuosa",
    common_name: "Peppermint Tree",
    family_name: "Myrtaceae",
    plantType: "Tree (evergreen)",
    bloomTime: "Summer",
    flower_color: "White",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam",
    waterNeeds: "None",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "1 (A)",
    additional_characteristices_notes:
      "Fast grower that requires extra tree care.",
    photocredit01:
      'SelecTree. "Agonis flexuosa Photo Record." 1995-2012. Jul 3, 2014.',
    photocredit02:
      'SelecTree. "Agonis flexuosa Photo Record." 1995-2012. Jul 3, 2014.',
    photocredit03:
      'SelecTree. "Agonis flexuosa Photo Record." 1995-2012. Jul 3, 2014.',
    photocredit04:
      'SelecTree. "Agonis flexuosa Photo Record." 1995-2012. Jul 3, 2014.',
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Agonis flexuosa  'After Dark'",
    common_name: "After Dark Peppermint Tree",
    family_name: "Myrtaceae",
    plantType: "Tree (evergreen)",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "None",
    associated_wildlife: "Bees;Butterflies;Birds",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "2 (B)",
    additional_characteristices_notes:
      "‘After Dark’ is a fast grower that requires extra tree care.",
    photocredit01: "Bri Weldon (CC BY 2.0)",
    photocredit02: "Jennifer de Graaf (CC BY-NC 2.0)",
    photocredit03: "Bri Weldon (CC BY 2.0)",
    photocredit04: "Jennifer de Graaf (CC BY-NC 2.0)",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Alcea rosea",
    common_name: "Hollyhock",
    family_name: "Malvaceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "Orange;Pink;Purple;Red;White;Yellow",
    sizeAtMaturity: "7-12 ft",
    climate_appropriate_plants: "Other",
    suitableSiteConditions: "Sun",
    soil_type: "Loam;Sand",
    waterNeeds: "Low",
    habitat_value: "Buds/greens;Pollinator",
    associated_wildlife: "Bees;Butterflies;Hummingbirds",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "16",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Attracts West Coast Painted Lady (Vanessa annabella) butterfly.",
    photocredit01: "©2011 Nick Kurzenko",
    photocredit02: "©2002 California Academy of Sciences",
    photocredit03: "©2011 Nick Kurzenko",
    photocredit04: "©2005 Luigi Rignanese (CC BY-NC 3.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Alnus rhombifolia",
    common_name: "White Alder",
    family_name: "Betulaceae",
    plantType: "Tree (deciduous)",
    bloomTime: "Fall",
    flower_color: "Yellow",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Riparian",
    suitableSiteConditions: "Sun;Shade",
    soil_type: "Clay;Loam;Sand;Rock",
    waterNeeds: "Moderate",
    habitat_value: "Pollinator",
    associated_wildlife: "Birds;Butterflies;Hummingbirds",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes: "Streamside plant.",
    photocredit01: "©Cal Poly San Luis Obispo",
    photocredit02: "©2011 BonTerra Consulting",
    photocredit03: "©2008 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit04: "©2012 Daniel Passarini (CC BY-NC-SA 3.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Amelanchier spp.",
    common_name: "Western Service Berry",
    family_name: "Rosaceae",
    plantType: "Shrub (deciduous)",
    bloomTime: "Summer",
    flower_color: "White",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Coastal Scrub",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Rock",
    waterNeeds: "Low",
    habitat_value: "Fruit;Pollinator",
    associated_wildlife: "Bees;Birds;Butterflies;Insects",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "17",
    street_tree_list: "NA",
    photocredit01: "©2008 George W. Hartwell",
    photocredit02: "©2014 John Doyen",
    photocredit03: "©2010 Vernon Smith (CC BY-NC-ND 3.0)",
    photocredit04: "©2007 Matt Below",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Amsinckia spectabilis",
    common_name: "Coast Fiddleneck",
    family_name: "Boraginaceae",
    plantType: "Perennial",
    bloomTime: "Spring",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Coastal scrub;Dunes",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand;Rock",
    waterNeeds: "None",
    habitat_value: "Buds/greens;Pollinator",
    associated_wildlife: "Bees;Birds;Butterflies;Insects",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Easy to grow from seed. Attracts Painted Lady (Vanessa cardui) butterfly.",
    nurseries: "Oaktown;Bay Natives;East Bay Wilds;",
    photocredit01: "©2008 Jorg & Mimi Fleige (CC BY-NC 3.0)",
    photocredit02: "©2010 Steven Thorsted (CC BY-NC 3.0)",
    photocredit03: "©2012 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit04: "©2010 Steven Thorsted (CC BY-NC 3.0)",
    special_list: "Sandy Soil",
    habitat: "Yes",
    habitat_int: "1",
    super60: "Y",
    super60_int: "1",
    sandy_soil: "Sandy Soil",
    sandy_soil_int: "1"
  },
  {
    latin_name: "Anaphalis margaritacea",
    common_name: "Pearly Everlasting",
    family_name: "Asteraceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "White;Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Coastal scrub;Dunes;Woodland",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand;Rock",
    waterNeeds: "None",
    habitat_value: "Buds/greens;Pollinator",
    associated_wildlife: "Bees;Birds;Butterflies;Insects",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Recommended root runner plant (also gumplant, goldenrod, dudleya). Attracts American Lady (Vanessa virginensis) and Painted Lady (Vanessa cardui) butterflies.",
    nurseries:
      "Oaktown;Mission Blue;Candlestick;Bay Natives;East Bay Wilds;Sutro Stewards;Watershed Nursery;",
    photocredit01: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit02: "©2008 Margo Bors",
    photocredit03: "©2008 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit04: "©2011 Jean Pawek",
    special_list: "Sandy Soil",
    habitat: "Yes",
    habitat_int: "1",
    super60: "Y",
    super60_int: "1",
    sandy_soil: "Sandy Soil",
    sandy_soil_int: "1"
  },
  {
    latin_name: "Angelica hendersonii",
    common_name: "Coast Angelica",
    family_name: "Apiaceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "White",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Coastal scrub",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "None",
    habitat_value: "Buds/greens;Pollinator",
    associated_wildlife: "Bees;Birds;Butterflies;Insects",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Recommended. Big robust leaves, but not weedy. Attracts Anise swallowtail (Papilio zelicaon) butterfly.",
    photocredit01: "©2012 Aaron Arthur",
    photocredit02: "©2012 Aaron Arthur",
    photocredit03: "©2012 Aaron Arthur",
    photocredit04: "©2009 Jorg & Mimi Fleige (CC BY-NC 3.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Aquilegia formosa",
    common_name: "Western Columbine",
    family_name: "Ranunculaceae",
    plantType: "Perennial",
    bloomTime: "Spring",
    flower_color: "Red;Yellow",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Coastal scrub;Woodland;Riparian",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Rock",
    waterNeeds: "Low",
    habitat_value: "Buds/greens;Pollinator",
    associated_wildlife: "Bees;Birds;Butterflies;Hummingbirds;Insects",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes: "Attracts Anna's Hummingbird.",
    photocredit01: "Charles Webber © California Academy of Sciences",
    photocredit02: "brewbooks (CC BY-SA 2.0)",
    photocredit03: "©2002 Charles E. Jones",
    photocredit04: "Charles Webber © California Academy of Sciences",
    special_list: "Shady Clay",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1",
    shady_clay: "Shady Clay",
    shady_clay_int: "1"
  },
  {
    latin_name: "Arbutus menziesii",
    common_name: "Madrone",
    family_name: "Ericaceae",
    plantType: "Tree (evergreen)",
    bloomTime: "Spring",
    flower_color: "White",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Woodland",
    suitableSiteConditions: "Sun;Shade",
    soil_type: "Clay;Loam;Rock",
    pruning_needs: "Structural pruning",
    waterNeeds: "Low",
    habitat_value: "Cover;Fruit;Pollinator;Nesting",
    associated_wildlife: "Bees;Birds;Butterflies;Hummingbirds",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes: "Peeling red bark.",
    photocredit01: "© Br. Alfred Brousseau, Saint Mary's College.",
    photocredit02: "© Br. Alfred Brousseau, Saint Mary's College.",
    photocredit03: "©2013 Lynn Watson",
    photocredit04: "©2013 Jean Pawek",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Arbutus x 'Marina'",
    common_name: "Marina Strawberry Tree",
    family_name: "Ericaceae",
    plantType: "Tree (evergreen)",
    bloomTime: "Fall;Winter",
    flower_color: "Pink",
    sizeAtMaturity: "13-24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    associated_wildlife: "Birds; Butterflies",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "1 (A)",
    additional_characteristices_notes:
      "Fruit drop can range from low volume to significant.",
    photocredit01: "Wendy Cutler (CC BY 2.0)",
    photocredit02: "Wendy Cutler (CC BY 2.0)",
    photocredit03: "Anna Fuster (CC BY-NC-ND 2.0)",
    photocredit04: "Martin LaBar (CC BY-NC 2.0)",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Aristolochia californica",
    common_name: "Dutchman's Pipevine",
    family_name: "Aristolochiaceae",
    plantType: "Vine",
    bloomTime: "Spring;Winter",
    flower_color: "Burgundy",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Coastal scrub;Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Low",
    habitat_value: "Buds/greens;Cover;Pollinator",
    associated_wildlife: "Butterflies;Insects",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Slow grower. Attracts Pipe Vine Swallowtail (Battus philenor).",
    nurseries:
      "Oaktown;Native Here;Mission Blue;Bay Natives;East Bay Wilds;Sutro Stewards;Watershed Nursery;",
    photocredit01: "©2011 Steven Perry",
    photocredit02: "©2013 Joshua McDill",
    photocredit03: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit04: "©2012 Julie Kierstead Nelson",
    special_list: "Shady Clay",
    habitat: "Yes",
    habitat_int: "1",
    super60: "Y",
    super60_int: "1",
    shady_clay: "Shady Clay",
    shady_clay_int: "1"
  },
  {
    latin_name: "Armeria maritima var. californica",
    common_name: "Sea Thrift",
    family_name: "Plumbaginaceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "Pink",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Coastal scrub;Dunes",
    suitableSiteConditions: "Sun",
    soil_type: "Loam;Rock;Sand",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden;Sidewalk;Roof",
    suggested_green_connection_routes: "15",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Not recommended for east side planting (strictly sand dune plant), may need additional moisture in a garden environment. Attracts Green Hairstreak (Callophrys viridis).",
    nurseries:
      "Oaktown;Candlestick;Flora Grubb;Bay Natives;East Bay Wilds;Sutro Stewards;Watershed Nursery;",
    photocredit01: "©2009 Ron Wolf",
    photocredit02: "©2006 Matt Below",
    photocredit03: "©2009 Ron Wolf",
    photocredit04: "©2009 Ron Wolf",
    special_list: "Sandy Soil",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1",
    super60: "Y",
    super60_int: "1",
    sandy_soil: "Sandy Soil",
    sandy_soil_int: "1"
  },
  {
    latin_name: "Artemisia californica",
    common_name: "California Sagebrush",
    family_name: "Asteraceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Coastal scrub",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand;Rock",
    waterNeeds: "None",
    habitat_value: "Cover;Nesting",
    associated_wildlife: "Bees;Birds;Butterflies",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden;Sidewalk;Roof",
    suggested_green_connection_routes: "20",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Resilient and good visual. Attracts Nutall's White-Crowned Sparrow (Zonotrichia leucophrys nuttalli).",
    nurseries:
      "Oaktown;Native Here;Mission Blue;Flora Grubb;Bay Natives;East Bay Wilds;Sutro Stewards;Watershed Nursery;",
    photocredit01: "©2004 BonTerra Consulting",
    photocredit02: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit03: "©2003 Michael Charters",
    photocredit04: "©2008 Jorg Fleige",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1",
    super60: "Y",
    super60_int: "1"
  },
  {
    latin_name: "Artemisia pycnocephala",
    common_name: "Dune Sagewort",
    family_name: "Asteraceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Dunes",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand;Rock",
    waterNeeds: "None",
    habitat_value: "Cover;Nesting",
    associated_wildlife: "Bees",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden;Sidewalk;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Excellent visually, but limited to coastal sand dunes.",
    nurseries:
      "Oaktown;Mission Blue;Candlestick;Flora Grubb;Bay Natives;East Bay Wilds;Sutro Stewards;Watershed Nursery;",
    photocredit01: "©2006 Matt Below",
    photocredit02: "©2005 Will Chatfield-Taylor",
    photocredit03: "©2011 Dylan Neubauer",
    photocredit04: "©2013 Neal Kramer",
    special_list: "Sandy Soil",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1",
    super60: "Y",
    super60_int: "1",
    sandy_soil: "Sandy Soil",
    sandy_soil_int: "1"
  },
  {
    latin_name: "Asarum caudatum",
    common_name: "Wild Ginger",
    family_name: "Aristolochiaceae",
    plantType: "Perennial",
    bloomTime: "Spring",
    flower_color: "Burgundy",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Woodland",
    suitableSiteConditions: "Part Shade;Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Moderate",
    habitat_value: "Cover",
    associated_wildlife: "No data",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit02: "Beatrice F. Howitt © California Academy of Sciences",
    photocredit03: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit04: "© Br. Alfred Brousseau, Saint Mary's College",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Asclepias fascicularis",
    common_name: "Narrowleaf Milkweed",
    family_name: "Apocynaceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "Pink;White",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Chaparral;Riparian;Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand;Rock",
    waterNeeds: "Low",
    habitat_value: "Buds/greens;Pollinator",
    associated_wildlife: "Bees;Birds;Butterflies;Insects",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "1",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Spreads easily, and may need confinement. Keep away from children/pets. Speciosa may be preferable. Attracts Monarch butterfly (Danaus plexippus).",
    photocredit01: "©2002 George Jackson",
    photocredit02: "©2003 Michael Charters",
    photocredit03:
      "J. E.(Jed) and Bonnie McClellan © California Academy of Sciences",
    photocredit04: "©2003 Michael Charters",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Cirsium occidentale var. occidentale",
    common_name: "Cobweb Thistle",
    family_name: "Asteraceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "Pink",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Coastal scrub;Dunes;Grassland/prairie;Woodland",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam",
    waterNeeds: "Low",
    habitat_value: "Buds/greens;Pollinator",
    associated_wildlife: "Bees;Birds;Butterflies;Hummingbirds;Insects",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "21",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Very temperamental plant. Attracts Mylitta Crescent (Phyciodes mylitta), California Crescent (Phyciodes orseis), and Painted Lady (Vanessa cardui) butterflies.",
    photocredit01: "©2009 Dr. Mark S. Brunell",
    photocredit02: "©2013 Robert Steers/NPS",
    photocredit03: "©2009 Dr. Mark S. Brunell",
    photocredit04: "© Br. Alfred Brousseau, Saint Mary's College",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Baccharis pilularis",
    common_name: "Coyotebrush",
    family_name: "Asteraceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Fall",
    flower_color: "White",
    sizeAtMaturity: "7-12 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Coastal scrub;Dunes",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand;Rock",
    waterNeeds: "None",
    habitat_value: "Cover;Buds/greens;Pollinator;Nesting",
    associated_wildlife: "Bees;Birds;Butterflies;Insects",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "12;17;21;22;23;5;8",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Amazing for wildlife. Attracts Western fence lizard, Nutall's White-crowned Sparrow, Gray Hairstreak (Strymon melinus), and Common Buckeye (Junonia coeni).",
    nurseries:
      "Oaktown;Native Here;Mission Blue;Candlestick;Flora Grubb;Bay Natives;East Bay Wilds;Sutro Stewards;Watershed Nursery;",
    photocredit01: "©2002 Lynn Watson",
    photocredit02: "©2004 David A. Tharp",
    photocredit03: "©1977 Lee Dittmann",
    photocredit04: "©1996 Margo Bors",
    special_list: "Sandy Soil;Top 20",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1",
    super60: "Y",
    super60_int: "1",
    top20_int: "1",
    sandy_soil: "Sandy Soil",
    sandy_soil_int: "1"
  },
  {
    latin_name: "Berberis pinnata",
    common_name: "Coast Barberry",
    family_name: "Berberidaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring",
    flower_color: "Yellow",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Woodland;Coastal scrub",
    suitableSiteConditions: "Part Shade",
    soil_type: "Clay",
    waterNeeds: "Low",
    habitat_value: "Buds/greens;Pollinator",
    associated_wildlife: "Bees;Birds",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Attracts Cedar Waxwing (Bombycilla cedrorum) bird.",
    photocredit01: "©2013 Margo Bors",
    photocredit02: "©2013 Terry Gosliner (CC BY-NC 3.0)",
    photocredit03: "Stan Shebs (CC BY-SA 3.0)",
    photocredit04: "©2008 Jorg Fleige (CC BY-NC 3.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Bolboschoenus robustus",
    common_name: "Alkali Bulrush / Sturdy Bulrush",
    family_name: "Cyperaceae",
    former_latin_name: "Scirpus robustus",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "Brown",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Riparian;Wetland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Moderate",
    habitat_value: "Fruit;Nesting",
    associated_wildlife: "Birds",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2013 Jake Ruygt",
    photocredit02: "©2013 Jake Ruygt",
    photocredit03: "©2011 Sangeet Khalsa",
    photocredit04: "©2011 Sangeet Khalsa",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Brahea edulis",
    common_name: "Guadalupe Palm",
    family_name: "Arecaceae",
    plantType: "Tree (evergreen)",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "2 (B)",
    additional_characteristices_notes: "Very slow growing in San Francisco.",
    photocredit01:
      'SelecTree. "Brahea edulis Photo Record." 1995-2012. Jul 3, 2014.',
    photocredit02:
      'SelecTree. "Brahea edulis Photo Record." 1995-2012. Jul 3, 2014.',
    photocredit03:
      'SelecTree. "Brahea edulis Photo Record." 1995-2012. Jul 3, 2014.',
    photocredit04:
      'SelecTree. "Brahea edulis Photo Record." 1995-2012. Jul 3, 2014.',
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Bromus carinatus ",
    common_name: "California Brome",
    family_name: "Poaceae",
    plantType: "Grass",
    bloomTime: "Winter",
    flower_color: "Yellow",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities:
      "Chaparral;Coastal scrub;Dunes;Grassland/prairie;Woodland",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand;Rock",
    waterNeeds: "None",
    habitat_value: "Cover;Fruit;Nesting",
    associated_wildlife: "Birds;Butterflies",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "17",
    street_tree_list: "NA",
    additional_characteristices_notes: "Attracts Umber Skipper butterfly.",
    photocredit01: "©2011 Ryan Batten",
    photocredit02: "©2011 Ryan Batten",
    photocredit03: "©2012 Jean Pawek",
    photocredit04: "©2012 Jean Pawek",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Bromus laevipes",
    common_name: "Woodland Brome",
    family_name: "Poaceae",
    plantType: "Grass",
    bloomTime: "Summer",
    flower_color: "Green",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Chaparral;Grassland/prairie;Woodland",
    suitableSiteConditions: "Shade",
    soil_type: "Clay;Loam;Sand;Rock",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Birds",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Needs to be planted with other grasses.",
    photocredit01: "©2012 Jake Ruygt",
    photocredit02: "©2013 Margo Bors",
    photocredit03: "©2005 Steve Matson",
    photocredit04: "©2011 Alice Cummings",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Calamagrostis nutkaensis",
    common_name: "Pacific Reedgrass",
    family_name: "Poaceae",
    plantType: "Grass",
    bloomTime: "Spring",
    flower_color: "Pink;White",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Wetland;Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay",
    waterNeeds: "Low",
    habitat_value: "Buds/greens",
    associated_wildlife: "Insects",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Needs fog and moisture, and tolerates wind well.",
    photocredit01: "©2008 Neal Kramer",
    photocredit02: "©2006 Tom Annese",
    photocredit03: "©2012 Asa Spade",
    photocredit04: "©2012 Aaron E. Sims",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Callistemon citrinus",
    common_name: "Lemon Bottlebrush",
    family_name: "Myrtaceae",
    plantType: "Tree (evergreen)",
    bloomTime: "Spring;Summer",
    flower_color: "Red",
    sizeAtMaturity: "13-24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Birds;Hummingbirds;Butterflies;Bees",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "2 (B)",
    additional_characteristices_notes:
      "Grows low, and wide canopy and needs a wide sidewalk, sticky flowers.",
    photocredit01:
      'SelecTree. "Callistemon citrinus Photo Record." 1995-2012. Jul 3, 2014.',
    photocredit02:
      'SelecTree. "Callistemon citrinus Photo Record." 1995-2012. Jul 3, 2014.',
    photocredit03:
      'SelecTree. "Callistemon citrinus Photo Record." 1995-2012. Jul 3, 2014.',
    photocredit04:
      'SelecTree. "Callistemon citrinus Photo Record." 1995-2012. Jul 3, 2014.',
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Callistemon viminalis",
    common_name: "Weeping Bottlebrush",
    family_name: "Myrtaceae",
    plantType: "Tree (evergreen)",
    bloomTime: "Spring;Summer",
    flower_color: "red",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    waterNeeds: "Low",
    associated_wildlife: "Birds;Insects;Hummingbirds;Bees",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "1 (A)",
    additional_characteristices_notes: "Has sticky flowers.",
    photocredit01:
      'SelecTree. "Callistemon viminalis Photo Record." 1995-2012. Jul 3, 2014.',
    photocredit02:
      'SelecTree. "Callistemon viminalis Photo Record." 1995-2012. Jul 3, 2014.',
    photocredit03:
      'SelecTree. "Callistemon viminalis Photo Record." 1995-2012. Jul 3, 2014.',
    photocredit04:
      'SelecTree. "Callistemon viminalis Photo Record." 1995-2012. Jul 3, 2014.',
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Calycanthus occidentalis",
    common_name: "Spice Bush",
    family_name: "Calycanthaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Summer",
    flower_color: "Burgundy",
    sizeAtMaturity: "7-12 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Wetland;Woodland",
    suitableSiteConditions: "Sun;Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    habitat_value: "Buds/greens",
    associated_wildlife: "Birds;Insects",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "Gerald and Buff Corsi © California Academy of Sciences",
    photocredit02: "Gladys Lucille Smith © California Academy of Sciences",
    photocredit03: "©2009 Barry Breckling (CC BY-NC-SA 3.0)",
    photocredit04: "©2013 Neal Kramer",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Calystegia soldanella",
    common_name: "Beach Morning Glory",
    family_name: "Convolvulaceae",
    plantType: "Perennial",
    bloomTime: "Spring",
    flower_color: "Pink",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Dunes",
    suitableSiteConditions: "Sun",
    soil_type: "Sand",
    waterNeeds: "None",
    habitat_value: "Buds/greens",
    associated_wildlife: "Insects",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Needs dunes and shifting sand, most likely by ocean beach.",
    photocredit01: "©1997 Christopher L. Christie",
    photocredit02: "©1998 Nick Kurzenko",
    photocredit03: "©2007 Bradford Norman",
    photocredit04: "©2005 George W. Hartwell",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Carex pansa",
    common_name: "Pacific Dune Sedge",
    family_name: "Cyperaceae",
    plantType: "Perennial",
    bloomTime: "Spring",
    flower_color: "Brown",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Dunes",
    suitableSiteConditions: "Sun",
    soil_type: "Sand",
    waterNeeds: "Moderate",
    associated_wildlife: "No data",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden;Roof",
    suggested_green_connection_routes: "9",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Practical plant that is a dry grower and needs sand dunes. Can form a tight, tough mat.",
    photocredit01: "©David Amme",
    photocredit02: "©2003 Steve Matson",
    photocredit03: "©2014 DryStoneGarden",
    photocredit04: "©Hedgerow Farms",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Carex praegracilis",
    common_name: "Clustered Field Sedge",
    family_name: "Cyperaceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "Green",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Wetland",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand;Rock",
    pruning_needs: "Cut to ground",
    waterNeeds: "Moderate",
    habitat_value: "Nesting",
    associated_wildlife: "Birds;Butterflies",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Suitable lawn alternative, and can tolerate standing water.",
    photocredit01: "©2004 James M. Andre",
    photocredit02: "©2008 Steve Matson",
    photocredit03: "©2008 Steve Matson",
    photocredit04: "©2008 Steve Matson",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Castilleja ambigua",
    common_name: "Salt Marsh Owl’s-clover",
    family_name: "Orobanchaceae",
    former_latin_name: "Orthocarpus castillejoides",
    plantType: "Annual",
    bloomTime: "Summer",
    flower_color: "White;Yellow",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Riparian;Wetland",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Moderate",
    habitat_value: "Buds/greens;Pollinator",
    associated_wildlife: "Butterflies;Insects",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Hemiparasitic (Need root system of other plants for moisture and minerals) - favor composite family such as artemisia, sagebrush, coyotebush, pearly everlasting). Attracts Chalcedon Checkerspot (Euphydryas chalcedona) butterfly.",
    photocredit01: "©2005 Doreen L. Smith",
    photocredit02: "©2009 Jorg Fleige (CC BY-NC 3.0)",
    photocredit03: "©2005 Doreen L. Smith",
    photocredit04: "© Br. Alfred Brousseau, Saint Mary's College",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Cirsium quercetorum",
    common_name: "Brownie Thistle",
    family_name: "Asteraceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "White",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Grassland/prairie;Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Low",
    habitat_value: "Buds/greens;Pollinator",
    associated_wildlife: "Bees;Butterflies;Insects",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Short plant that spreads well by runners. Attracts Mylitta Crescent (Phyciodes mylitta), California Crescent (Phyciodes orseis), and Painted Lady (Vanessa cardui) butterflies.",
    photocredit01: "©2007 Neal Kramer",
    photocredit02: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit03: "©2010 Zoya Akulova (CC BY-NC 3.0)",
    photocredit04: "©2013 James Gonsman",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Castilleja latifolia",
    common_name: "Monterey Indian Paintbrush",
    family_name: "Orobanchaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "Orange;Red",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Coastal scrub;Grassland/prairie;Dunes",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    habitat_value: "Buds/greens;Pollinator",
    associated_wildlife: "Butterflies;Insects",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Hemiparasitic (Need root system of other plants for moisture and minerals) - favor composite family such as artemisia, sagebrush, coyotebush, pearly everlasting). Attracts Chalcedon Checkerspot (Euphydryas chalcedona) butterfly.",
    photocredit01: "©2010 Steven Thorsted (CC BY-NC 3.0)",
    photocredit02: "Gerald and Buff Corsi © California Academy of Sciences",
    photocredit03: "©Joseph Dougherty, M.D./ecology.org",
    photocredit04: "©2010 Steven Thorsted (CC BY-NC 3.0)",
    special_list: "Sandy Soil",
    habitat: "Yes",
    habitat_int: "1",
    sandy_soil: "Sandy Soil",
    sandy_soil_int: "1"
  },
  {
    latin_name: "Castilleja subinclusa ssp. franciscana",
    common_name: "Franciscan Paintbrush",
    family_name: "Orobanchaceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "Orange;Red",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Coastal scrub;Grassland/prairie;Woodland",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Rock",
    waterNeeds: "Low",
    habitat_value: "Buds/greens;Pollinator",
    associated_wildlife: "Butterflies;Insects",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Hemiparasitic (Need root system of other plants for moisture and minerals) - favor composite family such as artemisia, sagebrush, coyotebush, pearly everlasting). Attracts Chalcedon Checkerspot (Euphydryas chalcedona) butterfly.",
    photocredit01: "©2013 Robert Sikora",
    photocredit02: "©2004 Sharon Salisbury",
    photocredit03: "©2013 Robert Sikora",
    photocredit04: "©2007 Neal Kramer",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Claytonia perfoliata",
    common_name: "Miner's Lettuce",
    family_name: "Montiaceae",
    plantType: "Annual",
    bloomTime: "Spring;Winter",
    flower_color: "Pink;White",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Chaparral;Coastal scrub;Woodland",
    suitableSiteConditions: "Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    habitat_value: "Buds/greens;Cover;Fruit;Pollinator",
    associated_wildlife: "Birds;Insects",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Prolific plant. Attracts Mourning Doves and California Quail.",
    photocredit01: "Albert P. Bekker © California Academy of Sciences",
    photocredit02: "©2010 Steven Thorsted (CC BY-NC 3.0)",
    photocredit03: "©2005 Charles E. Jones",
    photocredit04: "© Br. Alfred Brousseau, Saint Mary's College",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Castilleja wightii",
    common_name: "Wight's Paintbrush",
    family_name: "Orobanchaceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "Red;White;Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Coastal scrub;Grassland/prairie;Woodland",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    habitat_value: "Buds/greens;Pollinator",
    associated_wildlife: "Butterflies;Insects",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Hemiparasitic (Need root system of other plants for moisture and minerals) - favor composite family such as artemisia, sagebrush, coyotebush, pearly everlasting). Attracts Chalcedon Checkerspot (Euphydryas chalcedona) butterfly.",
    photocredit01: "©2008 Neal Kramer",
    photocredit02: "©2008 Neal Kramer",
    photocredit03: "©2008 Eric Wrubel",
    photocredit04: "©2012 Aaron Schusteff",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Ceanothus 'Ray Hartman'",
    common_name: "Ray Hartman Ceanothus",
    family_name: "Rhamnaceae",
    plantType: "Tree (evergreen)",
    bloomTime: "Spring",
    flower_color: "Blue",
    sizeAtMaturity: "13-24 ft",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    associated_wildlife: "Birds;Hummingbirds;Butterflies",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "11;17;20",
    street_tree_list: "1 (A)",
    photocredit01:
      "SelecTree. \"Ceanothus 'Ray Hartman' Photo Record.\" 1995-2012. Jul 3, 2014.",
    photocredit02:
      "SelecTree. \"Ceanothus 'Ray Hartman' Photo Record.\" 1995-2012. Jul 3, 2014.",
    photocredit03:
      "SelecTree. \"Ceanothus 'Ray Hartman' Photo Record.\" 1995-2012. Jul 3, 2014.",
    photocredit04:
      "SelecTree. \"Ceanothus 'Ray Hartman' Photo Record.\" 1995-2012. Jul 3, 2014.",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Ceanothus thyrsiflorus ",
    common_name: "Blueblossom",
    family_name: "Rhamnaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring",
    flower_color: "Blue",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Chaparral;Coastal scrub;Woodland",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam",
    pruning_needs: "Minimal",
    waterNeeds: "None",
    habitat_value: "Cover;Buds/greens;Pollinator",
    associated_wildlife: "Bees;Birds;Butterflies;Hummingbirds;Insects",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Fragrant flowers. Attracts Cedar Waxwing bird and Echo Blue (Celestrina ladon echo), California Tortoiseshell (Nymphalis californica) butterflies.",
    nurseries:
      "Oaktown;Mission Blue;Flora Grubb;Bay Natives;East Bay Wilds;Sutro Stewards;Watershed Nursery;",
    photocredit01: "Elaine with Grey Cats (CC BY-SA 2.0)",
    photocredit02: "M. Martin Vicente (CC BY 2.0)",
    photocredit03: "Leonora Enking (CC BY-SA 2.0)",
    photocredit04: "pépiniériste 85 (CC BY-NC-SA 2.0)",
    special_list: "Top 20",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1",
    super60: "Y",
    super60_int: "1",
    top20_int: "1"
  },
  {
    latin_name: "Celtis sinensis",
    common_name: "Chinese Hackberry",
    family_name: "Ulmaceae",
    plantType: "Tree (deciduous)",
    bloomTime: "Spring",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    associated_wildlife: "Birds;Butterflies",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "2 (B)",
    additional_characteristices_notes:
      "Prefers heat, wind protection, uneven performer.",
    photocredit01:
      'SelecTree. "Celtis sinensis Photo Record." 1995-2012. Jul 3, 2014.',
    photocredit02:
      'SelecTree. "Celtis sinensis Photo Record." 1995-2012. Jul 3, 2014.',
    photocredit03:
      'SelecTree. "Celtis sinensis Photo Record." 1995-2012. Jul 3, 2014.',
    photocredit04:
      'SelecTree. "Celtis sinensis Photo Record." 1995-2012. Jul 3, 2014.',
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Cercis occidentalis",
    common_name: "Redbud",
    family_name: "Fabaceae",
    plantType: "Tree (deciduous)",
    bloomTime: "Spring",
    flower_color: "Pink",
    sizeAtMaturity: "13-24 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Chaparral;Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand;Rock",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees;Birds;Hummingbirds",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©1994 Gary A. Monroe (CC BY-NC 3.0)",
    photocredit02: "©2000 Joseph Dougherty/ecology.org",
    photocredit03: "©2009 Barry Breckling (CC BY-NC-SA 3.0)",
    photocredit04: "©2008 Steve Matson",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Chlorogalum pomeridianum",
    common_name: "Soap Plant",
    family_name: "Agavaceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "White",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Chaparral;Coastal scrub;Grassland/prairie;Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    habitat_value: "Buds/greens;Pollinator",
    associated_wildlife: "Bees;Butterflies;Insects",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Tough plant that is easy to grow. Flowers only open in evening or very overcast weather.",
    nurseries:
      "Oaktown;Native Here;Mission Blue;Candlestick;Bay Natives;East Bay Wilds;Sutro Stewards;Watershed Nursery;",
    photocredit01: "©2009 Neal Kramer",
    photocredit02: "Beatrice F. Howitt © California Academy of Sciences",
    photocredit03: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit04: "©2004 George W. Hartwell",
    special_list: "Shady Clay",
    habitat: "Yes",
    habitat_int: "1",
    super60: "Y",
    super60_int: "1",
    shady_clay: "Shady Clay",
    shady_clay_int: "1"
  },
  {
    latin_name: "Cirsium brevistylum",
    common_name: "Indian Thistle",
    family_name: "Asteraceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "Indigo",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Coastal scrub;Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Rock",
    waterNeeds: "None",
    habitat_value: "Buds/greens;Pollinator",
    associated_wildlife: "Bees;Butterflies;Insects",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Attracts Mylitta Crescent (Phyciodes mylitta), California Crescent (Phyciodes orseis), and Painted Lady (Vanessa cardui) butterflies.",
    photocredit01: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit02: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit03: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit04:
      "J. E.(Jed) and Bonnie McClellan © California Academy of Sciences",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Cirsium occidentale var. californicum",
    common_name: "California Thistle",
    family_name: "Asteraceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "Indigo",
    sizeAtMaturity: "7-12 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Coastal scrub;Dunes;Grassland/prairie;Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand;Rock",
    waterNeeds: "None",
    habitat_value: "Buds/greens;Pollinator",
    associated_wildlife: "Bees;Butterflies;Insects",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden;Roof",
    suggested_green_connection_routes: "1",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Attracts Mylitta Crescent (Phyciodes mylitta), California Crescent (Phyciodes orseis), and Painted Lady (Vanessa cardui) butterflies.",
    photocredit01: "©2011 Jean Pawek",
    photocredit02: "Beatrice F. Howitt © California Academy of Sciences",
    photocredit03: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit04: "Gladys Lucille Smith © California Academy of Sciences",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Cirsium occidentale var. candidissimum",
    common_name: "Snowy Thistle",
    family_name: "Asteraceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "Magenta",
    sizeAtMaturity: "7-12 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Coastal scrub;Dunes;Grassland/prairie;Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand;Rock",
    waterNeeds: "None",
    habitat_value: "Buds/greens;Pollinator",
    associated_wildlife: "Bees;Butterflies;Insects",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Attracts Mylitta Crescent (Phyciodes mylitta), California Crescent (Phyciodes orseis), and Painted Lady (Vanessa cardui) butterflies.",
    photocredit01: "©2001 Gary A. Monroe (CC BY-NC 3.0)",
    photocredit02: "©2002 Gary A. Monroe (CC BY-NC 3.0)",
    photocredit03: "©1981 Gary A. Monroe (CC BY-NC 3.0)",
    photocredit04: "©2005 George W. Hartwell",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Cirsium occidentale var. compactum",
    common_name: "Compact Cobweb Thistle",
    family_name: "Asteraceae",
    plantType: "Perennial",
    bloomTime: "Spring",
    flower_color: "Magenta",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Coastal scrub;Dunes;Grassland/prairie;Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand;Rock",
    waterNeeds: "None",
    habitat_value: "Buds/greens;Pollinator",
    associated_wildlife: "Bees;Butterflies;Insects",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Rare: CNPS 1B.2 . Attracts Mylitta Crescent (Phyciodes mylitta), California Crescent (Phyciodes orseis), and Painted Lady (Vanessa cardui) butterflies.",
    photocredit01: "Gerald and Buff Corsi © California Academy of Sciences",
    photocredit02: "©1981 California Native Plant Society",
    photocredit03: "©1993 California Native Plant Society",
    photocredit04: "©2005 Laura Ann Eliassen",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Clarkia davyi",
    common_name: "Davy's Clarkia",
    family_name: "Onagraceae",
    plantType: "Annual",
    bloomTime: "Summer",
    flower_color: "Pink",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Coastal scrub;Dunes;Grassland/prairie",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees, Butterflies",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2013 John Doyen",
    photocredit02: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit03: "©2013 Vernon Smith (CC BY-NC-ND 3.0)",
    photocredit04: "©2013 Robert Sikora",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Clarkia purpurea",
    common_name: "Winecup Clarkia",
    family_name: "Onagraceae",
    plantType: "Annual",
    bloomTime: "Spring;Summer",
    flower_color: "Pink",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Coastal scrub;Dunes;Grassland/prairie",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees, Butterflies",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2010 Vernon Smith (CC BY-NC-ND 3.0)",
    photocredit02: "©2013 Joshua McDill (CC BY-NC-SA 3.0)",
    photocredit03: "©2005 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit04: "©2009 Gary A. Monroe (CC BY-NC 3.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Clarkia rubicunda",
    common_name: "Farewell to Spring",
    family_name: "Onagraceae",
    plantType: "Annual",
    bloomTime: "Summer",
    flower_color: "Pink",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Coastal scrub;Dunes;Grassland/prairie",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees, Butterflies",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    nurseries:
      "Oaktown;Native Here;Candlestick;Bay Natives;East Bay Wilds;Sutro Stewards;Watershed Nursery;",
    photocredit01: "©2009 Vernon Smith (CC BY-NC-ND 3.0)",
    photocredit02: "©2009 Barry Breckling (CC BY-NC-SA 3.0)",
    photocredit03: "©2011 Margo Bors",
    photocredit04: "©2011 Margo Bors",
    habitat: "Yes",
    habitat_int: "1",
    super60: "Y",
    super60_int: "1"
  },
  {
    latin_name: "Clarkia unguiculata",
    common_name: "Woodland Clarkia",
    family_name: "Onagraceae",
    plantType: "Annual",
    bloomTime: "Summer",
    flower_color: "Pink",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Coastal scrub;Dunes;Grassland/prairie",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees, Butterflies",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2001 George W. Hartwell",
    photocredit02: "©2011 Barry Breckling (CC BY-NC-SA 3.0)",
    photocredit03: "©2007 Chris Winchell",
    photocredit04: "©2012 Diane Beauton & Cynthia Nunn.",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Asplenium scolopendrium",
    common_name: "Hart's Tongue Fern",
    family_name: "Aspleniaceae",
    plantType: "Fern",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Part Shade;Shade",
    waterNeeds: "Low",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2011 Zoya Akulova",
    photocredit02: "©2011 Zoya Akulova",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Clematis ligusticifolia",
    common_name: "Western Virgin's Bower",
    family_name: "Ranunculaceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "White",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Wetland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Loam;Sand",
    waterNeeds: "Moderate",
    habitat_value: "Cover;Pollinator",
    associated_wildlife: "Butterflies;Hummingbirds",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2010 Barry Breckling (CC BY-NC-SA 3.0)",
    photocredit02: "©2010 Lee Dittmann",
    photocredit03: "©2010 Lee Dittmann",
    photocredit04: "Beatrice F. Howitt © California Academy of Sciences",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Clinopodium douglasii",
    common_name: "Yerba Buena",
    family_name: "Lamiaceae",
    former_latin_name: "Satureja douglasii",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "White",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Coastal scrub;Woodland",
    suitableSiteConditions: "Part Shade",
    soil_type: "Clay;Loam;Sand;Rock",
    pruning_needs: "Minimal",
    waterNeeds: "Low",
    habitat_value: "Cover",
    associated_wildlife: "No data",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Temperamental and may not persist. Fragrant foliage.",
    nurseries:
      "Oaktown;Native Here;Mission Blue;Candlestick;Flora Grubb;Bay Natives;East Bay Wilds;Sutro Stewards;Watershed Nursery;",
    photocredit01: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit02: "©2012 Jean Pawek",
    photocredit03: "©2012 Aaron Arthur",
    photocredit04: "©2012 Gary McDonald",
    special_list: "Shady Clay",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1",
    super60: "Y",
    super60_int: "1",
    shady_clay: "Shady Clay",
    shady_clay_int: "1"
  },
  {
    latin_name: "Collinsia heterophylla",
    common_name: "Chinese Houses",
    family_name: "Plantaginaceae",
    plantType: "Annual",
    bloomTime: "Spring;Summer",
    flower_color: "Purple",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Chaparral;Grassland/prairie;Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    habitat_value: "Buds/greens",
    associated_wildlife: "Bees;Butterflies;Insects",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Rare plant. Attracts Checkerspot Butterfly.",
    photocredit01: "©2009 Lynn Watson",
    photocredit02: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit03: "©2010 Neal Kramer",
    photocredit04: "©2008 Steve Matson",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Cordyline australis",
    common_name: "Cabbage Palm",
    family_name: "Asparagaceae",
    plantType: "Tree (evergreen)",
    bloomTime: "Summer",
    sizeAtMaturity: "13-24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    waterNeeds: "Low",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "2 (B)",
    additional_characteristices_notes:
      "Needs very large basin, take care when planting near natural areas.",
    photocredit01:
      'SelecTree. "Cordyline australis Photo Record." 1995-2012. Jul 3, 2014.',
    photocredit02:
      'SelecTree. "Cordyline australis Photo Record." 1995-2012. Jul 3, 2014.',
    photocredit03:
      'SelecTree. "Cordyline australis Photo Record." 1995-2012. Jul 3, 2014.',
    photocredit04:
      'SelecTree. "Cordyline australis Photo Record." 1995-2012. Jul 3, 2014.',
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Corethrogyne filaginifolia",
    common_name: "Common Sand Aster",
    family_name: "Asteraceae",
    former_latin_name: "Lessingia filaginifolia",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "Pink;Purple",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Chaparral;Coastal scrub;Dunes",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    habitat_value: "Buds/greens;Pollinator",
    associated_wildlife: "Bees;Butterflies;Insects",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2012 Robert Sikora",
    photocredit02: "©2013 Neal Kramer",
    photocredit03: "©2012 Robert Sikora",
    photocredit04: "©2012 Robert Sikora",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Cornus sericea",
    common_name: "American Dogwood",
    family_name: "Cornaceae",
    plantType: "Shrub (deciduous)",
    bloomTime: "Spring;Summer",
    flower_color: "White",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Wetland;Riparian",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand;Rock",
    waterNeeds: "Moderate",
    habitat_value: "Buds/greens;Cover;Pollinator",
    associated_wildlife: "Bees;Birds;Butterflies;Insects",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Attracts Sprin Azure / Echo Blue (Celastrina ladon echo) butterfly.",
    photocredit01: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit02: "Charles Webber © California Academy of Sciences",
    photocredit03: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit04: "©2012 Jean Pawek",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Cornus sericea ssp. occidentalis",
    common_name: "California Dogwood",
    family_name: "Cornaceae",
    plantType: "Shrub (deciduous)",
    bloomTime: "Summer",
    flower_color: "White",
    sizeAtMaturity: "7-12 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Wetland;Riparian",
    suitableSiteConditions: "Sun;Shade",
    soil_type: "Clay;Loam;Sand;Rock",
    waterNeeds: "Moderate",
    habitat_value: "Buds/greens",
    associated_wildlife: "Birds;Insects",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "11",
    street_tree_list: "NA",
    additional_characteristices_notes: "Attracts Cedar Waxwing bird.",
    photocredit01: "©2011 Aaron Arthur",
    photocredit02: "©2009 Barry Breckling (CC BY-NC-SA 3.0)",
    photocredit03: "©2011 Margo Bors",
    photocredit04: "©2011 Margo Bors",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Corylus cornuta",
    common_name: "California Hazelnut",
    family_name: "Betulaceae",
    plantType: "Tree (deciduous)",
    bloomTime: "Winter",
    flower_color: "Red",
    sizeAtMaturity: "7-12 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Coastal scrub;Woodland;Riparian",
    suitableSiteConditions: "Sun;Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    habitat_value: "Fruit",
    associated_wildlife: "Birds",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2009 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit02: "©2010 Barry Breckling (CC BY-NC-SA 3.0)",
    photocredit03: "Charles Webber © California Academy of Sciences",
    photocredit04: "©2011 Aaron Arthur",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Corymbia ficifolia",
    common_name: "Red Flowering Gum",
    family_name: "Myrtaceae",
    plantType: "Tree (evergreen)",
    bloomTime: "Summer",
    flower_color: "Red",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    associated_wildlife: "Bees;Birds;Butterflies",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "2 (B)",
    additional_characteristices_notes:
      "Needs a very large basin, wide sidewalk.",
    photocredit01:
      'SelecTree. "Corymbia ficifolia Photo Record." 1995-2012. Jul 3, 2014.',
    photocredit02:
      'SelecTree. "Corymbia ficifolia Photo Record." 1995-2012. Jul 3, 2014.',
    photocredit03:
      'SelecTree. "Corymbia ficifolia Photo Record." 1995-2012. Jul 3, 2014.',
    photocredit04:
      'SelecTree. "Corymbia ficifolia Photo Record." 1995-2012. Jul 3, 2014.',
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Crataegus phaenopyrum",
    common_name: "Washington Hawthorn",
    family_name: "Rosaceae",
    plantType: "Tree (deciduous)",
    bloomTime: "Spring",
    flower_color: "White",
    sizeAtMaturity: "13-24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    waterNeeds: "Low",
    associated_wildlife: "Birds;Butterflies;Bees",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "2 (B)",
    additional_characteristices_notes:
      "Subject to pests, has thorns, may be susceptible to fireblight.",
    photocredit01:
      'SelecTree. "Crataegus phaenopyrum Photo Record." 1995-2012. Jul 3, 2014.',
    photocredit02:
      'SelecTree. "Crataegus phaenopyrum Photo Record." 1995-2012. Jul 3, 2014.',
    photocredit03:
      'SelecTree. "Crataegus phaenopyrum Photo Record." 1995-2012. Jul 3, 2014.',
    photocredit04: "F.D. Richards (CC BY-SA 2.0)",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Crataegus x Lavallei",
    common_name: "Lavallée's hawthorn",
    family_name: "Rosaceae",
    plantType: "Tree (deciduous)",
    flower_color: "White",
    sizeAtMaturity: "13-24 ft",
    climate_appropriate_plants: "Exotic",
    waterNeeds: "Low",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "2 (B)",
    photocredit01:
      "SelecTree. \"Crataegus × lavallei 'Carrierei' Photo Record.\" 1995-2012. Jul 7, 2014.",
    photocredit02: "Jami Dwyer (CC BY-SA 2.0)",
    photocredit03: "Margrit (CC BY-NC-SA 2.0)",
    photocredit04: "Jon Sullivan (CC BY-NC 2.0)",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Cressa truxillensis",
    common_name: "Alkali Weed",
    family_name: "Convolvulaceae",
    former_latin_name: "C.depressa, C. insularis",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "White",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Chaparral;Wetland",
    suitableSiteConditions: "Sun;Shade",
    soil_type: "Loam;Sand",
    pruning_needs: "Minimal",
    waterNeeds: "Low",
    associated_wildlife: "No data",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2013 Neal Kramer",
    photocredit02: "©2013 Don Davis (CC BY-NC-SA 3.0)",
    photocredit03: "©2001 James Reiter",
    photocredit04: "©2008 Zoya Akulova (CC BY-NC 3.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Syagrus romanzoffiana",
    common_name: "Queen Palm",
    family_name: "Arecaceae",
    plantType: "Tree (evergreen)",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "2 (B)",
    additional_characteristices_notes: "Needs heat, wind protection.",
    photocredit01:
      'SelecTree. "Syagrus romanzoffiana Photo Record." 1995-2012. Jul 8, 2014.',
    photocredit02:
      'SelecTree. "Syagrus romanzoffiana Photo Record." 1995-2012. Jul 8, 2014.',
    photocredit03:
      'SelecTree. "Syagrus romanzoffiana Photo Record." 1995-2012. Jul 8, 2014.',
    photocredit04:
      'SelecTree. "Syagrus romanzoffiana Photo Record." 1995-2012. Jul 8, 2014.',
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Symphoricarpos albus",
    common_name: "Common Snowberry",
    family_name: "Caprifoliaceae",
    plantType: "Shrub (deciduous)",
    bloomTime: "Summer",
    flower_color: "Pink",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Woodland;Coastal scrub",
    suitableSiteConditions: "Sun;Part Shade;Shade",
    soil_type: "Clay;Loam;Sand;Rock",
    waterNeeds: "Low",
    habitat_value: "Cover;Fruit",
    associated_wildlife: "Birds;Hummingbirds;Insects",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes: "Attracts quail, pheasant, and grouse.",
    photocredit01: "©2011 Zoya Akulova  (CC BY-NC 3.0)",
    photocredit02: "©2001 Lynn Overtree",
    photocredit03: "©2006 Tom Greer",
    photocredit04: "©2006 Louis-M. Landry",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Symphoricarpos mollis",
    common_name: "Trailing Snowberry",
    family_name: "Caprifoliaceae",
    plantType: "Shrub (deciduous)",
    bloomTime: "Spring;Summer",
    flower_color: "Pink",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Woodland;Coastal scrub",
    suitableSiteConditions: "Sun;Part Shade;Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    habitat_value: "Cover;Fruit;Pollinator;Nesting",
    associated_wildlife: "Bees;Birds;Butterflies",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©Keir Morse",
    photocredit02: "©Keir Morse",
    photocredit03: "©Barry Breckling",
    photocredit04: "©2010 Franco Folini (CC BY-SA 2.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Symphyotrichum chilense",
    common_name: "Wild Aster",
    family_name: "Asteraceae",
    former_latin_name: "Aster chilensis",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "Pink",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities:
      "Chaparral;Coastal scrub;Dunes;Grassland/prairie;Riparian;Woodland",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand;Rock",
    waterNeeds: "None",
    habitat_value: "Buds/greens;Pollinator",
    associated_wildlife: "Bees;Birds;Butterflies;Insects",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden;Roof",
    suggested_green_connection_routes: "1;16;6",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Attracts Field Crescent (Phyciodes campestris) butterfly.",
    photocredit01: "©2011 Aaron Arthur",
    photocredit02: "©2011 Steven Perry",
    photocredit03: "©2009 Neal Kramer",
    photocredit04: "©2013 Robert Sikora",
    special_list: "Top 20",
    habitat: "Yes",
    habitat_int: "1",
    top20_int: "1"
  },
  {
    latin_name: "Tanacetum bipinnatum",
    common_name: "Dune Tansy",
    family_name: "Asteraceae",
    former_latin_name: "Tanacetum camphoratum",
    plantType: "Perennial",
    bloomTime: "Summer;Fall",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Dunes",
    suitableSiteConditions: "Sun",
    soil_type: "Sand",
    waterNeeds: "None",
    associated_wildlife: "No data",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden;Roof",
    suggested_green_connection_routes: "13;5",
    street_tree_list: "NA",
    nurseries:
      "Oaktown;Mission Blue;Candlestick;Bay Natives;East Bay Wilds;Sutro Stewards;Watershed Nursery;",
    photocredit01: "©2012 Neal Kramer",
    photocredit02: "©2012 Neal Kramer",
    photocredit03: "©2012 Neal Kramer",
    photocredit04: "©1999 René Charest",
    special_list: "Sandy Soil",
    habitat: "Yes",
    habitat_int: "1",
    super60: "Y",
    super60_int: "1",
    sandy_soil: "Sandy Soil",
    sandy_soil_int: "1"
  },
  {
    latin_name: "Tellima grandiflora",
    common_name: "Fringe Cups",
    family_name: "Saxifragaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "Pink;White",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Woodland;Coastal scrub",
    suitableSiteConditions: "Part Shade;Shade",
    soil_type: "Loam;Clay",
    waterNeeds: "Low",
    habitat_value: "Cover;Pollinator",
    associated_wildlife: "Birds;Butterflies;Hummingbirds",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes: "Self-sowing, but not weedy.",
    nurseries:
      "Oaktown;Native Here;Mission Blue;Bay Natives;East Bay Wilds;Sutro Stewards;Watershed Nursery;",
    photocredit01: "©2012 RT Hawke (CC BY-NC 3.0)",
    photocredit02: "©2008 Steve Matson",
    photocredit03: "©2008 Steve Matson",
    photocredit04: "©2006 George W. Hartwell",
    special_list: "Shady Clay",
    habitat: "Yes",
    habitat_int: "1",
    super60: "Y",
    super60_int: "1",
    shady_clay: "Shady Clay",
    shady_clay_int: "1"
  },
  {
    latin_name: "Tilia cordata",
    common_name: "Littleleaf Linden",
    family_name: "Malvaceae",
    plantType: "Tree (deciduous)",
    bloomTime: "Spring",
    flower_color: "Yellow",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    associated_wildlife: "Butterflies",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "2 (B)",
    additional_characteristices_notes: "Slow grower.",
    photocredit01:
      'SelecTree. "Tilia cordata Photo Record." 1995-2012. Jul 8, 2014.',
    photocredit02:
      'SelecTree. "Tilia cordata Photo Record." 1995-2012. Jul 8, 2014.',
    photocredit03:
      'SelecTree. "Tilia cordata Photo Record." 1995-2012. Jul 8, 2014.',
    photocredit04:
      'SelecTree. "Tilia cordata Photo Record." 1995-2012. Jul 8, 2014.',
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Trifolium fucatum",
    common_name: "Bull Clover",
    family_name: "Fabaceae",
    plantType: "Annual",
    bloomTime: "Spring",
    flower_color: "Red;White",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Coastal scrub;Riparian;Woodland",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Moderate",
    associated_wildlife: "Butterflies;Insects",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Wet grower. Attracts Orange Sulphur (Colias eurytheme).",
    photocredit01: "©2009 Neal Kramer",
    photocredit02: "©2005 Bob Huettmann",
    photocredit03: "©2003 George W. Hartwell",
    photocredit04: "©2008 Zoya Akulova (CC BY-NC 3.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Trifolium gracilentum",
    common_name: "Pinpoint Clover",
    family_name: "Fabaceae",
    plantType: "Annual",
    bloomTime: "Spring",
    flower_color: "Pink;White;Purple",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Grassland/prairie;Woodland",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    associated_wildlife: "Butterflies;Insects",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Attracts Orange Sulphur (Colias eurytheme).",
    photocredit01: "©2006 Doreen L. Smith",
    photocredit02: "©2012 Gary A. Monroe  (CC BY-NC 3.0)",
    photocredit03: "©2008 Zoya Akulova  (CC BY-NC 3.0)",
    photocredit04: "©2008 Zoya Akulova  (CC BY-NC 3.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Trifolium variegatum",
    common_name: "Whitetip Clover",
    family_name: "Fabaceae",
    plantType: "Annual",
    bloomTime: "Spring;Summer",
    flower_color: "Pink;White;Purple",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Coastal scrub;Riparian;Woodland",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    associated_wildlife: "Butterflies;Insects",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "16",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Attracts Orange Sulphur (Colias eurytheme).",
    photocredit01: "©2013 Zoya Akulova (CC BY-NC 3.0)",
    photocredit02: "©2013 Jean Pawek",
    photocredit03: "©2003 Steve Matson",
    photocredit04: "©2012 Aaron Schusteff",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Trifolium willdenovii",
    common_name: "Tomcat Clover",
    family_name: "Fabaceae",
    plantType: "Annual",
    bloomTime: "Spring",
    flower_color: "Pink;White;Purple",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Chaparral;Grassland/prairie;Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees;Birds;Butterflies",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Attracts Orange Sulphur (Colias eurytheme) and Bay Checkerspot butterflies.",
    photocredit01: "©2003 Dianne Fristrom (CC BY-SA 3.0)",
    photocredit02: "©2009 Neal Kramer",
    photocredit03: "©2003 Dianne Fristrom (CC BY-SA 3.0)",
    photocredit04: "©2008 Keir Morse (CC BY-NC-SA 3.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Trifolium wormskioldii",
    common_name: "Cow Clover",
    family_name: "Fabaceae",
    plantType: "Perennial",
    bloomTime: "Spring",
    flower_color: "Pink",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Chaparral;Grassland/prairie;Riparian;Wetland;Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Moderate",
    associated_wildlife: "No data",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes: "Wet grower.",
    photocredit01: "©2002 Tom Annese",
    photocredit02: "©2009 Dr. Mark S. Brunell",
    photocredit03: "Sherry Ballard © California Academy of Sciences",
    photocredit04: "Charles Webber © California Academy of Sciences",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Tristaniopsis laurina",
    common_name: "Water Gum",
    family_name: "Myrtaceae",
    former_latin_name: "Tristania laurina",
    plantType: "Tree (evergreen)",
    bloomTime: "Spring",
    flower_color: "Yellow",
    sizeAtMaturity: "13-24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "None",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "1 (A)",
    photocredit01:
      'SelecTree. "Tristaniopsis laurina Photo Record." 1995-2012. Jul 8, 2014.',
    photocredit02:
      'SelecTree. "Tristaniopsis laurina Photo Record." 1995-2012. Jul 8, 2014.',
    photocredit03:
      'SelecTree. "Tristaniopsis laurina Photo Record." 1995-2012. Jul 8, 2014.',
    photocredit04:
      'SelecTree. "Tristaniopsis laurina Photo Record." 1995-2012. Jul 8, 2014.',
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Euthamia occidentalis",
    common_name: "Western Goldenrod",
    family_name: "Asteraceae",
    former_latin_name: "Solidago occidentalis",
    plantType: "Perennial",
    bloomTime: "Summer;Fall",
    flower_color: "Yellow",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Grassland/prairie;Riparian;Wetland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees;Butterflies",
    stormwater_benefit: "yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2003 Michael Charters",
    photocredit02: "©2003 Michael Charters",
    photocredit03: "©2003 Michael Charters",
    photocredit04: "©2007 Neal Kramer",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Tristaniopsis laurina  'Elegant'",
    common_name: "Elegant Brisbane Box",
    family_name: "Myrtaceae",
    plantType: "Tree (evergreen)",
    bloomTime: "Spring;Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "13-24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "2 (B)",
    additional_characteristices_notes:
      "Foremerly know as Tristania laurina ‘Elegant’; grows slowly, irregular form, mod-high maintenance.",
    photocredit01:
      "SelecTree. \"Tristaniopsis laurina 'Elegant' Photo Record.\" 1995-2012. Jul 8, 2014.",
    photocredit02:
      "SelecTree. \"Tristaniopsis laurina 'Elegant' Photo Record.\" 1995-2012. Jul 8, 2014.",
    photocredit03:
      "SelecTree. \"Tristaniopsis laurina 'Elegant' Photo Record.\" 1995-2012. Jul 8, 2014.",
    photocredit04:
      "SelecTree. \"Tristaniopsis laurina 'Elegant' Photo Record.\" 1995-2012. Jul 8, 2014.",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Typha domingensis",
    common_name: "Southern Cattail",
    family_name: "Typhaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "Brown",
    sizeAtMaturity: "7-12 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Riparian;Wetland",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Moderate",
    habitat_value: "Cover;Nesting",
    associated_wildlife: "Birds",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "10",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Wet grower, fresh water, travels and can be a maintenance problem very easily. Attracts Red-Wing Black bird.",
    photocredit01: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit02: "©2009 Neal Kramer",
    photocredit03: "©2012 Aaron Arthur",
    photocredit04: "©2010 lara hartley",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Typha latifolia",
    common_name: "Broadleaf Cattail",
    family_name: "Typhaceae",
    plantType: "Perennial",
    bloomTime: "Spring",
    flower_color: "Brown;Yellow",
    sizeAtMaturity: "7-12 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Riparian;Wetland",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Moderate",
    habitat_value: "Cover;Nesting",
    associated_wildlife: "Birds",
    stormwater_benefit: "yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "10",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Wet grower, fresh water, travels and can be a maintenance problem very easily.",
    photocredit01: "©2003 George W. Hartwell",
    photocredit02: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit03: "Gerald and Buff Corsi © California Academy of Sciences",
    photocredit04: "©2009 Barry Rice (CC BY-NC-SA 3.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Ulmus parvifolia 'Drake', 'Sempervirens'",
    common_name: "Drake/Sempervirens Chinese Elm",
    family_name: "Ulmaceae",
    plantType: "Tree (deciduous)",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    plant_communities: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    associated_wildlife: "Butterflies, Birds",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "1 (A)",
    additional_characteristices_notes:
      "Fast grower, which initially requires higher maintenance.",
    photocredit01:
      'SelecTree. "Ulmus parvifolia Photo Record." 1995-2012. Jul 8, 2014.',
    photocredit02:
      'SelecTree. "Ulmus parvifolia Photo Record." 1995-2012. Jul 8, 2014.',
    photocredit03:
      'SelecTree. "Ulmus parvifolia Photo Record." 1995-2012. Jul 8, 2014.',
    photocredit04:
      'SelecTree. "Ulmus parvifolia Photo Record." 1995-2012. Jul 8, 2014.',
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Urtica dioica ssp. gracilis",
    common_name: "Stinging Nettle",
    family_name: "Urticaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "Green;White",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Riparian;Wetland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Moderate",
    habitat_value: "Buds/greens",
    associated_wildlife: "Butterflies;Insects",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Attracts Red Admiral (Vanessa atalanta), Satyr Anglewing (Polygonia satyrus), and West Coast Lady (Vanessa annabella).",
    photocredit01: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit02: "Toni Corelli (CC BY-NC 2.0)",
    photocredit03: "Toni Corelli (CC BY-NC 2.0)",
    photocredit04: "Photo by Pancrazio Campagna",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Urtica dioica ssp. holosericea",
    common_name: "Hoary Nettle",
    family_name: "Urticaceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Riparian;Wetland",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    habitat_value: "Buds/greens",
    associated_wildlife: "Butterflies;Insects",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Attracts Red Admiral (Vanessa atalanta), Satyr Anglewing (Polygonia satyrus), West Coast Lady (Vanessa annabella).",
    photocredit01: "©2013 Neal Kramer",
    photocredit02: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit03: "©2007 Dr. Mark S. Brunell",
    photocredit04: "© Br. Alfred Brousseau, Saint Mary's College",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Vaccinium ovatum",
    common_name: "Huckleberry",
    family_name: "Ericaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Winter",
    flower_color: "White",
    sizeAtMaturity: "7-12 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Woodland;Coastal scrub",
    suitableSiteConditions: "Sun;Part Shade;Shade",
    soil_type: "Clay",
    waterNeeds: "Low",
    habitat_value: "Fruit;Pollinator;Nesting",
    associated_wildlife: "Bees;Birds;Butterflies",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2007 Neal Kramer",
    photocredit02: "©2009 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit03: "©2011 Zoya Akulova (CC BY-NC 3.0)",
    photocredit04: "©2013 Terry Gosliner (CC BY-NC 3.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Verbena bonariensis",
    common_name: "Purpletop Vervain",
    family_name: "Verbenaceae",
    plantType: "Annual",
    bloomTime: "Spring",
    flower_color: "Purple",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "Exotic",
    plant_communities: "Riparian",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Loam",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees;Butterflies;Hummingbirds",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Attracts Mournful Duskywing (Erynnis tristis).",
    photocredit01: "©2008 Jorg Fleige  (CC BY-NC 3.0)",
    photocredit02: "©2012 Jean Pawek",
    photocredit03: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit04: "©2012 Jean Pawek",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Verbena lasiostachys",
    common_name: "Western Vervain",
    family_name: "Verbenaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "Purple",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Riparian;Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand;Rock",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees;Birds;Butterflies;Insects",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Attracts Mournful Duskywing (Erynnis tristis).",
    photocredit01: "©2008 Keir Morse  (CC BY-NC-SA 3.0)",
    photocredit02: "©2008 Keir Morse  (CC BY-NC-SA 3.0)",
    photocredit03: "©2008 Keir Morse  (CC BY-NC-SA 3.0)",
    photocredit04: "©2011 Michael O'Brien",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Vitis californica",
    common_name: "California Wild Grape",
    family_name: "Vitaceae",
    plantType: "Vine",
    bloomTime: "Spring",
    flower_color: "Red",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Riparian;Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand;Rock",
    waterNeeds: "Low",
    habitat_value: "Cover;Fruit",
    associated_wildlife: "Bees;Birds;Butterflies;Insects",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden;Roof",
    suggested_green_connection_routes: "11;12",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Plant east of Twin Peaks – needs warmth. Attracts Cedar Waxwing and Western Fence Lizard.",
    photocredit01:
      "J. E.(Jed) and Bonnie McClellan © California Academy of Sciences",
    photocredit02: "©2013 Zoya Akulova (CC BY-NC 3.0)",
    photocredit03:
      "J. E.(Jed) and Bonnie McClellan © California Academy of Sciences",
    photocredit04: "©2013 Jean Pawek",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Washingtonia robusta",
    common_name: "Mexican Fan Palm",
    family_name: "Arecaceae",
    plantType: "Tree (evergreen)",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "2 (B)",
    additional_characteristices_notes: "Prefers some warmth, full sun",
    photocredit01:
      'SelecTree. "Washingtonia robusta Photo Record." 1995-2012. Jul 8, 2014.',
    photocredit02:
      'SelecTree. "Washingtonia robusta Photo Record." 1995-2012. Jul 8, 2014.',
    photocredit03:
      'SelecTree. "Washingtonia robusta Photo Record." 1995-2012. Jul 8, 2014.',
    photocredit04:
      'SelecTree. "Washingtonia robusta Photo Record." 1995-2012. Jul 8, 2014.',
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Cupaniopsis anacardioides",
    common_name: "Carrot Wood",
    family_name: "Sapindaceae",
    plantType: "Tree (evergreen)",
    bloomTime: "Summer",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Shade",
    waterNeeds: "Low",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "1 (A)",
    additional_characteristices_notes:
      "Uneven performer, needs heat, wind protection.",
    photocredit01:
      'SelecTree. "Cupaniopsis anacardioides Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit02:
      'SelecTree. "Cupaniopsis anacardioides Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit03:
      'SelecTree. "Cupaniopsis anacardioides Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit04:
      'SelecTree. "Cupaniopsis anacardioides Photo Record." 1995-2012. Jul 7, 2014.'
  },
  {
    latin_name: "Danthonia californica",
    common_name: "California Oatgrass",
    family_name: "Poaceae",
    plantType: "Grass",
    bloomTime: "Winter",
    flower_color: "Brown;Green",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Grassland/prairie;Coastal scrub;Wetland;Woodland",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand",
    pruning_needs: "Minimal",
    waterNeeds: "Low",
    habitat_value: "Fruit",
    associated_wildlife: "Birds;Butterflies",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "10",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Deep roots, takes to hillsides well, and better erosion protection than most trees.",
    photocredit01: "©2010 Aaron Arthur",
    photocredit02: "©2010 Rebecca Wenk",
    photocredit03: "©2011 Hattie Brown",
    photocredit04: "©2007 Steve Matson",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Deinandra corymbosa",
    common_name: "Coast Tarweed",
    family_name: "Asteraceae",
    former_latin_name: "Hemizonia corymbosa",
    plantType: "Annual",
    bloomTime: "Spring;Summer;Fall",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Grassland/prairie",
    suitableSiteConditions: "Sun",
    soil_type: "Clay",
    waterNeeds: "Low",
    habitat_value: "Buds/greens;Fruit;Pollinator",
    associated_wildlife: "Bees;Birds;Insects",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes: "Best on east side of the city.",
    photocredit01: "©2010 Aaron Arthur",
    photocredit02: "©2011 Chris Winchell",
    photocredit03: "©2012 Jake Ruygt",
    photocredit04: "©2010 Aaron Arthur",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Whipplea modesta",
    common_name: "Western modesty",
    family_name: "Hydrangeaceae",
    plantType: "Vine",
    bloomTime: "Spring",
    flower_color: "White",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Part Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2012 Gary A. Monroe",
    photocredit02: "©2004 Steve Matson",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Deschampsia cespitosa ssp. holciformis",
    common_name: "Tufted Hairgrass",
    family_name: "Poaceae",
    plantType: "Grass",
    bloomTime: "Summer",
    flower_color: "Brown;Green",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Coastal scrub;Grassland/prairie;Wetland;Woodland",
    suitableSiteConditions: "Sun;Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Low",
    habitat_value: "Fruit",
    associated_wildlife: "Birds;Butterflies",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes: "Not drought tolerant.",
    nurseries:
      "Oaktown;Native Here;Mission Blue;Bay Natives;East Bay Wilds;Watershed Nursery;",
    photocredit01: "©2010 Zoya Akulova (CC BY-NC 3.0)",
    photocredit02: "©2010 Zoya Akulova (CC BY-NC 3.0)",
    photocredit03: "©2013 Robert Steers/NPS",
    photocredit04: "©2012 Vernon Smith (CC BY-NC-ND 3.0)",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1",
    super60: "Y",
    super60_int: "1"
  },
  {
    latin_name: "Distichlis spicata",
    common_name: "Saltgrass",
    family_name: "Poaceae",
    plantType: "Grass",
    bloomTime: "Summer",
    flower_color: "Green;Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Wetland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    habitat_value: "Buds/greens;Fruit",
    associated_wildlife: "Birds;Butterflies",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "24",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Attracts Westen Pygmy Blue butterfly (Brephidium exile).",
    photocredit01: "©2009 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit02: "©2010 Vernon Smith (CC BY-NC-ND 3.0)",
    photocredit03: "©2012 Jorg & Mimi Fleige (CC BY-NC 3.0)",
    photocredit04: "©2009 Robert Sivinski (CC BY-NC 3.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Dudleya brittonii D. pulverulenta",
    common_name: "Chalk Dudleya",
    family_name: "Crassulaceae",
    plantType: "Succulent",
    bloomTime: "Spring",
    flower_color: "Green",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Chaparral",
    suitableSiteConditions: "Sun",
    soil_type: "Clay",
    pruning_needs: "Minimal",
    waterNeeds: "Low",
    associated_wildlife: "No data",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Good for containers / medians. Chalky foliage.",
    photocredit01: "Stan Shebs (CC BY-SA 3.0)",
    photocredit02: "©2010 Michael O'Brien",
    photocredit03: "©2000 Joseph Dougherty/ecology.org",
    photocredit04: "©2005 Michael W. Tuma",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Dudleya caespitosa",
    common_name: "Sea Lettuce, Coast Dudleya",
    family_name: "Crassulaceae",
    former_latin_name: "Dudleya cotyledon",
    plantType: "Succulent",
    bloomTime: "Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Grassland/prairie",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay",
    pruning_needs: "Minimal",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Birds;Butterflies;Hummingbirds",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2013 Judith Elaine Bush (CC BY-NC-SA 3.0)",
    photocredit02: "©2012 CountryMouse13 (CC BY-NC-ND 2.0)",
    photocredit03: "©2013 Peggy A. Lopipero-Langmo (CC BY-SA 2.0)",
    photocredit04: "©2009 Pamla J. Eisenberg (CC BY-SA 2.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Dudleya farinosa",
    common_name: "Live Forever",
    family_name: "Crassulaceae",
    plantType: "Succulent",
    bloomTime: "Spring",
    flower_color: "Yellow",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Grassland/prairie;Coastal scrub;Dunes",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Rock;Sand",
    pruning_needs: "Minimal",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Hummingbirds",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Produces green or glaucus form. Plant next to concrete to wick moisture. Chalky white and red foliage.",
    nurseries:
      "Oaktown;Native Here;Mission Blue;Candlestick;Flora Grubb;Bay Natives;East Bay Wilds;Sutro Stewards;Watershed Nursery;",
    photocredit01: "©2002 Brad Kelley",
    photocredit02: "©1998 Gary A. Monroe (CC BY-NC 3.0)",
    photocredit03: "©2013 Robert Sikora",
    photocredit04: "©2013 Robert Sikora",
    special_list: "Sandy Soil",
    habitat: "Yes",
    habitat_int: "1",
    super60: "Y",
    super60_int: "1",
    sandy_soil: "Sandy Soil",
    sandy_soil_int: "1"
  },
  {
    latin_name: "Elaeocarpus decipiens",
    common_name: "Japanese Blueberry Tree",
    family_name: "Elaeocarpaceae",
    plantType: "Tree (evergreen)",
    bloomTime: "Summer",
    flower_color: "White",
    sizeAtMaturity: "13-24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    associated_wildlife: "Bees;Birds;Butterflies",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "2 (B)",
    additional_characteristices_notes:
      "Slow growing, keep out of strong/ prevailing wind, flowers and may not produce fruit at maturity here .",
    photocredit01: "© 2014 Monrovia",
    photocredit02:
      "Illustrations and photos by Maria del Pilar Paz, Biological Scientist, Environmental Horticulture Department, IFAS, University of Florida.",
    photocredit03:
      "Illustrations and photos by Maria del Pilar Paz, Biological Scientist, Environmental Horticulture Department, IFAS, University of Florida.",
    photocredit04:
      "Illustrations and photos by Maria del Pilar Paz, Biological Scientist, Environmental Horticulture Department, IFAS, University of Florida.",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Eleocharis macrostachya",
    common_name: "Common or Creeping Spikerush",
    family_name: "Cyperaceae",
    former_latin_name: "E.perlonga, E.xyridiformis",
    plantType: "Grass",
    bloomTime: "Spring",
    flower_color: "Brown",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Riparian;Wetland",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand;Rock",
    pruning_needs: "Minimal",
    waterNeeds: "Moderate",
    habitat_value: "Buds/greens;Cover;Nesting",
    associated_wildlife: "Birds",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes: "Brown Seedhead.",
    photocredit01: "©2007 Neal Kramer",
    photocredit02: "©2009 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit03: "©2009 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit04: "©2004 Carol W. Witham",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Eleocharis parvula",
    common_name: "Dwarf Spikerush",
    family_name: "Cyperaceae",
    former_latin_name:
      "Chaetocyperus membranacea, Scirpus nanus var. anachaetus",
    plantType: "Grass",
    bloomTime: "Summer",
    flower_color: "Brown;Green",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Wetland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand;Rock",
    pruning_needs: "Minimal",
    waterNeeds: "Moderate",
    associated_wildlife: "No data",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes: "CNPS Rare Plant",
    photocredit01: "© Biopix: JC Schou",
    photocredit02:
      "Robert H. Mohlenbrock @ USDA-NRCS PLANTS Database / USDA SCS. 1991",
    photocredit03: "© Larry Allain",
    photocredit04: "©2006 John Maunder",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Elymus glaucus",
    common_name: "Blue Wild-rye",
    family_name: "Poaceae",
    plantType: "Grass",
    bloomTime: "Summer",
    flower_color: "Blue;Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Coastal scrub;Grassland/prairie;Woodland",
    suitableSiteConditions: "Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "None",
    habitat_value: "Fruit",
    associated_wildlife: "Birds;Butterflies",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden;Roof",
    suggested_green_connection_routes: "23",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Versatile flower that is a weedfighter and survivor.",
    nurseries:
      "Oaktown;Native Here;Candlestick;Flora Grubb;Bay Natives;East Bay Wilds;Flora Grubb;Watershed Nursery;",
    photocredit01: "©2013 Jean Pawek",
    photocredit02: "©2013 Jean Pawek",
    photocredit03: "©2013 Jean Pawek",
    photocredit04: "©2008 Keir Morse (CC BY-NC-SA 3.0)",
    habitat: "Yes",
    habitat_int: "1",
    super60: "Y",
    super60_int: "1"
  },
  {
    latin_name: "Elymus mollis ssp. mollis",
    common_name: "American Dune Grass",
    family_name: "Poaceae",
    former_latin_name: "Leymus mollis ssp. mollis",
    plantType: "Grass",
    bloomTime: "Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Dunes",
    suitableSiteConditions: "Sun",
    soil_type: "Sand",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "13;7",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Needs dunes and shifting sand, most likely by ocean beach.",
    photocredit01: "© Mike LaBarbera",
    photocredit02: "© Avis Boutell",
    photocredit03: "©2010 Robert Steers/NPS",
    photocredit04: "© Jeb Bjerke",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Elymus pacificus",
    common_name: "Pacific wild rye",
    family_name: "Poaceae",
    plantType: "Grass",
    bloomTime: "Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Coastal scrub;Dunes",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    habitat_value: "Fruit",
    associated_wildlife: "Birds",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "© Avis Boutell",
    photocredit02: "© Avis Boutell",
    photocredit03: "© Avis Boutell",
    photocredit04: "©2006 Tom Annese",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Elymus triticoides",
    common_name: "Beardless wild rye",
    family_name: "Poaceae",
    plantType: "Grass",
    bloomTime: "Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Coastal scrub;Riparian;Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    habitat_value: "Fruit",
    associated_wildlife: "Birds",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Versatile flower that is a runner and good for binding.",
    photocredit01: "© Matt Lavin",
    photocredit02: "© Matt Lavin",
    photocredit03: "© Matt Lavin",
    photocredit04: "© Matt Lavin",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Encelia californica",
    common_name: "Bush Sunflower",
    family_name: "Asteraceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Summer;Winter",
    flower_color: "Yellow",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Chaparral",
    suitableSiteConditions: "Sun",
    soil_type: "Clay",
    pruning_needs: "Cut to ground",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees;Birds;Butterflies",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "10",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Sunflower-like flowers. Attracts Red-Winged Blackbird.",
    photocredit01: "©2004 BonTerra Consulting",
    photocredit02: "©2011 Zoya Akulova  (CC BY-NC 3.0)",
    photocredit03: "©2003 Michael Charters",
    photocredit04: "©Las Pilitas Nursery",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Epilobium brachycarpum",
    common_name: "Annual Fireweed",
    family_name: "Onagraceae",
    plantType: "Annual",
    bloomTime: "Summer",
    flower_color: "Pink",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Chaparral;Grassland/prairie;Woodland",
    suitableSiteConditions: "Sun;Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "None",
    habitat_value: "Buds/greens;Fruit;Pollinator",
    associated_wildlife: "Birds;Hummingbirds;Insects",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Self-seeds. Attracts Lesser Goldfinches and Sphinx Moth.",
    photocredit01: "©2007 Ron Wolf",
    photocredit02: "©2007 George W. Hartwell",
    photocredit03: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit04: "©2002 Gary A. Monroe  (CC BY-NC 3.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Festuca californica",
    common_name: "California Fescue",
    family_name: "Poaceae",
    plantType: "Grass",
    bloomTime: "Spring;Winter",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Woodland;Coastal scrub",
    suitableSiteConditions: "Part Shade",
    soil_type: "clay;loam",
    waterNeeds: "Low",
    habitat_value: "Cover",
    associated_wildlife: "Butterflies",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden;Sidewalk;Roof",
    suggested_green_connection_routes: "21",
    street_tree_list: "NA",
    additional_characteristices_notes: "Attracts Black-tailed jackrabbit.",
    nurseries:
      "Oaktown;Native Here;Mission Blue;Candlestick;Flora Grubb;Bay Natives;East Bay Wilds;Watershed Nursery;",
    photocredit01: "©2007 California Native Plant Society",
    photocredit02: "©2007 Neal Kramer",
    photocredit03: "©2011 Aaron Arthur",
    photocredit04: "©2001 CDFA (CC BY-NC 3.0)",
    special_list: "Shady Clay",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1",
    super60: "Y",
    super60_int: "1",
    shady_clay: "Shady Clay",
    shady_clay_int: "1"
  },
  {
    latin_name: "Epilobium canum",
    common_name: "California Fuchsia",
    family_name: "Onagraceae",
    former_latin_name: "Zauschneria californica",
    plantType: "Perennial",
    bloomTime: "Summer;Fall",
    flower_color: "Orange;Pink",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Coastal Scrub;Chaparral",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Loam;Rock;Sand",
    pruning_needs: "Cut to ground",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees;Birds;Butterflies;Hummingbirds",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden;Roof",
    suggested_green_connection_routes: "3",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Attracts Anna's Hummingbird and White Line Sphinx moth.",
    nurseries:
      "Oaktown;Native Here;Mission Blue;Flora Grubb;Bay Natives;East Bay Wilds;Watershed Nursery;",
    photocredit01: "© 1993 Gary A. Monroe (CC BY-NC 3.0)",
    photocredit02: "© Tony Frates  (CC BY-NC-SA 2.0)",
    photocredit03: "© 1993 Gary A. Monroe (CC BY-NC 3.0)",
    photocredit04: "© Don M. Davis  (CC BY-NC-ND 2.0)",
    special_list: "Top 20",
    habitat: "Yes",
    habitat_int: "1",
    super60: "Y",
    super60_int: "1",
    top20_int: "1"
  },
  {
    latin_name: "Epilobium ciliatum ssp. watsonii",
    common_name: "Willow Herb",
    family_name: "Onagraceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "Pink",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Wetland;Coastal scrub;Riparian;Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand;Rock",
    waterNeeds: "Low",
    habitat_value: "Fruit;Pollinator",
    associated_wildlife: "Birds;Hummingbirds",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Self-seeds. Attracts Lesser Goldfinches.",
    photocredit01: "© Ken Gardiner",
    photocredit02: "©2013 Jean Pawek",
    photocredit03: "©1999 James B. Gratiot",
    photocredit04: "© David A. Hofmann",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Ericameria ericoides",
    common_name: "Mock Heather",
    family_name: "Asteraceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Fall",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Dunes",
    suitableSiteConditions: "Sun",
    soil_type: "Sand",
    waterNeeds: "None",
    habitat_value: "Cover",
    associated_wildlife: "No data",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "13;24;9",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Needs dunes and shifting sand, most likely by ocean beach.",
    photocredit01: "©2003 Christopher L. Christie",
    photocredit02: "©2013 Aaron Arthur",
    photocredit03: "Robert Potts © California Academy of Sciences",
    photocredit04: "©2012 Jean Pawek",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Erigeron glaucus",
    common_name: "Seaside Daisy",
    family_name: "Asteraceae",
    plantType: "Perennial",
    bloomTime: "Spring;Winter",
    flower_color: "Pink",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Dunes;Coastal scrub",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees;Butterflies",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden;Sidewalk;Roof",
    suggested_green_connection_routes: "14;15",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Adaptable to many locations. Attracts Green Hairstreak (Callophrys viridis) butterfly.",
    nurseries:
      "Oaktown;Mission Blue;Candlestick;Flora Grubb;Bay Natives;East Bay Wilds;Sutro Stewards;Watershed Nursery;",
    photocredit01: "©1994 Gary A. Monroe (CC BY-NC 3.0)",
    photocredit02: "Albert P. Bekker © California Academy of Sciences",
    photocredit03: "©2010 Steven Thorsted (CC BY-NC 3.0)",
    photocredit04: "Charles Webber © California Academy of Sciences",
    special_list: "Sandy Soil;Top 20",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1",
    super60: "Y",
    super60_int: "1",
    top20_int: "1",
    sandy_soil: "Sandy Soil",
    sandy_soil_int: "1"
  },
  {
    latin_name: "Eriobotrya deflexa",
    common_name: "Bronze Loquat",
    family_name: "Asteraceae",
    plantType: "Tree (evergreen)",
    bloomTime: "Spring",
    flower_color: "White",
    sizeAtMaturity: "13-24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    waterNeeds: "Low",
    associated_wildlife: "Birds;Butterflies",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "2 (B)",
    additional_characteristices_notes:
      "Needs wind protection, does not perform well in sandy soils.",
    photocredit01:
      'SelecTree. "Eriobotrya deflexa Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit02:
      'SelecTree. "Eriobotrya deflexa Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit03:
      'SelecTree. "Eriobotrya deflexa Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit04:
      'SelecTree. "Eriobotrya deflexa Photo Record." 1995-2012. Jul 7, 2014.',
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Eriogonum latifolium",
    common_name: "Coast Buckwheat",
    family_name: "Polygonaceae",
    plantType: "Perennial",
    bloomTime: "Summer;Fall",
    flower_color: "Pink",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Dunes;Coastal Scrub;Grassland/prairie",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Rock;Sand;Clay",
    waterNeeds: "None",
    habitat_value: "Buds/greens;Pollinator",
    associated_wildlife: "Bees;Birds;Butterflies;Insects",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden;Sidewalk;Roof",
    suggested_green_connection_routes: "13;14;15;20;5",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Recommended. Attracts Green Hairstreak (Callophrys viridis) and Acmon Blue (Plebejus acmon) butterflies.",
    nurseries:
      "Oaktown;Mission Blue;Candlestick;East Bay Wilds;Bay Natives;Sutro Stewards;Watershed Nursery;",
    photocredit01: "©2010 Zoya Akulova (CC BY-NC 3.0)",
    photocredit02: "©2004 Bob Huettmann",
    photocredit03: "©2012 Robert Sikora",
    photocredit04: "©2000 Joseph Dougherty/ecology.org",
    special_list: "Sandy Soil;Top 20",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1",
    super60: "Y",
    super60_int: "1",
    top20_int: "1",
    sandy_soil: "Sandy Soil",
    sandy_soil_int: "1"
  },
  {
    latin_name: "Eriophyllum staechadifolium",
    common_name: "Lizardtail",
    family_name: "Asteraceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Dunes;Coastal scrub",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees;Butterflies;Insects",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden;Sidewalk;Roof",
    suggested_green_connection_routes: "9",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Recommended. Likes the fog belt, but could do the east side.",
    photocredit01: "©2005 Bob Huettmann",
    photocredit02: "©2013 Neal Kramer",
    photocredit03: "©2013 Neal Kramer",
    photocredit04: "©2013 Jean Pawek",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Erysimum franciscanum",
    common_name: "San Francisco Wallflower",
    family_name: "Brassicaceae",
    plantType: "Perennial",
    bloomTime: "Spring",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Coastal scrub;Dunes",
    suitableSiteConditions: "Sun",
    soil_type: "Loam;Sand;Clay;Rock",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees;Butterflies",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes: "CNPS Rare Plant",
    nurseries:
      "Oaktown;Mission Blue;Bay Natives;East Bay Wilds;Sutro Stewards;",
    photocredit01: "©2002 Doreen L. Smith",
    photocredit02: "©2013 Neal Kramer",
    photocredit03: "©2013 Neal Kramer",
    photocredit04: "©2011 Dylan Neubauer",
    habitat: "Yes",
    habitat_int: "1",
    super60: "Y",
    super60_int: "1"
  },
  {
    latin_name: "Escallonia bifida",
    common_name: "White Escallonia",
    family_name: "Escalloniaceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "White",
    sizeAtMaturity: "7-12 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Loam",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "© rachel greenbelt (CC by-nc-sa 2.0)",
    photocredit02: "© ibsut (CC by-nc 2.0)",
    photocredit03: "© Nigel Taylor",
    photocredit04: "© wally grom (CC by-nc-sa 2.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Eschscholzia californica",
    common_name: "California Poppy",
    family_name: "Papaveraceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "Orange;Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities:
      "Chaparral;Grassland/prairie;Woodland;Coastal scrub;Dunes",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand;Rock",
    waterNeeds: "None",
    habitat_value: "Cover;Pollinator",
    associated_wildlife: "Bees;Birds;Butterflies",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden;Sidewalk;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Coastal form is smaller and more yellow, large orange form is native inland.",
    nurseries:
      "Oaktown;Mission Blue;Candlestick;Flora Grubb;Bay Natives;East Bay Wilds;Sutro Stewards;Watershed Nursery;",
    photocredit01: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit02: "Charles Webber © California Academy of Sciences",
    photocredit03: "Gerald and Buff Corsi © California Academy of Sciences",
    photocredit04: "©2007 Neal Kramer",
    special_list: "Sandy Soil;Top 20",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1",
    super60: "Y",
    super60_int: "1",
    top20_int: "1",
    sandy_soil: "Sandy Soil",
    sandy_soil_int: "1"
  },
  {
    latin_name: "Adenanthos spp.",
    common_name: "Woolly Bush",
    family_name: "Proteaceae",
    plantType: "Shrub (evergreen)",
    sizeAtMaturity: "varies",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    waterNeeds: "Low",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2014 Cerlin Ng",
    photocredit02: "©2013 Clare Snow",
    additional_species_cultivars_varieties: "sericea, cuneatus",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Eucalyptus polyanthemos",
    common_name: "Silver Dollar Gum",
    family_name: "Myrtaceae",
    plantType: "Tree (evergreen)",
    bloomTime: "Summer;Fall",
    flower_color: "White",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    associated_wildlife: "Birds;Butterflies",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "2 (B)",
    additional_characteristices_notes:
      "Needs a large basin, fast grower, high maintenance.",
    photocredit01:
      'SelecTree. "Eucalyptus polyanthemos Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit02:
      'SelecTree. "Eucalyptus polyanthemos Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit03:
      'SelecTree. "Eucalyptus polyanthemos Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit04:
      'SelecTree. "Eucalyptus polyanthemos Photo Record." 1995-2012. Jul 7, 2014.',
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Festuca idahoensis",
    common_name: "Idaho Fescue",
    family_name: "Poaceae",
    plantType: "Grass",
    bloomTime: "Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Chaparral;Grassland/prairie;Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand;Rock",
    waterNeeds: "None",
    habitat_value: "Buds/greens;Pollinator",
    associated_wildlife: "Birds;Butterflies",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden;Sidewalk;Roof",
    suggested_green_connection_routes: "7",
    street_tree_list: "NA",
    photocredit01: "Bert Wilson © Las Pilitas Nursery",
    photocredit02: "© Andy Huber/GROWISER",
    photocredit03: "©2008 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit04: "©2005 Steve Matson",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Festuca rubra",
    common_name: "Red Fescue",
    family_name: "Poaceae",
    plantType: "Grass",
    bloomTime: "Spring",
    flower_color: "Yellow",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Chaparral;Grassland/prairie;Wetland;Woodland;Dunes",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "None",
    habitat_value: "Cover;Fruit",
    associated_wildlife: "Bees;Birds;Butterflies",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden;Sidewalk;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    nurseries:
      "Oaktown;Native Here;Mission Blue;Candlestick;Flora Grubb;Bay Natives;East Bay Wilds;Watershed Nursery;",
    photocredit01: "©Forest and Kim Starr (CC BY 2.0)",
    photocredit02: "©oliveoligarchy (CC BY 2.0)",
    photocredit03: "©oliveoligarchy (CC BY 2.0)",
    photocredit04: "©oliveoligarchy (CC BY 2.0)",
    special_list: "Sandy Soil",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1",
    super60: "Y",
    super60_int: "1",
    sandy_soil: "Sandy Soil",
    sandy_soil_int: "1"
  },
  {
    latin_name: "Festuca rubra 'Point Molate'",
    common_name: "Molate Fescue",
    family_name: "Poaceae",
    plantType: "Grass",
    bloomTime: "Spring",
    flower_color: "Indigo",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Grassland/prairie",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "None",
    habitat_value: "Cover;Fruit",
    associated_wildlife: "Bees;Birds;Butterflies",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "Peggy A. Lopipero-Langmo (CC BY 2.0)",
    photocredit02: "oliveoligarchy (CC BY 2.0)",
    photocredit03: "eNikodemus (CC BY 2.0)",
    photocredit04: "Starr Environmental (CC BY 2.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Fragaria chiloensis",
    common_name: "Coast Strawberry",
    family_name: "Rosaceae",
    plantType: "Perennial",
    bloomTime: "Winter",
    flower_color: "White",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Dunes;Coastal scrub;",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand;Rock",
    waterNeeds: "None",
    habitat_value: "Cover;Fruit",
    associated_wildlife: "Bees;Birds;Butterflies",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden;Sidewalk;Roof",
    suggested_green_connection_routes: "15;24;5",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Endures wind well. Attracts Green Hairstreak (Callophrys viridis) butterfly.",
    nurseries:
      "Oaktown;Mission Blue;Candlestick;Flora Grubb;Bay Natives;East Bay Wilds;Sutro Stewards;Watershed Nursery;",
    photocredit01: "©2013 Margo Bors",
    photocredit02: "©2013 Zoya Akulova (CC BY-NC 3.0)",
    photocredit03: "©2014 Vernon Smith (CC BY-NC-ND 3.0)",
    photocredit04: "© Br. Alfred Brousseau, Saint Mary's College",
    special_list: "Sandy Soil;Top 20",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1",
    super60: "Y",
    super60_int: "1",
    top20_int: "1",
    sandy_soil: "Sandy Soil",
    sandy_soil_int: "1"
  },
  {
    latin_name: "Fragaria vesca",
    common_name: "Woodland Strawberry",
    family_name: "Rosaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Winter",
    flower_color: "White",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Woodland;Coastal scrub",
    suitableSiteConditions: "Part Shade;Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Low",
    habitat_value: "Cover;Fruit",
    associated_wildlife: "Bees;Birds;Butterflies",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden;Sidewalk;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2006 Vivian Parker (CC BY-SA 3.0)",
    photocredit02: "©2008 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit03: "oxfordian.world (CC BY 2.0)",
    photocredit04: "©2008 Keir Morse (CC BY-NC-SA 3.0)",
    special_list: "Shady Clay",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1",
    shady_clay: "Shady Clay",
    shady_clay_int: "1"
  },
  {
    latin_name: "Frangula californica",
    common_name: "Coffeeberry",
    family_name: "Rhamnaceae",
    former_latin_name: "Rhamnus californica",
    plantType: "Shrub (evergreen)",
    bloomTime: "Summer",
    flower_color: "Burgundy",
    sizeAtMaturity: "7-12 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Coastal scrub;Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand;Rock",
    waterNeeds: "None",
    habitat_value: "Cover;Buds/greens;Fruit;Pollinator",
    associated_wildlife: "Bees;Birds;Butterflies;Insects",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "9",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Attracts Pale Tiger Swallowtail (Papilio eurymedon) and White-Crowned Sparrow.",
    nurseries:
      "Oaktown;Native Here;Mission Blue;Candlestick;Flora Grubb;Bay Natives;East Bay Wilds;Sutro Stewards;Watershed Nursery;",
    photocredit01: "©2012 Neal Kramer",
    photocredit02: "©2012 Aaron Arthur",
    photocredit03: "©2012 Jean Pawek",
    photocredit04: "©2012 Jean Pawek",
    special_list: "Sandy Soil;Shady Clay;Top 20",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1",
    super60: "Y",
    super60_int: "1",
    top20_int: "1",
    shady_clay: "Shady Clay",
    shady_clay_int: "1",
    sandy_soil: "Sandy Soil",
    sandy_soil_int: "1"
  },
  {
    latin_name: "Frankenia salina",
    common_name: "Alkali-heath",
    family_name: "Frankeniaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "Pink",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Wetland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Moderate",
    habitat_value: "Fruit",
    associated_wildlife: "Birds;Butterflies",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "24",
    street_tree_list: "NA",
    photocredit01: "Charles Webber © California Academy of Sciences",
    photocredit02: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit03: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit04: "©2008 Margo Bors",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Fraxinus latifolia",
    common_name: "Oregon Ash",
    family_name: "Oleaceae",
    plantType: "Tree (deciduous)",
    bloomTime: "Spring",
    flower_color: "Green",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Riparian;Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    habitat_value: "Fruit;Nesting",
    associated_wildlife: "Birds;Butterflies",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Attracts Pale Swallowtail (Papilio eurymedon) and Western Tiger Swallowtail (Papilio rutulus).",
    photocredit01: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit02: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit03: "©2008 Neal Kramer",
    photocredit04: "©2008 Keir Morse (CC BY-NC-SA 3.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Fremontodendron 'California Glory'",
    common_name: "Flannel Bush",
    family_name: "Malvaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring",
    flower_color: "Yellow",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Chaparral;Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "None",
    habitat_value: "Cover;Buds/greens;Pollinator",
    associated_wildlife: "Bees;Butterflies;Insects",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2013 Jean Pawek",
    photocredit02: "©2008 BonTerra Consulting",
    photocredit03: "© Br. Alfred Brousseau, Saint Mary's College.",
    photocredit04: "© Br. Alfred Brousseau, Saint Mary's College.",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Gaillardia x grandiflora",
    common_name: "Gaillardia (Blanket Flower)",
    family_name: "Asteraceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "Orange;Red;Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees;Birds",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: " © The Brroksville Garden Club",
    photocredit02: "Toni Moore © 2005-2006 Arizona Board of Regents",
    photocredit03: "© The Brroksville Garden Club",
    photocredit04: "Toni Moore © 2005-2006 Arizona Board of Regents",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Galvezia speciosa",
    common_name: "Island Bush Snapdragon",
    family_name: "Plantaginaceae",
    plantType: "Perennial",
    bloomTime: "Spring",
    flower_color: "Red",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Chaparral",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Sand",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies;Hummingbirds",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Intermittent bloom throughout the year.",
    photocredit01: "Philip Bouchard (CC BY-NC-ND 2.0)",
    photocredit02: "Alan Harper (CC BY-NC 2.0)",
    photocredit03: "Kerry Woods (CC BY-NC-ND 2.0)",
    photocredit04: "Erick Lux (CC BY-NC-SA 2.0)",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Garrya elliptica",
    common_name: "Coast Silktassel",
    family_name: "Garryaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Winter",
    flower_color: "White",
    sizeAtMaturity: "13-24 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Coastal scrub;Woodland",
    suitableSiteConditions: "Sun;Shade",
    soil_type: "clay;loam",
    waterNeeds: "Low",
    habitat_value: "Fruit;Cover",
    associated_wildlife: "Bees;Birds",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "2 (B)",
    additional_characteristices_notes:
      "Beautiful plant that gets large, is wind pollinated, and could be trained into a single stem tree.",
    nurseries:
      "Oaktown;Native Here;Mission Blue;Flora Grubb;Bay Natives;East Bay Wilds;Sutro Stewards;",
    photocredit01:
      'SelecTree. "Garrya elliptica Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit02:
      'SelecTree. "Garrya elliptica Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit03:
      'SelecTree. "Garrya elliptica Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit04:
      'SelecTree. "Garrya elliptica Photo Record." 1995-2012. Jul 7, 2014.',
    special_list: "Shady Clay",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1",
    super60: "Y",
    super60_int: "1",
    shady_clay: "Shady Clay",
    shady_clay_int: "1"
  },
  {
    latin_name: "Geijera parviflora",
    common_name: "Australian Willow",
    family_name: "Rutaceae",
    plantType: "Tree (evergreen)",
    bloomTime: "Spring",
    flower_color: "White",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Loam;Sand",
    waterNeeds: "Moderate",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "2 (B)",
    additional_characteristices_notes: "Prefers heat, wind protection.",
    photocredit01:
      'SelecTree. "Geijera parviflora Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit02:
      'SelecTree. "Geijera parviflora Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit03:
      'SelecTree. "Geijera parviflora Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit04:
      'SelecTree. "Geijera parviflora Photo Record." 1995-2012. Jul 7, 2014.',
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Gilia capitata ssp. Chamissonis",
    common_name: "Dune Gilia",
    family_name: "Polemoniaceae",
    plantType: "Annual",
    bloomTime: "Spring;Summer",
    flower_color: "Blue",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Coastal scrub;Dunes;Grassland/prairie",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees;Butterflies",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2009 Jorg Fleige (CC BY-NC 3.0)",
    photocredit02: "©2006 Andrea Jesse",
    photocredit03: "©2003 Doreen L. Smith",
    photocredit04: "©2011 Eric Wrubel/NPS",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Gilia tricolor ssp. Tricolor",
    common_name: "Bird's Eye Gilia",
    family_name: "Polemoniaceae",
    plantType: "Annual",
    bloomTime: "Summer",
    flower_color: "Purple",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Grassland/prairie;Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees;Birds;Butterflies;Hummingbirds",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2003 Michael Charters",
    photocredit02: "©2003 George Jackson",
    photocredit03: "©2010 Neal Kramer",
    photocredit04: "©2010 Neal Kramer",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Ginkgo biloba 'Autumn Gold'",
    common_name: "Maidenhair Tree",
    family_name: "Ginkgoaceae",
    plantType: "Tree (deciduous)",
    bloomTime: "Spring",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "1 (A)",
    additional_characteristices_notes:
      "Slow grower. 'Princeton Sentry', 'Saratoga' another variety.",
    photocredit01:
      'SelecTree. "Ginkgo biloba Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit02:
      'SelecTree. "Ginkgo biloba Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit03:
      'SelecTree. "Ginkgo biloba Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit04:
      'SelecTree. "Ginkgo biloba Photo Record." 1995-2012. Jul 7, 2014.',
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Glaux maritima",
    common_name: "Sea milkwort",
    family_name: "Myrsinaceae",
    plantType: "Perennial",
    bloomTime: "Spring",
    flower_color: "White",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Wetland",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Moderate",
    associated_wildlife: "No data",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2013 Vernon Smith (CC BY-NC-ND 3.0)",
    photocredit02:
      "J. E.(Jed) and Bonnie McClellan © California Academy of Sciences",
    photocredit03: "©2009 Phil Pullen",
    photocredit04: "©2007 Steve Matson",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Artemisia tridentata",
    common_name: "Big Sagebrush",
    family_name: "Asteraceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Summer",
    flower_color: "White",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2012 Jean Pawek",
    photocredit02: "©2012 Jean Pawek",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Banksia spp.",
    common_name: "Banksia",
    family_name: "Proteaceae",
    plantType: "Shrub (evergreen)",
    sizeAtMaturity: "13-24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    habitat_value: "Pollinator;Cover;Nesting",
    associated_wildlife: "Birds",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2009 Zoya Akulova",
    photocredit02: "©2016 Zoya Akulova",
    additional_species_cultivars_varieties: "integrifolia, speciosa",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Calliandra eriophylla",
    common_name: "\tFairy Dustre",
    family_name: "Fabaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Winter",
    flower_color: "Pink",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Birds;Hummingbirds",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2006 Steve Matson ",
    photocredit02: "©2006 Steve Matson ",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Ceanothus spp.",
    common_name: "California Lilac",
    family_name: "Rhamnaceae",
    plantType: "Shrub (evergreen)",
    sizeAtMaturity: "varies",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Birds;Bees",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2016 Keir Morse",
    photocredit02: "©2009 Keir Morse",
    additional_species_cultivars_varieties:
      "'Joyce Coulter', 'Ray Hartman', 'Concha', 'Dark Star', 'Diamond Heights', gloriosus, griseus, thyrsiflorus repens,thyrisiflorus 'Yankee Point', thyrsiflorus 'Snow Flurry',griseus horizontalis,",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Grindelia hirsutula",
    common_name: "San Francisco Gumplant",
    family_name: "Asteraceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Coastal scrub;Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Rock",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees;Birds;Butterflies",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden;Sidewalk;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "CNPS Rare Plant. Tough plant, easy to grow, self seeding. Attracts Common Buckeye (Junonia coenia) butterfly.",
    nurseries:
      "Oaktown;Mission Blue;Candlestick;Bay Natives;East Bay Wilds;Sutro Stewards;Watershed Nursery;",
    photocredit01: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit02: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit03: "Robert Potts © California Academy of Sciences",
    photocredit04: "©2004 Doreen L. Smith",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1",
    super60: "Y",
    super60_int: "1"
  },
  {
    latin_name: "Grindelia stricta",
    common_name: "Coastal Gumplant",
    family_name: "Asteraceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer;Fall",
    flower_color: "Yellow",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Dunes;Wetland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    habitat_value: "Pollinator;Nesting",
    associated_wildlife: "Bees;Birds;Butterflies",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2010 Vernon Smith (CC BY-NC-ND 3.0)",
    photocredit02: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit03: "Eric Hunt (CC BY-NC-ND 2.0)",
    photocredit04: "Philip Bouchard (CC BY-NC-ND 2.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Helianthus gracilentus",
    common_name: "Slender Sunflower",
    family_name: "Asteraceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Chaparral",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees;Birds;Butterflies",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Attracts Red-Winged Blackbird and Monarch Butterfly (Danaus plexippus).",
    photocredit01: "©2013 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit02: "©2013 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit03: "©2013 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit04: "©2010 Zoya Akulova (CC BY-NC 3.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Heliotropium curassavicum",
    common_name: "Wild Heliotrope",
    family_name: "Boraginaceae",
    plantType: "Perennial",
    bloomTime: "Spring",
    flower_color: "White",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Wetland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Loam;Sand",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit02: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit03: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit04: "©2004 Keir Morse  (CC BY-NC-SA 3.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Heracleum maximum",
    common_name: "Cow Parsnip",
    family_name: "Apiaceae",
    former_latin_name: "Heracleum lanatum",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "White",
    sizeAtMaturity: "7-12 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Woodland;Coastal scrub;Wetland;Riparian",
    suitableSiteConditions: "Part Shade",
    soil_type: "Loam;Sand",
    pruning_needs: "Cut to ground",
    waterNeeds: "Low",
    associated_wildlife: "Bees;Birds",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "6",
    street_tree_list: "NA",
    photocredit01: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit02: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit03: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit04: "© 2000 Gary A. Monroe (CC BY-NC 3.0)",
    special_list: "Shady Clay",
    habitat: "Yes",
    habitat_int: "1",
    shady_clay: "Shady Clay",
    shady_clay_int: "1"
  },
  {
    latin_name: "Heteromeles arbutifolia",
    common_name: "Toyon",
    family_name: "Rosaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Summer",
    flower_color: "White",
    sizeAtMaturity: "7-12 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Chaparral;Coastal scrub;Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand;Rock",
    pruning_needs: "Minimal",
    waterNeeds: "None",
    habitat_value: "Cover;Buds/greens;Fruit;Pollinator",
    associated_wildlife: "Bees;Birds;Butterflies;Hummingbirds;Insects",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "23;4",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "White flowers in summer, red berries in winter. Attracts Cedar Waxwing, Robins, and Purple Finches.",
    nurseries:
      "Oaktown;Native Here;Mission Blue;Candlestick;Flora Grubb;Bay Natives;East Bay Wilds;Sutro Stewards;Watershed Nursery;",
    photocredit01: 'Laura Camp "campsjc" (CC BY-SA 2.0)',
    photocredit02: 'James Gaither "jim-sf" (CC BY-NC-ND 2.0)',
    photocredit03: 'Belinda Lo "western goblin" (CC BY-NC-SA 2.0)',
    photocredit04: "Roy Randall (CC BY-NC-ND 2.0)",
    special_list: "Top 20",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1",
    super60: "Y",
    super60_int: "1",
    top20_int: "1"
  },
  {
    latin_name: "Origanum dictamnus",
    common_name: "Dittany of Crete, Hop Marjoram",
    family_name: "Lamiaceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "Pink",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies;bees",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2011 Peter A. Mansfield",
    photocredit02: "©2011 Peter A. Mansfield",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Heterotheca sessiliflora ssp. Bolanderi",
    common_name: "Bolander's Goldenaster",
    family_name: "Asteraceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer;Fall;Winter",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native ",
    plant_communities: "Grassland/prairie",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Rock",
    waterNeeds: "None",
    habitat_value: "Fruit;Pollinator",
    associated_wildlife: "Bees;Birds;Butterflies",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2001 Doreen L. Smith",
    photocredit02: "©2007 Neal Kramer",
    photocredit03: "©2012 Aaron Arthur",
    photocredit04: "©2007 Neal Kramer",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Heuchera micrantha",
    common_name: "Alum Root",
    family_name: "Saxifragaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "White",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Woodland;Coastal scrub",
    suitableSiteConditions: "Part shade;Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Moderate",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees;Butterflies;Hummingbirds",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden;Sidewalk;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Prefers north facing cliff faces and cracks in the rock.",
    nurseries:
      "Oaktown;Native Here;Mission Blue;Flora Grubb;Bay Natives;East Bay Wilds;Sutro Stewards;Watershed Nursery;",
    photocredit01: "© 2010 Barry Breckling  (CC BY-NC-SA 3.0)",
    photocredit02: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit03: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit04: "© 2009 Keir Morse (CC BY-NC-SA 3.0)",
    special_list: "Shady Clay",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1",
    super60: "Y",
    super60_int: "1",
    shady_clay: "Shady Clay",
    shady_clay_int: "1"
  },
  {
    latin_name: "Holodiscus discolor",
    common_name: "Cream Bush",
    family_name: "Rosaceae",
    plantType: "Shrub (deciduous)",
    bloomTime: "Spring;Summer",
    flower_color: "White",
    sizeAtMaturity: "7-12 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Woodland;Coastal scrub",
    suitableSiteConditions: "Sun;Part Shade;Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Low",
    habitat_value: "Buds/greens;Cover;Pollinator",
    associated_wildlife: "Bees;Birds;Butterflies",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Attracts Spring Azure, Pale Swallowtail, and Lorquin’s Admiral butterflies.",
    nurseries:
      "Oaktown;Native Here;Flora Grubb;Bay Natives;East Bay Wilds;Sutro Stewards;Watershed Nursery;",
    photocredit01: "©2007 Matt Below",
    photocredit02: "©2003 Steve Matson",
    photocredit03: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit04: "©2003 Michael Charters",
    special_list: "Shady Clay",
    habitat: "Yes",
    habitat_int: "1",
    super60: "Y",
    super60_int: "1",
    shady_clay: "Shady Clay",
    shady_clay_int: "1"
  },
  {
    latin_name: "Hordeum brachyantherum",
    common_name: "Meadow Barley",
    family_name: "Poaceae",
    plantType: "Grass",
    bloomTime: "Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities:
      "Grassland/prairie;Riparian;Wetland;Woodland;Coastal scrub",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Low",
    habitat_value: "Buds/greens;Cover;Fruit",
    associated_wildlife: "Birds;Insects",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    nurseries:
      "Oaktown;Native Here;Candlestick;Bay Natives;East Bay Wilds;Watershed Nursery;",
    photocredit01: "©2005 Aaron Schusteff",
    photocredit02: "©2005 Steve Matson",
    photocredit03: "©2010 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit04: "©2004 Steve Matson",
    habitat: "Yes",
    habitat_int: "1",
    super60: "Y",
    super60_int: "1"
  },
  {
    latin_name: "Horkelia californica",
    common_name: "California Horkelia",
    family_name: "Rosaceae",
    plantType: "Perennial",
    bloomTime: "Spring",
    flower_color: "White",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Coastal scrub;Grassland/prairie",
    suitableSiteConditions: "Sun;Part Shade;Shade",
    soil_type: "Clay;Rock",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2009 Vernon Smith (CC BY-NC-ND 3.0)",
    photocredit02: "©2009 Vernon Smith (CC BY-NC-ND 3.0)",
    photocredit03: "©2013 Jean Pawek",
    photocredit04: "© Br. Alfred Brousseau, Saint Mary's College",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Hosackia gracilis",
    common_name: "Harlequin Lotus",
    family_name: "Fabaceae",
    former_latin_name: "Lotus formosissimus",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Coastal scrub;Riparian;Wetland;Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay",
    waterNeeds: "Moderate",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees;Butterflies",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2012 Neal Kramer",
    photocredit02: "©2012 Robert Sikora",
    photocredit03: "©2013 Aaron Arthur",
    photocredit04: "©2013 Aaron Arthur",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Hymenosporum flavum",
    common_name: "Sweetshade",
    family_name: "Pittosporaceae",
    plantType: "Tree (evergreen)",
    bloomTime: "Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    waterNeeds: "Low",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "2 (B)",
    additional_characteristices_notes:
      "Uneven performer, prefers heat, wind protection, good drainage.",
    photocredit01:
      'SelecTree. "Hymenosporum flavum Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit02:
      'SelecTree. "Hymenosporum flavum Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit03:
      'SelecTree. "Hymenosporum flavum Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit04:
      'SelecTree. "Hymenosporum flavum Photo Record." 1995-2012. Jul 7, 2014.'
  },
  {
    latin_name: "Iris douglasiana",
    common_name: "Douglas Iris",
    family_name: "Iridaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Winter",
    flower_color: "Purple",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Grassland/prairie;Woodland;Coastal scrub",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Low",
    habitat_value: "Buds/greens;Pollinator",
    associated_wildlife: "Bees;Birds;Hummingbirds;Insects",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden;Sidewalk;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Likes wind and fog, include longipella (likes warmer, calmer, drier).",
    nurseries:
      "Oaktown;Native Here;Mission Blue;Candlestick;Flora Grubb;Bay Natives;East Bay Wilds;Sutro Stewards;Watershed Nursery;",
    photocredit01: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit02: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit03: "©1994 Gary A. Monroe (CC BY-NC 3.0)",
    photocredit04: "©1994 Gary A. Monroe (CC BY-NC 3.0)",
    special_list: "Shady Clay",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1",
    super60: "Y",
    super60_int: "1",
    shady_clay: "Shady Clay",
    shady_clay_int: "1"
  },
  {
    latin_name: "Isolepis cernua",
    common_name: "Low Bulrush",
    family_name: "Cyperaceae",
    former_latin_name: "Scirpus cernuus",
    plantType: "Annual",
    bloomTime: "Spring;Summer",
    flower_color: "White",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Wetland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Moderate",
    associated_wildlife: "No data",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2012 Vernon Smith (CC BY-NC-ND 3.0)",
    photocredit02: "©2002 Dean Wm. Taylor (CC BY-NC-SA 3.0)",
    photocredit03: "© linghe (CC BY-NC 3.0)",
    photocredit04: "Toni Corelli (CC BY-NC 2.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Iva axillaris",
    common_name: "Poverty-weed",
    family_name: "Asteraceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Wetland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Moderate",
    associated_wildlife: "No data",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes: "Desert plant.",
    photocredit01: "©2012 Jean Pawek",
    photocredit02: "©2012 Jean Pawek",
    photocredit03: "Matt Lavin (CC BY-SA 2.0)",
    photocredit04: "Matt Lavin (CC BY-SA 2.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Jacaranda mimosifolia",
    common_name: "Jacaranda",
    family_name: "Bignoniaceae",
    plantType: "Tree (evergreen)",
    bloomTime: "Spring;Summer",
    flower_color: "Blue,Lavender",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "2 (B)",
    additional_characteristices_notes:
      "Uneven performer, prefers heat, wind protection, good drainage.",
    photocredit01:
      'SelecTree. "Jacaranda mimosifolia Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit02:
      'SelecTree. "Jacaranda mimosifolia Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit03:
      'SelecTree. "Jacaranda mimosifolia Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit04:
      'SelecTree. "Jacaranda mimosifolia Photo Record." 1995-2012. Jul 7, 2014.',
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Juncus balticus",
    common_name: "Salt Rush",
    family_name: "Juncaceae",
    plantType: "Perennial",
    bloomTime: "Spring",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Moderate",
    habitat_value: "Cover",
    associated_wildlife: "Birds;Butterflies",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes: "Creeping rush, runner not buncher.",
    photocredit01: "©2005 Brent Miller (CC BY-NC-SA 3.0)",
    photocredit02: "©2008 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit03: "©2005 Brent Miller (CC BY-NC-SA 3.0)",
    photocredit04: "Matt Lavin (CC BY-SA 2.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Juncus bufonius",
    common_name: "Toad Rush",
    family_name: "Juncaceae",
    plantType: "Annual",
    bloomTime: "Spring",
    flower_color: "Green",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Wetland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Moderate",
    habitat_value: "Cover;Fruit",
    associated_wildlife: "Birds",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes: "Seedheads.",
    photocredit01: "©2012 Jean Pawek",
    photocredit02: "©2012 Jean Pawek",
    photocredit03: "©2011 Jorg & Mimi Fleige (CC BY-NC 3.0)",
    photocredit04: "©2008 Zoya Akulova (CC BY-NC 3.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Juncus xiphioides",
    common_name: "Iris-leaved Rush",
    family_name: "Juncaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "Burgundy",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Riparian;Wetland;Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Moderate",
    associated_wildlife: "Butterflies",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes: "spreads well by runners.",
    photocredit01: "©2009 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit02: "©2009 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit03: "©2002 Bart and Susan Eisenberg (CC BY 3.0)",
    photocredit04: "©2010 Zoya Akulova (CC BY-NC 3.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Koeleria macrantha",
    common_name: "June Grass",
    family_name: "Poaceae",
    plantType: "Grass",
    bloomTime: "Spring",
    flower_color: "Green",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Chaparral;Coastal scrub;Woodland;Grassland/prairie",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "clay;loam",
    waterNeeds: "None",
    habitat_value: "Fruit",
    associated_wildlife: "Birds",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Roof;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes: "Great bunchgrass.",
    nurseries:
      "Oaktown;Native Here;Flora Grubb;Bay Natives;East Bay Wilds;Sutro Stewards;Watershed Nursery;",
    photocredit01: "©2008 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit02: "©2008 Keir Morse (CC BY-NC-SA 3.0) ",
    photocredit03: "©2010 Zoya Akulova (CC BY-NC 3.0)",
    photocredit04: "©2011 RT Hawke (CC BY-NC 3.0)",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1",
    super60: "Y",
    super60_int: "1"
  },
  {
    latin_name: "Koelreuteria paniculata",
    common_name: "Goldenrain Tree",
    family_name: "Sapindaceae",
    plantType: "Tree (deciduous)",
    bloomTime: "Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "2 (B)",
    additional_characteristices_notes:
      "Uneven performer, prefers heat, wind protection.",
    photocredit01:
      'SelecTree. "Koelreuteria paniculata Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit02:
      'SelecTree. "Koelreuteria paniculata Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit03:
      'SelecTree. "Koelreuteria paniculata Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit04:
      'SelecTree. "Koelreuteria paniculata Photo Record." 1995-2012. Jul 7, 2014.',
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Lagunaria patersonii",
    common_name: "Primrose Tree",
    family_name: "Malvaceae",
    plantType: "Tree (evergreen)",
    bloomTime: "Spring;Summer",
    flower_color: "Pink",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "1 (A)",
    additional_characteristices_notes: "Does well in windy areas.",
    photocredit01:
      'SelecTree. "Lagunaria patersonia Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit02:
      'SelecTree. "Lagunaria patersonia Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit03:
      'SelecTree. "Lagunaria patersonia Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit04:
      'SelecTree. "Lagunaria patersonia Photo Record." 1995-2012. Jul 7, 2014.',
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Lasthenia californica",
    common_name: "California Goldfields",
    family_name: "Asteraceae",
    plantType: "Annual",
    bloomTime: "Spring;Winter",
    flower_color: "Yellow",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Coastal scrub;Grassland/prairie",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Rock",
    waterNeeds: "Low",
    habitat_value: "Fruit;Pollinator",
    associated_wildlife: "Bees;Birds",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit02: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit03: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit04: "© Br. Alfred Brousseau, Saint Mary's College",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Lasthenia glaberrima",
    common_name: "Smooth Goldfields",
    family_name: "Asteraceae",
    plantType: "Annual",
    bloomTime: "Spring",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Coastal scrub;Wetland;Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Moderate",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees;Butterflies",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: 'D In Orbit "OrbitalD" (CC BY-NC-ND 2.0)',
    photocredit02: 'D In Orbit "OrbitalD" (CC BY-NC-ND 2.0)',
    photocredit03: "John Game (CC BY-NC-SA 2.0)",
    photocredit04: "©1994 Dean Wm. Taylor (CC BY-NC-SA 3.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Lasthenia glabrata",
    common_name: "Yellow-rayed Goldfields",
    family_name: "Asteraceae",
    plantType: "Annual",
    bloomTime: "Spring;Winter",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Wetland;Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "clay;loam",
    waterNeeds: "Moderate",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees;Butterflies",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Attracts Checkerspot butterfly (endangered).",
    photocredit01: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit02: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit03: "©2013 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit04: "©2013 Keir Morse (CC BY-NC-SA 3.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Laurus nobilis 'Saratoga'",
    common_name: "Sweet Bay",
    family_name: "Lauraceae",
    plantType: "Tree (evergreen)",
    bloomTime: "Spring",
    flower_color: "Yellow",
    sizeAtMaturity: "13-24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    waterNeeds: "Low",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "2 (B)",
    additional_characteristices_notes:
      "Uneven performer, prefers heat, wind protection, gets pests.",
    photocredit01:
      'SelecTree. "Laurus nobilis Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit02:
      'SelecTree. "Laurus nobilis Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit03:
      'SelecTree. "Laurus nobilis Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit04:
      'SelecTree. "Laurus nobilis Photo Record." 1995-2012. Jul 7, 2014.',
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Lavandula spp.",
    common_name: "Lavender",
    family_name: "Lamiaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Summer;Fall;Winter",
    flower_color: "Purple",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator;Cover",
    associated_wildlife: "Bees;Birds;Butterflies;Hummingbirds",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Relatively short-lived, it will become leggy if not pruned after flowering.",
    nurseries: "Flora Grubb;East Bay Wilds;Sutro Stewards;",
    photocredit01: "©2006 Luigi Rignanese (CC BY-NC 3.0)",
    photocredit02: "Maja Dumat (CC BY-NC-SA 2.0)",
    photocredit03: "Maja Dumat (CC BY 2.0)",
    photocredit04: "Maja Dumat (CC BY 2.0)",
    additional_species_cultivars_varieties:
      "stoechas 'Alba', stoechas 'Willow Vale',  x intermedia 'Provence', x. intermedia 'Grosso',",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1",
    super60: "Y",
    super60_int: "1"
  },
  {
    latin_name: "Leptospermum laevigatum",
    common_name: "Australian Tea Tree",
    family_name: "Myrtaceae",
    plantType: "Tree (evergreen)",
    bloomTime: "Spring",
    flower_color: "White",
    sizeAtMaturity: "13-24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "2 (B)",
    additional_characteristices_notes:
      "Poor rooter, may need long-term or permanent staking.",
    photocredit01:
      'SelecTree. "Leptospermum laevigatum Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit02:
      'SelecTree. "Leptospermum laevigatum Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit03:
      'SelecTree. "Leptospermum laevigatum Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit04:
      'SelecTree. "Leptospermum laevigatum Photo Record." 1995-2012. Jul 7, 2014.',
    thrifty150: "Yes",
    thrifty150_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Leptosyne calliopsidea",
    common_name: "Leafy-stemmed Coreopsis",
    family_name: "Asteraceae",
    former_latin_name: "Coreopsis calliopsidea",
    plantType: "Annual",
    bloomTime: "Spring;Winter",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native ",
    plant_communities: "Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2011 Jean Pawek",
    photocredit02: "©2011 Jean Pawek",
    photocredit03: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit04: "© Br. Alfred Brousseau, Saint Mary's College",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Limonium californicum",
    common_name: "Sea Lavender",
    family_name: "Plumbaginaceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "Purple",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Wetland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Moderate",
    habitat_value: "Buds/greens;Pollinator",
    associated_wildlife: "Bees;Butterflies;Insects",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit02: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit03: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit04: "© Br. Alfred Brousseau, Saint Mary's College",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Liquidambar styraciflua",
    common_name: "American Sweetgum",
    family_name: "Hamamelidaceae",
    plantType: "Tree (deciduous)",
    bloomTime: "Spring",
    flower_color: "Yellow",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Moderate",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "2 (B)",
    additional_characteristices_notes:
      "Needs a very large basin for canopy and roots",
    photocredit01:
      'SelecTree. "Liquidambar styraciflua Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit02:
      'SelecTree. "Liquidambar styraciflua Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit03:
      'SelecTree. "Liquidambar styraciflua Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit04:
      'SelecTree. "Liquidambar styraciflua Photo Record." 1995-2012. Jul 7, 2014.',
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Liriodendron tulipifera",
    common_name: "Tulip Tree",
    family_name: "Magnoliaceae",
    plantType: "Tree (deciduous)",
    bloomTime: "Spring",
    flower_color: "Yellow",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "2 (B)",
    additional_characteristices_notes: "Uneven performer.",
    photocredit01:
      'SelecTree. "Liriodendron tulipifera Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit02:
      'SelecTree. "Liriodendron tulipifera Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit03:
      'SelecTree. "Liriodendron tulipifera Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit04:
      'SelecTree. "Liriodendron tulipifera Photo Record." 1995-2012. Jul 7, 2014.',
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Lomatium californicum",
    common_name: "California Celery",
    family_name: "Apiaceae",
    plantType: "Perennial",
    bloomTime: "Spring",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Chaparral;Woodland",
    suitableSiteConditions: "Part Shade;Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Low",
    habitat_value: "Buds/greens",
    associated_wildlife: "Butterflies",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Dry grower. Attracts Anise Swallowtail (Papilio Zelicaon) butterfly.",
    photocredit01: "©2009 Vernon Smith (CC BY-NC-ND 3.0)",
    photocredit02: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit03: "©2006 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit04: "©2006 Keir Morse (CC BY-NC-SA 3.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Lonicera involucrata",
    common_name: "Twinberry Honeysuckle",
    family_name: "Caprifoliaceae",
    plantType: "Shrub (deciduous)",
    bloomTime: "Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "7-12 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Coastal scrub;Riparian;Wetland;Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Loam;Clay",
    waterNeeds: "Low",
    habitat_value: "Fruit;Pollinator;Cover",
    associated_wildlife: "Bees;Birds;Butterflies;Hummingbirds",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "11;4",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Not wind tolerant, but grows in many habitats. Attracts Cedar Waxwing and  Red Admiral.",
    photocredit01: "John Rusk (CC BY-NC-SA 2.0)",
    photocredit02: "N. Stuart (CC BY-NC-ND 2.0)",
    photocredit03: "sfbaywalk (CC BY 2.0)",
    photocredit04: "avid A. Hofmann (CC BY-NC-ND 2.0)",
    special_list: "Shady Clay",
    habitat: "Yes",
    habitat_int: "1",
    shady_clay: "Shady Clay",
    shady_clay_int: "1"
  },
  {
    latin_name: "Lophostemon confertus",
    common_name: "Brisbane Box",
    family_name: "Myrtaceae",
    former_latin_name: "Tristania conferta",
    plantType: "Tree (evergreen)",
    bloomTime: "Spring;Summer",
    flower_color: "White",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "1 (A)",
    photocredit01:
      'SelecTree. "Lophostemon confertus Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit02:
      'SelecTree. "Lophostemon confertus Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit03:
      'SelecTree. "Lophostemon confertus Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit04:
      'SelecTree. "Lophostemon confertus Photo Record." 1995-2012. Jul 7, 2014.',
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Lupinus albifrons",
    common_name: "Silver Lupine",
    family_name: "Fabaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "Purple",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Chaparral;Coastal scrub;Woodland",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand;Rock",
    waterNeeds: "None",
    habitat_value: "Buds/greens;Pollinator",
    associated_wildlife: "Bees;Birds;Butterflies;Hummingbirds",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden;Sidewalk;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Host to the endangered Mission Blue Butterfly.",
    photocredit01: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit02: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit03: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit04: "© Br. Alfred Brousseau, Saint Mary's College",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Passiflora caerulea",
    common_name: "Passion Vine",
    family_name: "Passifloraceae",
    plantType: "Vine",
    bloomTime: "Spring;summer",
    flower_color: "White;Purple",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator;Cover;Nesting",
    associated_wildlife: "Butterflies",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2015 Zoya Akulova",
    photocredit02: "©2015 Ron Vanderhoff",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Lupinus arboreus",
    common_name: "Yellow Bush Lupine",
    family_name: "Fabaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring",
    flower_color: "Yellow",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Coastal scrub;Grassland/prairie;Dunes",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand;Rock",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees;Butterflies;Hummingbirds",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Only plant in western sandy areas.  Grows fast, then dies out fast.  Lupines should be planted as plants – unpredictable germination.",
    photocredit01: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit02: "Jon Sullivan (CC BY-NC 2.0)",
    photocredit03: "helen.2006 (CC BY-NC-SA 2.0)",
    photocredit04: "James Gaither (CC BY-NC-ND 2.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Lupinus bicolor",
    common_name: "Miniature Lupine",
    family_name: "Fabaceae",
    plantType: "Annual",
    bloomTime: "Spring",
    flower_color: "Purple;White",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Coastal scrub;Grassland/prairie;Dunes",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "None",
    habitat_value: "Cover;Pollinator",
    associated_wildlife: "Bees;Butterflies;Hummingbirds",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes: "Plant to the east of sunset.",
    nurseries:
      "Oaktown;Native Here;Bay Natives;East Bay Wilds;Watershed Nursery;",
    photocredit01: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit02: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit03: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit04: "© Br. Alfred Brousseau, Saint Mary's College",
    special_list: "Sandy Soil",
    habitat: "Yes",
    habitat_int: "1",
    super60: "Y",
    super60_int: "1",
    sandy_soil: "Sandy Soil",
    sandy_soil_int: "1"
  },
  {
    latin_name: "Lupinus chamissonis",
    common_name: "Silver Dune Lupine",
    family_name: "Fabaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring",
    flower_color: "Indigo",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Dunes",
    suitableSiteConditions: "Sun",
    soil_type: "Sand",
    waterNeeds: "None",
    habitat_value: "Pollinator;Cover",
    associated_wildlife: "Butterflies;Hummingbirds",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2013 Gary A. Monroe (CC BY-NC 3.0)",
    photocredit02: "©2013 Gary A. Monroe (CC BY-NC 3.0)",
    photocredit03: "©2013 Gary A. Monroe (CC BY-NC 3.0)",
    photocredit04: "© Br. Alfred Brousseau, Saint Mary's College",
    special_list: "Sandy Soil",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1",
    sandy_soil: "Sandy Soil",
    sandy_soil_int: "1"
  },
  {
    latin_name: "Lupinus variicolor",
    common_name: "Varied Lupine",
    family_name: "Fabaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "Purple;White",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Coastal scrub;Grassland/prairie",
    suitableSiteConditions: "Sun",
    soil_type: "Loam;Sand",
    waterNeeds: "None",
    habitat_value: "Cover;Pollinator",
    associated_wildlife: "Bees;Butterflies;Hummingbirds",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden;Sidewalk;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes: "Plant to the east of sunset.",
    photocredit01: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit02: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit03: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit04: "© Br. Alfred Brousseau, Saint Mary's College",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Lyonothamnus floribundus ssp. Asplenifolius",
    common_name: "Catalina Ironwood",
    family_name: "Rosaceae",
    plantType: "Tree (evergreen)",
    bloomTime: "Spring;Summer",
    flower_color: "White",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam",
    waterNeeds: "Low",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "2 (B)",
    additional_characteristices_notes: "Prefers heat, wind protection.",
    photocredit01:
      'SelecTree. "Lyonothamnus floribundus subsp. asplenifolius Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit02:
      'SelecTree. "Lyonothamnus floribundus subsp. asplenifolius Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit03:
      'SelecTree. "Lyonothamnus floribundus subsp. asplenifolius Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit04:
      'SelecTree. "Lyonothamnus floribundus subsp. asplenifolius Photo Record." 1995-2012. Jul 7, 2014.',
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Lythrum californicum",
    common_name: "Common loosestrife",
    family_name: "Lythraceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "Pink",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Wetland; Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Loam",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2012 Robert Sivinski (CC BY-NC 3.0)",
    photocredit02: "Joe Decruyenaere (CC BY-SA 2.0)",
    photocredit03: "©2002 Gary A. Monroe (CC BY-NC 3.0)",
    photocredit04: "©2002 Gary A. Monroe (CC BY-NC 3.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Madia sativa",
    common_name: "Common Tarweed",
    family_name: "Asteraceae",
    plantType: "Annual",
    bloomTime: "Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Chaparral;Coastal scrub;Grassland/prairie",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Sand",
    waterNeeds: "None",
    habitat_value: "Fruit;Pollinator",
    associated_wildlife: "Bees;Birds;Butterflies",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes: "Attracts Western Meadowlark bird.",
    photocredit01: "©2008 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit02: "©2008 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit03: "©2008 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit04: "©2008 Keir Morse (CC BY-NC-SA 3.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Magnolia  doltsopa",
    common_name: "Sweet Michelia",
    family_name: "Magnoliaceae",
    former_latin_name: "Michelia doltsopa",
    plantType: "Tree (evergreen)",
    bloomTime: "Spring",
    flower_color: "White",
    sizeAtMaturity: "13-24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    waterNeeds: "Low",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "2 (B)",
    additional_characteristices_notes:
      "Formerly Michelia doltsopa; uneven performer, grows very slowly, prefers heat, wind protection.",
    photocredit01:
      'SelecTree. "Michelia doltsopa Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit02:
      'SelecTree. "Michelia doltsopa Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit03:
      'SelecTree. "Michelia doltsopa Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit04:
      'SelecTree. "Michelia doltsopa Photo Record." 1995-2012. Jul 7, 2014.',
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Magnolia champaca",
    common_name: "Golden Champaca",
    family_name: "Magnoliaceae",
    former_latin_name: "Michelia champaca",
    plantType: "Tree (evergreen)",
    bloomTime: "Spring;Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "13-24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    waterNeeds: "Low",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "2 (B)",
    additional_characteristices_notes:
      "Formerly Michelia champaca; needs wind protection, wide sidewalk, gets powdery mildew and very slow grower.",
    photocredit01:
      'SelecTree. "Magnolia champaca Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit02:
      'SelecTree. "Magnolia champaca Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit03: "Mauricio Mercadante (CC BY-NC-SA 2.0)",
    photocredit04: "Mauricio Mercadante (CC BY-NC-SA 2.0)",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Magnolia grandiflora 'Little Gem'",
    common_name: "Little Gem Magnolia",
    family_name: "Magnoliaceae",
    plantType: "Tree (evergreen)",
    bloomTime: "Summer;Fall",
    flower_color: "White",
    sizeAtMaturity: "13-24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    associated_wildlife: "Birds;Butterflies",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "1 (A)",
    photocredit01:
      "SelecTree. \"Magnolia grandiflora 'Little Gem' Photo Record.\" 1995-2012. Jul 7, 2014.",
    photocredit02:
      "SelecTree. \"Magnolia grandiflora 'Little Gem' Photo Record.\" 1995-2012. Jul 7, 2014.",
    photocredit03:
      "SelecTree. \"Magnolia grandiflora 'Little Gem' Photo Record.\" 1995-2012. Jul 7, 2014.",
    photocredit04:
      "SelecTree. \"Magnolia grandiflora 'Little Gem' Photo Record.\" 1995-2012. Jul 7, 2014.",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Magnolia grandiflora 'Majestic Beauty', 'Samuel Sommer'",
    common_name: "Majestic Beauty/Samuel Sommer Southern Magnolia",
    family_name: "Magnoliaceae",
    plantType: "Tree (evergreen)",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    waterNeeds: "Low",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "1 (A)",
    photocredit01:
      "SelecTree. \"Magnolia grandiflora 'Majestic Beauty' Photo Record.\" 1995-2012. Jul 7, 2014.",
    photocredit02:
      "SelecTree. \"Magnolia grandiflora 'Samuel Sommer' Photo Record.\" 1995-2012. Jul 7, 2014.",
    photocredit03:
      "SelecTree. \"Magnolia grandiflora 'Samuel Sommer' Photo Record.\" 1995-2012. Jul 7, 2014.",
    photocredit04:
      "SelecTree. \"Magnolia grandiflora 'Samuel Sommer' Photo Record.\" 1995-2012. Jul 7, 2014.",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Magnolia grandiflora 'St. Mary'",
    common_name: "St. Mary Magnolia",
    family_name: "Magnoliaceae",
    plantType: "Tree (evergreen)",
    bloomTime: "Summer;Fall",
    flower_color: "White",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    associated_wildlife: "Birds;Butterflies",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "1 (A)",
    photocredit01:
      "SelecTree. \"Magnolia grandiflora 'St. Mary' Photo Record.\" 1995-2012. Jul 7, 2014.",
    photocredit02:
      "SelecTree. \"Magnolia grandiflora 'St. Mary' Photo Record.\" 1995-2012. Jul 7, 2014.",
    photocredit03:
      "SelecTree. \"Magnolia grandiflora 'St. Mary' Photo Record.\" 1995-2012. Jul 7, 2014.",
    photocredit04:
      "SelecTree. \"Magnolia grandiflora 'St. Mary' Photo Record.\" 1995-2012. Jul 7, 2014.",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Mahonia repens",
    common_name: "Creeping Mahonia",
    family_name: "Berberidaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Chaparral;Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    habitat_value: "Fruit",
    associated_wildlife: "Birds;Butterflies",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "Bryant Olsen (CC BY-NC 2.0)",
    photocredit02: "Rick Leche (CC BY-NC-ND 2.0)",
    photocredit03: "Matt Lavin (CC BY-SA 2.0)",
    photocredit04: "Matt Lavin (CC BY-SA 2.0)",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Dasylirion wheeleri",
    common_name: "Desert Spoon",
    family_name: "Nolinaceae",
    plantType: "succulent",
    bloomTime: "Summer",
    flower_color: "White",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2009 Robert Sivinski",
    photocredit02: "©2011 Megan Hansen",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Malva assurgentiflora",
    common_name: "Malva Rosa, Island Mallow",
    family_name: "Malvaceae",
    former_latin_name: "Lavatera assurgentiflora",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Summer;Winter",
    flower_color: "Pink",
    sizeAtMaturity: "7-12 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Coastal scrub",
    suitableSiteConditions: "Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    habitat_value: "Buds/greens",
    associated_wildlife: "Butterflies;Insects",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Attracts Grey Hairstreak (Strymon melinus) and Painted Lady (Vanessa cardui) butterflies.",
    photocredit01: " © Br. Alfred Brousseau, Saint Mary's College",
    photocredit02: " © Br. Alfred Brousseau, Saint Mary's College",
    photocredit03: "©2010 Gary A. Monroe (CC BY-NC 3.0)",
    photocredit04: "©2010 Gary A. Monroe (CC BY-NC 3.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Arctostaphylos spp.",
    common_name: "Manzanita",
    family_name: "Ericaceae",
    plantType: "Shrub (evergreen)",
    sizeAtMaturity: "varies",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Birds;Bees",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2006 dogtooth77",
    photocredit02: "©2009 Roy Randall",
    additional_species_cultivars_varieties:
      "densiflora 'Howard McMinn', densiflora 'Lutsko Pink', 'Emerald Carpet, glauca, uva-ursi, 'John Dourley', 'Pacific Mist'",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1",
    super60: "Y",
    super60_int: "1"
  },
  {
    latin_name: "Artemisia spp.",
    common_name: "California Sagebrush",
    family_name: "Asteraceae",
    plantType: "Shrub (evergreen)",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Birds;Bees",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2012 Jean Pawek",
    photocredit02: "©2010 Jean Pawek",
    additional_species_cultivars_varieties:
      "californica, douglasiana, tridentata, 'Powis Castle',pycnocephala, 'David's Choice ',schmidtiana 'Silvermound'",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Marah fabacea",
    common_name: "Wild Cucumber/Manroot",
    family_name: "Cucurbitales",
    plantType: "Vine",
    bloomTime: "Spring",
    flower_color: "White",
    sizeAtMaturity: "13-24 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Coastal scrub;Dunes;Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "None",
    habitat_value: "Fruit",
    associated_wildlife: "Birds",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "14",
    street_tree_list: "NA",
    additional_characteristices_notes: "Attracts Scrub Jays.",
    nurseries:
      "Oaktown;Native Here;Mission Blue;Bay Natives;East Bay Wilds;Sutro Stewards;Watershed Nursery;",
    photocredit01: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit02: "©2009 Barry Breckling (CC BY-NC-SA 3.0)",
    photocredit03: "©2008 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit04: "©2008 Keir Morse (CC BY-NC-SA 3.0)",
    special_list: "Sandy Soil",
    habitat: "Yes",
    habitat_int: "1",
    super60: "Y",
    super60_int: "1",
    sandy_soil: "Sandy Soil",
    sandy_soil_int: "1"
  },
  {
    latin_name: "Marah oregana",
    common_name: "Coast Manroot",
    family_name: "Cucurbitales",
    plantType: "Vine",
    bloomTime: "Spring",
    flower_color: "White",
    sizeAtMaturity: "13-24 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Woodland",
    suitableSiteConditions: "Part shade;Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    habitat_value: "Fruit",
    associated_wildlife: "Birds",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Only one specimen in SF in John McLaren Park.",
    photocredit01: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit02: "©2007 Zoya Akulova (CC BY-NC 3.0)",
    photocredit03: "©2012 Zoya Akulova (CC BY-NC 3.0)",
    photocredit04: "©2013 Zoya Akulova (CC BY-NC 3.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Cotinus spp.",
    common_name: "Smoke Bush",
    family_name: "Anacardiaceae",
    plantType: "Shrub (evergreen)",
    sizeAtMaturity: "7-12 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    habitat_value: "Cover;Nesting",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2016 Zoya Akulova",
    photocredit02: "©2016 Zoya Akulova",
    additional_species_cultivars_varieties: "coggygria, obvatus  ",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Melaleuca quinquenervia",
    common_name: "Cajeput Tree",
    family_name: "Myrtaceae",
    plantType: "Tree (evergreen)",
    bloomTime: "Summer;Fall",
    flower_color: "White",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "2 (B)",
    additional_characteristices_notes:
      "Grows fast, dense, irregular form, prefers wind protection.",
    photocredit01:
      'SelecTree. "Melaleuca quinquenervia Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit02:
      'SelecTree. "Melaleuca quinquenervia Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit03:
      'SelecTree. "Melaleuca quinquenervia Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit04:
      'SelecTree. "Melaleuca quinquenervia Photo Record." 1995-2012. Jul 7, 2014.',
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Melaleuca styphelioides",
    common_name: "Prickly Melaleuca",
    family_name: "Myrtaceae",
    plantType: "Tree (evergreen)",
    bloomTime: "Spring;Summer",
    flower_color: "White",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "2 (B)",
    additional_characteristices_notes: "Can root poorly, and prickly leaves.",
    photocredit01:
      'SelecTree. "Melaleuca styphelioides Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit02:
      'SelecTree. "Melaleuca styphelioides Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit03:
      'SelecTree. "Melaleuca styphelioides Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit04:
      'SelecTree. "Melaleuca styphelioides Photo Record." 1995-2012. Jul 7, 2014.',
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Melica californica",
    common_name: "California Melic ",
    family_name: "Poaceae",
    plantType: "Grass",
    bloomTime: "Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Woodland;Grassland/prairie",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "None",
    habitat_value: "Fruit",
    associated_wildlife: "Birds",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Requires moisture in growing season, then wants to be dry after flowering.",
    nurseries:
      "Oaktown;Flora Grubb;Bay Natives;East Bay Wilds;Sutro Stewards;Watershed Nursery;",
    photocredit01: " ©2011 Barry Breckling (CC BY-NC-SA 3.0)",
    photocredit02: "©2010 Barry Breckling (CC BY-NC-SA 3.0)",
    photocredit03: "©2009 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit04: "©2009 Keir Morse (CC BY-NC-SA 3.0)",
    habitat: "Yes",
    habitat_int: "1",
    super60: "Y",
    super60_int: "1"
  },
  {
    latin_name: "Melica torreyana",
    common_name: "Torrey melic",
    family_name: "Poaceae",
    plantType: "Grass",
    bloomTime: "Spring",
    flower_color: "Purple;Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Rock",
    waterNeeds: "Low",
    habitat_value: "Cover;Fruit;Nesting",
    associated_wildlife: "Birds;Butterflies;Insects",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes: "Best in rocky outcrops.",
    photocredit01: "Belinda Lo (CC BY-NC-SA 2.0)",
    photocredit02: "D In Orbit (CC BY-NC-ND 2.0)",
    photocredit03: "©2012 Al Keuter (CC BY-NC-SA 3.0)",
    photocredit04: "©2012 Al Keuter (CC BY-NC-SA 3.0)",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Metrosideros excelsa",
    common_name: "New Zealand Christmas tree",
    family_name: "Myrtaceae",
    plantType: "Tree (evergreen)",
    bloomTime: "Spring;Summer;Fall;Winter",
    flower_color: "Red",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Loam;Sand",
    waterNeeds: "Low",
    habitat_value: "Fruit;Pollinator",
    associated_wildlife: "Bees;Birds;Butterflies;Insects",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "2 (B)",
    additional_characteristices_notes:
      "Endures wind well and is a common SF street tree.",
    photocredit01:
      'SelecTree. "Metrosideros excelsa Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit02:
      'SelecTree. "Metrosideros excelsa Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit03:
      'SelecTree. "Metrosideros excelsa Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit04:
      'SelecTree. "Metrosideros excelsa Photo Record." 1995-2012. Jul 7, 2014.',
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Mimulus aurantiacus",
    common_name: "Sticky Monkeyflower",
    family_name: "Phrymaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Summer",
    flower_color: "Orange",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Coastal scrub;Woodland;Dunes",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand;Rock",
    waterNeeds: "None",
    habitat_value: "Buds/greens;Pollinator",
    associated_wildlife: "Bees;Birds;Butterflies;Hummingbirds;Insects",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden;Roof",
    suggested_green_connection_routes: "22",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Plant in dry areas, endures wind well. Attracts Nutall's White-crowned Sparrow, Buckeye (Junonia coenia) and Variable Checkerspot (Euphydras chalcedona) butterflies, and Anna's Hummingbird.",
    nurseries:
      "Oaktown;Native Here;Mission Blue;Candlestick;Flora Grubb;Bay Natives;East Bay Wilds;Sutro Stewards;Watershed Nursery;",
    photocredit01: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit02: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit03: "©2007 Steven Thorsted (CC BY-NC 3.0)",
    photocredit04: "©2007 Steven Thorsted (CC BY-NC 3.0)",
    special_list: "Sandy Soil;Top 20",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1",
    super60: "Y",
    super60_int: "1",
    top20_int: "1",
    sandy_soil: "Sandy Soil",
    sandy_soil_int: "1"
  },
  {
    latin_name: "Dodonaea viscosa",
    common_name: "Hopseed Bush",
    family_name: "Sapindaceae",
    plantType: "Shrub (evergreen)",
    sizeAtMaturity: "7-12 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    habitat_value: "Cover;Nesting",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2016 Zoya Akulova",
    photocredit02: "©2016 Zoya Akulova",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Monardella villosa",
    common_name: "Coyote Mint",
    family_name: "Lamiaceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "Purple",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Coastal scrub;Woodland;Grassland/prairie",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees;Butterflies;Hummingbirds",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden;Roof",
    suggested_green_connection_routes: "12;20",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Prefer dry areas. Attracts Western Fence Lizard.",
    nurseries:
      "Oaktown;Mission Blue;Candlestick;Flora Grubb;Bay Natives;East Bay Wilds;Sutro Stewards;Watershed Nursery;",
    photocredit01: "©2009 Vernon Smith (CC BY-NC-ND 3.0)",
    photocredit02: "©2009 Barry Breckling (CC BY-NC-SA 3.0)",
    photocredit03: "©2009 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit04: "©2009 Keir Morse (CC BY-NC-SA 3.0)",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1",
    super60: "Y",
    super60_int: "1"
  },
  {
    latin_name: "Morella californica",
    common_name: "California Wax Myrtle, Pacific Bayberry",
    family_name: "Myricaceae",
    former_latin_name: "Myrica californica",
    plantType: "Shrub (evergreen)",
    bloomTime: "Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "13-24 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Coastal scrub;Riparian;Woodland;Wetland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Moderate",
    habitat_value: "Cover;Fruit;Pollinator;Nesting",
    associated_wildlife: "Bees;Birds;Butterflies",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Endures wind well. May grow as a single trunk standard tree.",
    nurseries:
      "Oaktown;Native Here;Mission Blue;Flora Grubb;Bay Natives;East Bay Wilds;Sutro Stewards;",
    photocredit01: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit02: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit03: "©2008 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit04: "©2008 Keir Morse (CC BY-NC-SA 3.0)",
    special_list: "Shady Clay",
    habitat: "Yes",
    habitat_int: "1",
    super60: "Y",
    super60_int: "1",
    shady_clay: "Shady Clay",
    shady_clay_int: "1"
  },
  {
    latin_name: "Muhlenbergia rigens",
    common_name: "Deer Grass",
    family_name: "Poaceae",
    plantType: "Grass",
    bloomTime: "Spring",
    flower_color: "White",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Chaparral",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Low",
    habitat_value: "Nesting",
    associated_wildlife: "Bees;Birds;Butterflies",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "William Herron (CC BY-SA 2.0)",
    photocredit02: "Bri Weldon (CC BY 2.0)",
    photocredit03: "Anthony Mendoza (CC BY-NC-SA 2.0)",
    photocredit04: "Josh S Jackson (CC BY-NC 2.0)",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Nepeta racemosa",
    common_name: "Cat Mint",
    family_name: "Lamiaceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "Purple",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees;Butterflies;Hummingbirds",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "Scott Davies (CC BY-NC 2.0)",
    photocredit02: "Jim, the photographer (CC BY 2.0)",
    photocredit03: "--Tico-- (CC BY-NC-ND 2.0)",
    photocredit04: "beautifulcataya (CC BY-NC-ND 2.0)",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Oenanthe sarmentosa",
    common_name: "Ditch-carrot, Water Parsley",
    family_name: "Apiaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "White",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Riparian;Wetland;Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Moderate",
    habitat_value: "Buds/greens",
    associated_wildlife: "Insects",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes: "Wet grower.",
    photocredit01: "©2002 Brad Kelley",
    photocredit02: "©2014 Jean Pawek",
    photocredit03: "©2008 Keir Morse  (CC BY-NC-SA 3.0)",
    photocredit04: "©2008 Keir Morse  (CC BY-NC-SA 3.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Oenothera elata ssp. hookeri",
    common_name: "Hooker's Evening Primrose",
    family_name: "Onagraceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Coastal scrub;Woodland;Dunes;Chaparral",
    suitableSiteConditions: "Sun",
    soil_type: "Loam;Sand;Clay",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees;Birds;Butterflies;Hummingbirds;Insects",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden;Sidewalk;Roof",
    suggested_green_connection_routes: "12;20",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Resilient, gets weedy, self sows. Attracts Western fence lizard and Goldfinch.",
    photocredit01: "Suzanne Black (CC BY-NC-ND 2.0)",
    photocredit02: "Bryant Olsen (CC BY-NC 2.0)",
    photocredit03: "David A. Hofmann (CC BY-NC-ND 2.0)",
    photocredit04: "John Rusk (CC BY-NC-SA 2.0)",
    special_list: "Sandy Soil",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1",
    sandy_soil: "Sandy Soil",
    sandy_soil_int: "1"
  },
  {
    latin_name: "Olea europaea",
    common_name: "Olive Tree",
    family_name: "Oleaceae",
    plantType: "Tree (evergreen)",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "None",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "2 (B)",
    additional_characteristices_notes:
      "Needs a very large basin, prefers wind protection.",
    photocredit01:
      'SelecTree. "Olea europaea Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit02:
      'SelecTree. "Olea europaea Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit03:
      'SelecTree. "Olea europaea Photo Record." 1995-2012. Jul 7, 2014.',
    photocredit04:
      'SelecTree. "Olea europaea Photo Record." 1995-2012. Jul 7, 2014.',
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Penstemon centranthifolius",
    common_name: "Scarlet Bugler",
    family_name: "Plantaginaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "Red",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Chaparral",
    suitableSiteConditions: "Sun",
    soil_type: "Clay",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees;Birds;Butterflies;Hummingbirds",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2010 Gary A. Monroe (CC BY-NC 3.0)",
    photocredit02: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit03: "©2009 Barry Breckling (CC BY-NC-SA 3.0)",
    photocredit04: "©2009 Keir Morse (CC BY-NC-SA 3.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Penstemon heterophyllus",
    common_name: "Penstemon",
    family_name: "Plantaginaceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "Indigo",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Chaparral;Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees;Birds;Butterflies;Hummingbirds",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden;Sidewalk;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2009 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit02: "©2009 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit03: "©2013 Vernon Smith (CC BY-NC-ND 3.0)",
    photocredit04: "©2010 Jean Pawek",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "v",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Persicaria amphibia",
    common_name: "Water Smartweed",
    family_name: "Polygonaceae",
    plantType: "Perennial",
    bloomTime: "Summer;Fall",
    flower_color: "Pink",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Wetland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Moderate",
    associated_wildlife: "Birds",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2014 Neal Kramer",
    photocredit02: "©2011 Barry Breckling (CC BY-NC-SA 3.0)",
    photocredit03: "©2010 Vernon Smith (CC BY-NC-ND 3.0)",
    photocredit04: "©2014 Neal Kramer",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Persicaria lapathifolia",
    common_name: "Common Knotweed",
    family_name: "Polygonaceae",
    plantType: "Annual",
    bloomTime: "Summer;Fall",
    flower_color: "Pink;White",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Wetland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Moderate",
    associated_wildlife: "Birds",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2012 Jean Pawek",
    photocredit02: "©2012 Jorg & Mimi Fleige  (CC BY-NC 3.0)",
    photocredit03: "©2012 Jean Pawek",
    photocredit04: "Patrick Alexander (CC BY-NC-ND 2.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Persicaria punctata",
    common_name: "Dotted Smartweed",
    family_name: "Polygonaceae",
    plantType: "Perennial",
    bloomTime: "Summer;Fall",
    flower_color: "White",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Wetland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Moderate",
    associated_wildlife: "Birds",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes: "Wet grower.",
    photocredit01: "©2005 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit02: "©2005 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit03: "©2011 Jean Pawek",
    photocredit04: "©2011 Jean Pawek",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Phacelia californica",
    common_name: "California Phacelia",
    family_name: "Boraginaceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "Purple",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Coastal scrub;Chaparral",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam",
    waterNeeds: "None",
    habitat_value: "Buds/greens;Pollinator",
    associated_wildlife: "Bees;Butterflies;Insects",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Attracts endangered Mission Blue butterfly.",
    nurseries:
      "Oaktown;Mission Blue;Candlestick;Flora Grubb;Bay Natives;East Bay Wilds;Sutro Stewards;Watershed Nursery;",
    photocredit01: "©2009 Zoya Akulova (CC BY-NC 3.0)",
    photocredit02: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit03: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit04: "© Br. Alfred Brousseau, Saint Mary's College",
    special_list: "Top 20",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1",
    super60: "Y",
    super60_int: "1",
    top20_int: "1"
  },
  {
    latin_name: "Phacelia malvifolia",
    common_name: "Stinging Phacelia",
    family_name: "Boraginaceae",
    plantType: "Annual",
    bloomTime: "Spring;Summer",
    flower_color: "White",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Coastal scrub;Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Loam;Sand;Clay",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2010 Jean Pawek",
    photocredit02: "©2011 Jean Pawek",
    photocredit03: "©2008 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit04: "©2008 Keir Morse (CC BY-NC-SA 3.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Phalaris californica",
    common_name: "California Canarygrass",
    family_name: "Poaceae",
    plantType: "Grass",
    bloomTime: "Spring",
    flower_color: "Magenta",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Coastal scrub;Grassland/prairie;Wetland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Low",
    habitat_value: "Buds/greens;Cover;Fruit",
    associated_wildlife: "Birds;Butterflies",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Rare in SF, but present on San Bruno mountain. Attracks Woodland Skipper (Ochlodes sylvanoides) butterfly.",
    photocredit01: "©2013 Vernon Smith (CC BY-NC-ND 3.0)",
    photocredit02: "©2009 Zoya Akulova (CC BY-NC 3.0)",
    photocredit03: "©2009 Zoya Akulova (CC BY-NC 3.0)",
    photocredit04: "©2010 Zoya Akulova (CC BY-NC 3.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Echium candicans",
    common_name: "Pride of Madeira",
    family_name: "Boraginaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Summer;Fall",
    flower_color: "Purple",
    sizeAtMaturity: "7-12 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "Low",
    habitat_value: "Pollinator;Cover;Nesting",
    associated_wildlife: "Birds;Butterflies",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2016 Zoya Akulova",
    photocredit02: "©2016 Zoya Akulova",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Grevillea spp.",
    common_name: "Grevillea",
    family_name: "Proteaceae",
    plantType: "Shrub (evergreen)",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part shade",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Birds;Bees",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2016 Zoya Akulova",
    photocredit02: "©2014 Zoya Akulova",
    additional_species_cultivars_varieties:
      "'Austraflora Fanfare', lanigera 'Coastal Gem',  'Magic Lantern',robusta",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Phoenix dactylifera",
    common_name: "Date Palm",
    family_name: "Arecaceae",
    plantType: "Tree (evergreen)",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    waterNeeds: "Low",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "2 (B)",
    additional_characteristices_notes: "Needs a large basin and wide sidewalk.",
    photocredit01:
      'SelecTree. "Phoenix dactylifera Photo Record." 1995-2012. Jul 8, 2014.',
    photocredit02:
      'SelecTree. "Phoenix dactylifera Photo Record." 1995-2012. Jul 8, 2014.',
    photocredit03:
      'SelecTree. "Phoenix dactylifera Photo Record." 1995-2012. Jul 8, 2014.',
    photocredit04:
      'SelecTree. "Phoenix dactylifera Photo Record." 1995-2012. Jul 8, 2014.',
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Pinus contorta var. contorta",
    common_name: "Shore Pine",
    family_name: "Pinaceae",
    plantType: "Tree (evergreen)",
    bloomTime: "Spring",
    flower_color: "Brown",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "CA Native ",
    plant_communities: "Riparian",
    suitableSiteConditions: "Sun",
    soil_type: "Loam",
    waterNeeds: "Moderate",
    associated_wildlife: "Bees;Butterflies",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "2",
    street_tree_list: "NA",
    photocredit01: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit02: "©2012 Vernon Smith (CC BY-NC-ND 3.0)",
    photocredit03: "©2012 Vernon Smith (CC BY-NC-ND 3.0)",
    photocredit04: "Jason Sturner (CC BY 2.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Pinus muricata",
    common_name: "Bishop Pine",
    family_name: "Pinaceae",
    plantType: "Tree (evergreen) ",
    bloomTime: "Spring",
    flower_color: "Orange",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "CA Native ",
    plant_communities: "Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Loam",
    waterNeeds: "Moderate",
    habitat_value: "Buds/greens",
    associated_wildlife: "Insects",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "2",
    street_tree_list: "NA",
    photocredit01: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit02: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit03: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit04: "© Br. Alfred Brousseau, Saint Mary's College",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Rhamnus crocea",
    common_name: "Redberry",
    family_name: "Rhamnaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Winter",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Chaparral;Coastal scrub;Woodland",
    suitableSiteConditions: "Sun;Part shade",
    soil_type: "Clay;Rock",
    waterNeeds: "Low",
    habitat_value: "Fruit;Pollinator",
    associated_wildlife: "Bees;Hummingbirds",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2009 Vernon Smith (CC BY-NC-ND 3.0)",
    photocredit02: "©2012 Jean Pawek",
    photocredit03: "©2012 Jean Pawek",
    photocredit04: "©2012 Jean Pawek",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Pistacia chinensis 'Keith Davey'",
    common_name: "Keith Davey Chinese Pistache",
    family_name: "Anacardiaceae",
    plantType: "Tree (deciduous)",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    waterNeeds: "Low",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "2 (B)",
    additional_characteristices_notes: "Prefers heat, wind protection.",
    photocredit01:
      'SelecTree. "Pistacia chinensis Photo Record." 1995-2012. Jul 8, 2014.',
    photocredit02:
      'SelecTree. "Pistacia chinensis Photo Record." 1995-2012. Jul 8, 2014.',
    photocredit03:
      'SelecTree. "Pistacia chinensis Photo Record." 1995-2012. Jul 8, 2014.',
    photocredit04:
      'SelecTree. "Pistacia chinensis Photo Record." 1995-2012. Jul 8, 2014.',
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Pittosporum crassifolium",
    common_name: "Seaside Pittosporum",
    family_name: "Pittosporaceae",
    plantType: "Tree (evergreen)",
    bloomTime: "Spring",
    flower_color: "Red",
    sizeAtMaturity: "13-24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    waterNeeds: "Low",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "2 (B)",
    additional_characteristices_notes:
      "Tolerates wind and fog. Can leak sap from trunk.",
    photocredit01:
      'SelecTree. "Pittosporum crassifolium Photo Record." 1995-2012. Jul 8, 2014.',
    photocredit02:
      'SelecTree. "Pittosporum crassifolium Photo Record." 1995-2012. Jul 8, 2014.',
    photocredit03:
      'SelecTree. "Pittosporum crassifolium Photo Record." 1995-2012. Jul 8, 2014.',
    photocredit04:
      'SelecTree. "Pittosporum crassifolium Photo Record." 1995-2012. Jul 8, 2014.',
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Pittosporum undulatum",
    common_name: "Victorian Box",
    family_name: "Pittosporaceae",
    plantType: "Tree (evergreen)",
    bloomTime: "Spring;Summer",
    flower_color: "White",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    waterNeeds: "None",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "1 (A)",
    additional_characteristices_notes:
      "Can self-sow, take care when planting near natural areas.",
    photocredit01:
      'SelecTree. "Pittosporum undulatum Photo Record." 1995-2012. Jul 8, 2014.',
    photocredit02:
      'SelecTree. "Pittosporum undulatum Photo Record." 1995-2012. Jul 8, 2014.',
    photocredit03:
      'SelecTree. "Pittosporum undulatum Photo Record." 1995-2012. Jul 8, 2014.',
    photocredit04:
      'SelecTree. "Pittosporum undulatum Photo Record." 1995-2012. Jul 8, 2014.',
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Plantago elongata",
    common_name: "Coastal Plantain",
    family_name: "Plantaginaceae",
    plantType: "Annual",
    bloomTime: "Spring",
    flower_color: "Green",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Riparian;Wetland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Moderate",
    habitat_value: "Buds/greens",
    associated_wildlife: "Butterflies",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Attracts Common Buckeye (Junonia coenia) butterfly.",
    photocredit01: " ©2010 Gary A. Monroe (CC BY-NC 3.0)",
    photocredit02: " ©2010 Gary A. Monroe (CC BY-NC 3.0)",
    photocredit03: " ©2010 Gary A. Monroe (CC BY-NC 3.0)",
    photocredit04: "Matt Lavin (CC BY-SA 2.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Plantago maritima",
    common_name: "Seaside plantain",
    family_name: "Plantaginaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "Brown",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Coastal scrub;Wetland;Dunes",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    habitat_value: "Buds/greens",
    associated_wildlife: "Butterflies;Insects",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Succulent that grows on cliff faces and likes salt wind. Attracts Common Buckeye (Junonia coenia) butterfly.",
    photocredit01: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit02: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit03: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit04: "© Br. Alfred Brousseau, Saint Mary's College",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Platanus x acerifolia",
    common_name: "London Plane Tree",
    family_name: "Platanaceae",
    plantType: "Tree (deciduous)",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    waterNeeds: "Low",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "1 (A)",
    additional_characteristices_notes:
      "Prefers wind protection, Susceptible to anthracnose and powdery mildew.",
    photocredit01:
      'SelecTree. "Platanus × hispanica Photo Record." 1995-2012. Jul 8, 2014.',
    photocredit02:
      'SelecTree. "Platanus × hispanica Photo Record." 1995-2012. Jul 8, 2014.',
    photocredit03:
      'SelecTree. "Platanus × hispanica Photo Record." 1995-2012. Jul 8, 2014.',
    photocredit04:
      'SelecTree. "Platanus × hispanica Photo Record." 1995-2012. Jul 8, 2014.',
    additional_species_cultivars_varieties:
      "'Bloodgood', 'Columbia', 'Yarwood', ‘Liberty’",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Pluchea odorata",
    common_name: "Salt marsh fleabane",
    family_name: "Asteraceae",
    plantType: "Perennial",
    bloomTime: "Summer;Fall",
    flower_color: "Purple",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Wetland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Moderate",
    habitat_value: "Buds/greens;Pollinator",
    associated_wildlife: "Bees;Butterflies;Insects",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2013 Keir Morse (CC BY-NC-SA 3.0) ",
    photocredit02: "©2013 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit03: "©2013 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit04: "©2013 Keir Morse (CC BY-NC-SA 3.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Podocarpus gracilior",
    common_name: "Fern Pine",
    family_name: "Podocarpaceae",
    plantType: "Tree (evergreen)",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    waterNeeds: "Low",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "2 (B)",
    additional_characteristices_notes: "Better as a shrub, gets pests.",
    photocredit01:
      'SelecTree. "Afrocarpus falcatus Photo Record." 1995-2012. Jul 8, 2014.',
    photocredit02:
      'SelecTree. "Afrocarpus falcatus Photo Record." 1995-2012. Jul 8, 2014.',
    photocredit03:
      'SelecTree. "Afrocarpus falcatus Photo Record." 1995-2012. Jul 8, 2014.',
    photocredit04:
      'SelecTree. "Afrocarpus falcatus Photo Record." 1995-2012. Jul 8, 2014.',
    sidewalk_landscaping_plants: "v",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Polygonum paronychia",
    common_name: "Beach/Dune Knotweed",
    family_name: "Polygonaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "White",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Dunes",
    suitableSiteConditions: "Sun",
    soil_type: "Sand",
    waterNeeds: "None",
    habitat_value: "Cover",
    associated_wildlife: "Butterflies",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit02: "Ken-ichi Ueda (CC BY-NC 2.0)",
    photocredit03: "Tom Hilton (CC BY 2.0)",
    photocredit04: "D In Orbit (CC BY-NC-ND 2.0)",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Polypodium californicum",
    common_name: "Polypody Fern",
    family_name: "Polypodiaceae",
    plantType: "Fern",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Coastal scrub;Woodland",
    suitableSiteConditions: "Part shade;Shade",
    soil_type: "Clay;Loam;Sand;Rock",
    waterNeeds: "Low",
    associated_wildlife: "No data",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "James Gaither (CC BY-NC-ND 2.0)",
    photocredit02: "M. Dolly (CC BY-SA 2.0)",
    photocredit03: "Ken-ichi Ueda (CC BY-NC 2.0)",
    photocredit04: "dogtooth77 (CC BY-NC-SA 2.0)",
    special_list: "Shady Clay",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1",
    shady_clay: "Shady Clay",
    shady_clay_int: "1"
  },
  {
    latin_name: "Clematis lasiantha",
    common_name: "Pipestems; Chaparral clematis",
    family_name: "Ranunculaceae",
    plantType: "Vine",
    bloomTime: "Spring",
    flower_color: "White",
    sizeAtMaturity: "13-24 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Riparian;Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Low",
    habitat_value: "Cover",
    associated_wildlife: "Birds;Butterflies",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: " ©1999 California Academy of Sciences",
    photocredit02: "©1999 California Academy",
    photocredit03: "©2003 Lynn Watson",
    photocredit04: "©1995 Saint Mary's College of California",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Polystichum munitum",
    common_name: "Western Sword Fern",
    family_name: "Dryopteridaceae",
    plantType: "Fern",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Woodland;Coastal scrub",
    suitableSiteConditions: "Part shade;Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Moderate",
    associated_wildlife: "No data",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    nurseries:
      "Oaktown;Mission Blue;Flora Grubb;Bay Natives;East Bay Wilds;Sutro Stewards;",
    photocredit01: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit02: "©2008 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit03: "©2008 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit04: "©2008 Keir Morse (CC BY-NC-SA 3.0)",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1",
    super60: "Y",
    super60_int: "1"
  },
  {
    latin_name: "Populus nigra 'Italica'",
    common_name: "Lombardy Poplar",
    family_name: "Salicaceae",
    plantType: "Tree (deciduous)",
    bloomTime: "Winter",
    flower_color: "White",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    plant_communities: "NA***",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Moderate",
    habitat_value: "Buds/greens",
    associated_wildlife: "Butterflies;Insects",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Attracts Lorquin's Admiral (Limenitis lorquini) butterfly.",
    photocredit01: "Romana Klee (CC BY-SA 2.0)",
    photocredit02: "Xevi V (CC BY-NC-SA 2.0)",
    photocredit03: "Matt Lavin (CC BY-SA 2.0)",
    photocredit04: "Leonora Enking (CC BY-SA 2.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Potentilla anserina ssp. pacifica",
    common_name: "Silverweed",
    family_name: "Rosaceae",
    plantType: "Perennial",
    bloomTime: "Spring",
    flower_color: "Yellow",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Riparian;Wetland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Moderate",
    habitat_value: "Cover;Pollinator",
    associated_wildlife: "Bees;Butterflies",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2002 Dean Wm. Taylor (CC BY-NC-SA 3.0)",
    photocredit02: "©2009 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit03: "©2009 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit04: "©2009 Keir Morse (CC BY-NC-SA 3.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Prunella vulgaris var. lanceolata",
    common_name: "Self Heal",
    family_name: "Lamiaceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "Purple",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Riparian;Wetland",
    suitableSiteConditions: "Part Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Moderate",
    habitat_value: "Cover;Pollinator",
    associated_wildlife: "Bees;Butterflies",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2009 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit02: "©2008 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit03: "©2009 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit04: "©2008 Keir Morse (CC BY-NC-SA 3.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Prunus cerasifera 'Krauter Vesuvius'",
    common_name: "Krauter Vesuvius Plum",
    family_name: "Rosaceae",
    plantType: "Tree (deciduous)",
    bloomTime: "Spring",
    flower_color: "Purple",
    sizeAtMaturity: "13-24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    associated_wildlife: "Birds;Butterflies",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "2 (B)",
    additional_characteristices_notes:
      "Wind protection, susceptible to shot hole fungus.",
    photocredit01:
      "SelecTree. \"Prunus cerasifera 'Krauter Vesuvius' Photo Record.\" 1995-2012. Jul 8, 2014.",
    photocredit02: "Mark Pritchard (CC BY-NC-SA 2.0)",
    photocredit03: "Mark Pritchard (CC BY-NC-SA 2.0)",
    photocredit04: "Mark Pritchard (CC BY-NC-SA 2.0)",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Zeltnera venusta",
    common_name: "Centaury",
    family_name: "Gentianaceae",
    former_latin_name: "Centaurium venustum",
    plantType: "Annual",
    bloomTime: "Spring;Summer;Fall",
    flower_color: "Purple",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Chaparral",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Loam;Clay",
    waterNeeds: "None",
    habitat_value: "Fruit",
    associated_wildlife: "Birds",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2001 George W. Hartwell",
    photocredit02: "©2003 Charles E. Jones",
    photocredit03: "©2005 Charles E. Jones",
    photocredit04: "©2010 Neal Kramer",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Prunus emarginata",
    common_name: "Bitter cherry",
    family_name: "Rosaceae",
    plantType: "Tree (deciduous)",
    bloomTime: "Spring",
    flower_color: "White",
    sizeAtMaturity: "13-24 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Woodland;Coastal scrub;Chaparral",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Loam;Clay",
    waterNeeds: "Low",
    habitat_value: "Fruit;Pollinator",
    associated_wildlife: "Bees;Birds;Butterflies;Insects",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Too cool of a climate to set fruit, but spreads vegetatively.  Get from SF sources. Attracts Lorquin's Admiral (Limenitis lorquini), Pale Swallowtail (Papilio eurymedon), Western Tiger Swallowtail (Papilio rutulus), Red Admiral (Vanessa atalanta).",
    photocredit01: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit02: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit03: "©2009 Barry Breckling (CC BY-NC-SA 3.0)",
    photocredit04: "©2013 Jean Pawek",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Prunus ilicifolia",
    common_name: "Holly Leaf Cherry/Islay Cherry",
    family_name: "Rosaceae",
    plantType: "Tree (evergreen)",
    bloomTime: "Spring;Winter",
    flower_color: "White",
    sizeAtMaturity: "13-24 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Chaparral;Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "clay;loam",
    pruning_needs: "Structural pruning",
    waterNeeds: "None",
    habitat_value: "Cover;Buds/greens;Fruit;Pollinator",
    associated_wildlife: "Bees;Birds;Butterflies;Insects",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "18;4;6",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Attracts Lorquin's Admiral (Limenitis lorquini), Pale Swallowtail (Papilio eurymedon), Western Tiger Swallowtail (Papilio rutulus), Cedar Waxwing, Western Tiger Swallowtail (Papilio rutulus), Anise Swallowtail (Papilio zelicaon).",
    nurseries:
      "Oaktown;Native Here;Mission Blue;Candlestick;Flora Grubb;Bay Natives;East Bay Wilds;Sutro Stewards;Watershed Nursery;",
    photocredit01: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit02: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit03: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit04: "© Br. Alfred Brousseau, Saint Mary's College",
    habitat: "Yes",
    habitat_int: "1",
    super60: "Y",
    super60_int: "1"
  },
  {
    latin_name: "Prunus serrulata 'Kwanzan'",
    common_name: "Kwansan Japanese Flowering Cherry",
    family_name: "Rosaceae",
    plantType: "Tree (deciduous)",
    bloomTime: "Winter",
    flower_color: "Pink;White",
    sizeAtMaturity: "13-24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "1 (A)",
    additional_characteristices_notes: "Prefers wind protection .",
    photocredit01:
      'SelecTree. "Prunus serrulata Photo Record." 1995-2012. Jul 8, 2014.',
    photocredit02:
      'SelecTree. "Prunus serrulata Photo Record." 1995-2012. Jul 8, 2014.',
    photocredit03:
      'SelecTree. "Prunus serrulata Photo Record." 1995-2012. Jul 8, 2014.',
    photocredit04:
      'SelecTree. "Prunus serrulata Photo Record." 1995-2012. Jul 8, 2014.',
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Prunus subcordata",
    common_name: "Sierra Plum",
    family_name: "Rosaceae",
    plantType: "Tree (deciduous)",
    bloomTime: "Winter",
    flower_color: "White",
    sizeAtMaturity: "13-24 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    habitat_value: "Fruit",
    associated_wildlife: "Bees;Birds;Butterflies",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Attracts Lorquin's Admiral (Limenitis lorquini), Pale Swallowtail (Papilio eurymedon), Western Tiger Swallowtail (Papilio rutulus), Red Admiral (Vanessa atalanta).",
    photocredit01: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit02: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit03: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit04: "©2009 Barry Breckling (CC BY-NC-SA 3.0) ",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Prunus virginiana var. demissa",
    common_name: "Western Chokecherry",
    family_name: "Rosaceae",
    former_latin_name: "Prunus demissa virginiana",
    plantType: "Tree (deciduous)",
    bloomTime: "Spring",
    flower_color: "White",
    sizeAtMaturity: "13-24 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Riparian;Wetland;Coastal scrub;Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Low",
    habitat_value: "Fruit",
    associated_wildlife: "Bees;Birds;Butterflies",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "8",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Attracts Lorquin's Admiral (Limenitis lorquini), Pale Swallowtail (Papilio eurymedon), Western Tiger Swallowtail (Papilio rutulus), Red Admiral (Vanessa atalanta).",
    photocredit01: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit02: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit03: "©2008 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit04: "©2008 Keir Morse (CC BY-NC-SA 3.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Pseudotsuga menziesii",
    common_name: "Douglas Fir",
    family_name: "Pinaceae",
    plantType: "Tree (evergreen)",
    bloomTime: "Spring",
    flower_color: "Red",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand;Rock",
    waterNeeds: "Low",
    habitat_value: "Nesting",
    associated_wildlife: "Birds;Butterflies",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "2",
    street_tree_list: "NA",
    additional_characteristices_notes: "Grow very large.",
    photocredit01: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit02: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit03: "©2008 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit04: "©2004 Robert Sivinski  (CC BY-NC 3.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Pteridium aquilinum var. pubescens",
    common_name: "Bracken Fern",
    family_name: "Dennstaedtiaceae",
    plantType: "Fern",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Woodland;Coastal scrub;Dunes",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    associated_wildlife: "No data",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes: "Grows everywhere – cosmopolitan fern.",
    photocredit01: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit02: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit03: "©2010 Keir Morse  (CC BY-NC-SA 3.0)",
    photocredit04: "©2011 Zoya Akulova (CC BY-NC 3.0)",
    special_list: "Sandy Soil",
    habitat: "Yes",
    habitat_int: "1",
    sandy_soil: "Sandy Soil",
    sandy_soil_int: "1"
  },
  {
    latin_name: "Pyrus calleryana 'New Bradford'",
    common_name: "New Bradford Pear",
    family_name: "Rosaceae",
    plantType: "Tree (deciduous)",
    bloomTime: "Spring",
    flower_color: "White",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Sand",
    waterNeeds: "Low",
    associated_wildlife: "Birds",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "2 (B)",
    additional_characteristices_notes:
      "Needs wind protection, susceptible to fireblight.",
    photocredit01:
      "SelecTree. \"Pyrus calleryana 'Bradford' Photo Record.\" 1995-2012. Jul 8, 2014.",
    photocredit02:
      "SelecTree. \"Pyrus calleryana 'Bradford' Photo Record.\" 1995-2012. Jul 8, 2014.",
    photocredit03:
      "SelecTree. \"Pyrus calleryana 'Bradford' Photo Record.\" 1995-2012. Jul 8, 2014.",
    photocredit04:
      "SelecTree. \"Pyrus calleryana 'Bradford' Photo Record.\" 1995-2012. Jul 8, 2014.",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Pyrus kawakamii",
    common_name: "Evergreen Pear",
    family_name: "Rosaceae",
    plantType: "Tree (evergreen)",
    bloomTime: "Spring",
    flower_color: "White",
    sizeAtMaturity: "13-24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "2 (B)",
    additional_characteristices_notes:
      "Plant in only warmest areas of city, semi-evergreen, leaf spot/fungus likely to occur and may cause premature leaf drop, does not flower well in our climate, susceptible to fireblight.",
    photocredit01:
      'SelecTree. "Pyrus kawakamii Photo Record." 1995-2012. Jul 8, 2014.',
    photocredit02:
      'SelecTree. "Pyrus kawakamii Photo Record." 1995-2012. Jul 8, 2014.',
    photocredit03:
      'SelecTree. "Pyrus kawakamii Photo Record." 1995-2012. Jul 8, 2014.',
    photocredit04:
      'SelecTree. "Pyrus kawakamii Photo Record." 1995-2012. Jul 8, 2014.',
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Quercus agrifolia",
    common_name: "Coast Live Oak",
    family_name: "Fagaceae",
    plantType: "Tree (evergreen)",
    bloomTime: "Spring;Winter",
    flower_color: "Yellow",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand;Rock",
    waterNeeds: "None",
    habitat_value: "Nesting;Cover",
    associated_wildlife: "Birds;Butterflies;Insects",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "11;8",
    street_tree_list: "2 (B)",
    additional_characteristices_notes:
      "Recommended especially for large spaces. Attracts California Sister (Adelpha bredowii californica), Propertius Dusky-Wing (Erynnis propertius), Mournful Dusky-Wing (Erynnis tristis), Chestnut-Backed Chickadee, Scrub Jays",
    nurseries:
      "Oaktown;Native Here;Mission Blue;Candlestick;Flora Grubb;Bay Natives;East Bay Wilds;Sutro Stewards;Watershed Nursery;",
    photocredit01:
      'SelecTree. "Quercus agrifolia Photo Record." 1995-2012. Jul 8, 2014.',
    photocredit02:
      'SelecTree. "Quercus agrifolia Photo Record." 1995-2012. Jul 8, 2014.',
    photocredit03:
      'SelecTree. "Quercus agrifolia Photo Record." 1995-2012. Jul 8, 2014.',
    photocredit04:
      'SelecTree. "Quercus agrifolia Photo Record." 1995-2012. Jul 8, 2014.',
    special_list: "Top 20",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1",
    super60: "Y",
    super60_int: "1",
    top20_int: "1"
  },
  {
    latin_name: "Quercus chrysolepis",
    common_name: "Canyon Live Oak",
    family_name: "Fagaceae",
    plantType: "Tree (evergreen)",
    bloomTime: "Spring",
    flower_color: "Yellow",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "None",
    habitat_value: "Nesting;Cover",
    associated_wildlife: "Birds;Butterflies;Insects",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Attracts California Sister (Adelpha bredowii californica), Propertius Dusky-Wing (Erynnis propertius), Mournful Dusky-Wing (Erynnis tristis).",
    photocredit01: "Miguel Vieira (CC BY 2.0)",
    photocredit02: "Joe Decruyenaere (CC BY-SA 2.0)",
    photocredit03: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit04: "©2008 Keir Morse (CC BY-NC-SA 3.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Quercus suber",
    common_name: "Cork Oak",
    family_name: "Fagaceae",
    plantType: "Tree (evergreen)",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "2 (B)",
    additional_characteristices_notes: "Needs a large basin, wide sidewalk.",
    photocredit01:
      'SelecTree. "Quercus suber Photo Record." 1995-2012. Jul 8, 2014.',
    photocredit02:
      'SelecTree. "Quercus suber Photo Record." 1995-2012. Jul 8, 2014.',
    photocredit03:
      'SelecTree. "Quercus suber Photo Record." 1995-2012. Jul 8, 2014.',
    photocredit04:
      'SelecTree. "Quercus suber Photo Record." 1995-2012. Jul 8, 2014.'
  },
  {
    latin_name: "Ranunculus californicus",
    common_name: "California Buttercup",
    family_name: "Ranunculaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Winter",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Coastal scrub;Riparian;Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees;Butterflies",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit02: "©2011 Vernon Smith (CC BY-NC-ND 3.0)",
    photocredit03: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit04: "©2009 Keir Morse  (CC BY-NC-SA 3.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Ribes sanguineum var. glutinosum",
    common_name: "Pink Flowering Currant",
    family_name: "Grossulariaceae",
    plantType: "Shrub (deciduous)",
    bloomTime: "Spring;Winter",
    flower_color: "Pink",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Woodland;Coastal scrub;Dunes",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand;Rock",
    waterNeeds: "None",
    habitat_value: "Fruit;Pollinator",
    associated_wildlife: "Bees;Birds;Hummingbirds",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "3",
    street_tree_list: "NA",
    nurseries:
      "Oaktown;Native Here;Flora Grubb;Bay Natives;East Bay Wilds;Sutro Stewards;Watershed Nursery;",
    photocredit01: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit02: "©2014 Vernon Smith (CC BY-NC-ND 3.0)",
    photocredit03: "©2014 Judith Elaine Bush (CC BY-NC-SA 3.0)",
    photocredit04: "©2014 Judith Elaine Bush (CC BY-NC-SA 3.0)",
    special_list: "Sandy Soil;Top 20",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1",
    super60: "Y",
    super60_int: "1",
    top20_int: "1",
    sandy_soil: "Sandy Soil",
    sandy_soil_int: "1"
  },
  {
    latin_name: "Rosa californica",
    common_name: "California Wild Rose",
    family_name: "Rosaceae",
    plantType: "Shrub (deciduous)",
    bloomTime: "Spring;Summer",
    flower_color: "Pink",
    sizeAtMaturity: "7-12 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities:
      "Coastal scrub;Chaparral;Grassland/prairie;Riparian;Wetland;Woodland",
    suitableSiteConditions: "Sun;Part shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    habitat_value: "Fruit;Pollinator",
    associated_wildlife: "Bees;Birds;Butterflies;Insects",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    nurseries:
      "Oaktown;Native Here;Mission Blue;Flora Grubb;Bay Natives;East Bay Wilds;Sutro Stewards;Watershed Nursery;",
    photocredit01: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit02: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit03: "©2009 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit04: "©2011 Robert A. Hamilton (CC BY-NC-SA 3.0)",
    habitat: "Yes",
    habitat_int: "1",
    super60: "Y",
    super60_int: "1"
  },
  {
    latin_name: "Rosa gymnocarpa",
    common_name: "Wood Rose/Dwarf Rose",
    family_name: "Rosaceae",
    plantType: "Shrub (deciduous)",
    bloomTime: "Spring",
    flower_color: "Pink",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities:
      "Coastal scrub;Chaparral;Grassland/prairie;Woodland;Riparian",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Low",
    habitat_value: "Fruit;Pollinator",
    associated_wildlife: "Bees;Birds;Butterflies;Insects",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2008 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit02: "©2008 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit03: "©2010 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit04: "©2010 Jean Pawek",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Rubus ursinus",
    common_name: "California Blackberry",
    family_name: "Rosaceae",
    plantType: "Shrub (deciduous)",
    bloomTime: "Spring;Winter",
    flower_color: "White",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities:
      "Chaparral;Grassland/prairie;Riparian;Wetland;Woodland;Coastal scrub;Dunes",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "None",
    habitat_value: "Fruit;Pollinator;Cover",
    associated_wildlife: "Bees;Birds;Butterflies;Hummingbirds;Insects",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden;Roof",
    suggested_green_connection_routes: "22;23",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "With espalier can make attractive feature and good habitat. Attracts Nuttall's White-crowned Sparrow and Red Admiral (Vanessa atalanta).",
    photocredit01: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit02: "©2008 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit03: "©2008 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit04: "©2008 Keir Morse (CC BY-NC-SA 3.0)",
    special_list: "Top 20",
    habitat: "Yes",
    habitat_int: "1",
    top20_int: "1"
  },
  {
    latin_name: "Rumex fueginus",
    common_name: "Golden Dock",
    family_name: "Polygonaceae",
    former_latin_name: "Rumex maritimus",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Wetland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Moderate",
    habitat_value: "Fruit;Pollinator",
    associated_wildlife: "Butterflies",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit02: "©2008 Jorg Fleige (CC BY-NC 3.0)",
    photocredit03: "Joe F. Duft @ USDA-NRCS PLANTS Database / USDA NRCS. 1992.",
    photocredit04: "Hella Delicious (CC BY-NC-SA 2.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Rumex occidentalis",
    common_name: "Western Dock",
    family_name: "Polygonaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "Burgundy",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Wetland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Moderate",
    associated_wildlife: "No data",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2012 Vernon Smith (CC BY-NC-ND 3.0)",
    photocredit02: "©2012 Vernon Smith (CC BY-NC-ND 3.0)",
    photocredit03: "Luke McGuff  (CC BY-ND 2.0)",
    photocredit04: "stonebird (CC BY-NC-SA 2.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Salix lasiandra",
    common_name: "Yellow Willow",
    family_name: "Salicaceae",
    former_latin_name: "Salix lucida",
    plantType: "Tree (deciduous)",
    bloomTime: "Spring",
    flower_color: "Yellow",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Riparian;Wetland;Woodland",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Moderate",
    habitat_value: "Cover;Buds/greens;Cover;Pollinator",
    associated_wildlife: "Bees;Insects",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "18",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Atrracts Mourning Cloak (Nymphalis antiopa) and Western Tiger Swallowtail (Papilio rutulus).",
    photocredit01: "Matt Lavin (CC BY-SA 2.0)",
    photocredit02:
      "J. E.(Jed) and Bonnie McClellan © California Academy of Sciences",
    photocredit03:
      "J. E.(Jed) and Bonnie McClellan © California Academy of Sciences",
    photocredit04: "Matt Lavin (CC BY-SA 2.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Salix lasiolepis",
    common_name: "Arroyo Willow",
    family_name: "Salicaceae",
    plantType: "Tree (deciduous)",
    bloomTime: "Spring;Winter",
    flower_color: "Yellow",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Coastal scrub;Riparian;Wetland;Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    habitat_value: "Buds/greens;Pollinator",
    associated_wildlife: "Bees;Birds;Butterflies;Insects",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "10",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Atrracts Mourning Cloak (Nymphalis antiopa) and Western Tiger Swallowtail (Papilio rutulus).",
    photocredit01: "©2008 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit02: "©2008 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit03: "©2008 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit04: "©2008 Keir Morse (CC BY-NC-SA 3.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Salix lutea",
    common_name: "Yellow Willow",
    family_name: "Salicaceae",
    plantType: "Shrub (deciduous)",
    bloomTime: "Spring",
    flower_color: "Yellow",
    sizeAtMaturity: "13-24 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Wetland;Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Moderate",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees;Butterflies",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Attracts Dreamy Duskywing (Erynnis icelus), Viceroy (Limenitis archippus), Lorquin's Admiral (Limenitis lorquini), Weidemeyer's Admiral (Limenitis weidemeyerii), Mourning Cloak (Nymphalis antiopa), Western Tiger Swallowtail (Papilio rutulus).",
    photocredit01: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit02: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit03: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit04: "© Br. Alfred Brousseau, Saint Mary's College",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Salvia apiana",
    common_name: "California White Sage",
    family_name: "Lamiaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Summer",
    flower_color: "White",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Chaparral;Coastal scrub",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees;Birds;Butterflies",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden;Sidewalk;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    nurseries:
      "Oaktown;Flora Grubb;Bay Natives;East Bay Wilds;Sutro Stewards;Watershed Nursery;",
    photocredit01: " ©2013 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit02: "©2011 Wynn Anderson (CC BY-NC-SA 3.0)",
    photocredit03: "©2011 Wynn Anderson (CC BY-NC-SA 3.0)",
    photocredit04: "©2011 Wynn Anderson (CC BY-NC-SA 3.0)",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1",
    super60: "Y",
    super60_int: "1"
  },
  {
    latin_name: "Salvia clevelandii",
    common_name: "Cleveland Sage",
    family_name: "Lamiaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Summer",
    flower_color: "Blue;Purple",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Chaparral",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Sand",
    waterNeeds: "None",
    habitat_value: "Pollinator;Cover",
    associated_wildlife: "Bees;Butterflies;Hummingbirds",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden;Sidewalk;Roof",
    suggested_green_connection_routes: "3",
    street_tree_list: "NA",
    additional_characteristices_notes: "Very fragrant.",
    nurseries:
      "Oaktown;Mission Blue;Flora Grubb;Bay Natives;East Bay Wilds;Watershed Nursery;",
    photocredit01: "©2013 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit02: "©2013 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit03: "©2013 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit04: "©2013 Keir Morse (CC BY-NC-SA 3.0)",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1",
    super60: "Y",
    super60_int: "1"
  },
  {
    latin_name: "Salvia leucophylla",
    common_name: "Coast Purple Sage",
    family_name: "Lamiaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring",
    flower_color: "Purple",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Coastal scrub;Chaparral",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand;Rock",
    waterNeeds: "None",
    habitat_value: "Pollinator;Cover",
    associated_wildlife: "Bees;Butterflies;Hummingbirds",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden;Sidewalk;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    nurseries:
      "Candlestick;Flora Grubb;Bay Natives;East Bay Wilds;Watershed Nursery;",
    photocredit01: "©2003 Brent Miller (CC BY-NC-SA 3.0)",
    photocredit02: "©2011 Jean Pawek ",
    photocredit03: "©2005 Noah Elhardt (CC BY-NC-SA 3.0)",
    photocredit04: "©2003 Barry Breckling (CC BY-NC-SA 3.0)",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1",
    super60: "Y",
    super60_int: "1"
  },
  {
    latin_name: "Salvia sonomensis",
    common_name: "Creeping Sage",
    family_name: "Lamiaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "White;Purple",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Chaparral;Woodland",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Sand",
    waterNeeds: "None",
    habitat_value: "Cover;Pollinator",
    associated_wildlife: "Bees;Butterflies;Hummingbirds",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden;Sidewalk;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes: "Attracts Anna's Hummingbird.",
    photocredit01: "©2009 Gary A. Monroe  (CC BY-NC 3.0)",
    photocredit02: "©2009 Gary A. Monroe  (CC BY-NC 3.0)",
    photocredit03: "©2009 Gary A. Monroe  (CC BY-NC 3.0)",
    photocredit04: "©2009 Gary A. Monroe  (CC BY-NC 3.0)",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Salvia spathacea",
    common_name: "Hummingbird Sage",
    family_name: "Lamiaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer;Winter",
    flower_color: "Pink",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Chaparral;Coastal scrub;Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand;Rock",
    waterNeeds: "None",
    habitat_value: "Buds/greens;Cover;Pollinator",
    associated_wildlife: "Bees;Birds;Butterflies;Hummingbirds;Insects",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden;Sidewalk;Roof",
    suggested_green_connection_routes: "3",
    street_tree_list: "NA",
    additional_characteristices_notes: "Attracts Anna's Hummingbird.",
    nurseries:
      "Oaktown;Mission Blue;Candlestick;Flora Grubb;Bay Natives;East Bay Wilds;Sutro Stewards;Watershed Nursery;",
    photocredit01: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit02: "©2009 Gary A. Monroe (CC BY-NC 3.0)",
    photocredit03: "©2009 Gary A. Monroe (CC BY-NC 3.0)",
    photocredit04: "©2009 Gary A. Monroe (CC BY-NC 3.0)",
    special_list: "Sandy Soil;Top 20",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1",
    super60: "Y",
    super60_int: "1",
    top20_int: "1",
    sandy_soil: "Sandy Soil",
    sandy_soil_int: "1"
  },
  {
    latin_name: "Sambucus nigra ssp. cerulea",
    common_name: "Blue Elderberry",
    family_name: "Adoxaceae",
    former_latin_name: "Sambucus mexicana",
    plantType: "Shrub (deciduous)",
    bloomTime: "Spring;Summer",
    flower_color: "White",
    sizeAtMaturity: "13-24 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Coastal scrub;Riparian;Wetland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    habitat_value: "Cover;Fruit;Pollinator;Nesting",
    associated_wildlife: "Bees;Birds;Butterflies;Hummingbirds;Insects",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Plant on the east side. Off-white flowers in spring, blue fruits in early summer. Attracts quail, thrashers, grosbeaks, woodpeckers, orioles, house finshes, and orange-crowned warblers.",
    nurseries:
      "Oaktown;Native Here;Mission Blue;Candlestick;Flora Grubb;Bay Natives;East Bay Wilds;Watershed Nursery;",
    photocredit01: "Thayne Tuason (CC BY-NC 2.0)",
    photocredit02: "Phuong Tran (CC BY-NC-ND 2.0)",
    photocredit03: "Phuong Tran (CC BY-NC-ND 2.0)",
    photocredit04: "Phuong Tran (CC BY-NC-ND 2.0)",
    habitat: "Yes",
    habitat_int: "1",
    super60: "Y",
    super60_int: "1"
  },
  {
    latin_name: "Sambucus racemosa var. racemosa",
    common_name: "Red Elderberry",
    family_name: "Adoxaceae",
    plantType: "Shrub (deciduous)",
    bloomTime: "Summer",
    flower_color: "White",
    sizeAtMaturity: "13-24 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Coastal scrub;Riparian;Woodland",
    suitableSiteConditions: "Part shade;Shade",
    soil_type: "Loam;Clay",
    waterNeeds: "Low",
    habitat_value: "Fruit;Pollinator;Cover",
    associated_wildlife: "Bees;Birds;Hummingbirds;Insects",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "4;8",
    street_tree_list: "NA",
    additional_characteristices_notes: "Plant on the west side.",
    photocredit01: "©2011 Jean Pawek",
    photocredit02: "©2010 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit03: "©2010 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit04: "©2010 Keir Morse (CC BY-NC-SA 3.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Schoenoplectus acutus var. occidentalis",
    common_name: "Tule",
    family_name: "Cyperaceae",
    former_latin_name: "Scirpus acutus",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "Brown",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Wetland",
    suitableSiteConditions: "Sun",
    soil_type: "Loam;Sand",
    waterNeeds: "Moderate",
    associated_wildlife: "No data",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit02: "©2003 Keir Morse  (CC BY-NC-SA 3.0)",
    photocredit03: "©2013 Jean Pawek",
    photocredit04: "©2013 Jean Pawek",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Schoenoplectus californicus",
    common_name: "California Bulrush",
    family_name: "Cyperaceae",
    former_latin_name: "Scirpus californicus",
    plantType: "Perennial",
    bloomTime: "Spring",
    flower_color: "Brown",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Wetland",
    suitableSiteConditions: "Sun",
    soil_type: "Loam;Sand",
    waterNeeds: "Moderate",
    associated_wildlife: "No data",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2013 Zoya Akulova (CC BY-NC 3.0)",
    photocredit02: "©2009 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit03: "©2013 Steven Thorsted (CC BY-NC 3.0)",
    photocredit04: "©2010 Steven Thorsted (CC BY-NC 3.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Schoenoplectus pungens var. badius",
    common_name: "Common Threesquare",
    family_name: "Cyperaceae",
    former_latin_name: "Scirpus americanus",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "Burgundy",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Wetland",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Moderate",
    associated_wildlife: "No data",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "Max Licher (CC BY-SA)",
    photocredit02: "Max Licher (CC BY-SA)",
    photocredit03: "Max Licher (CC BY-SA)",
    photocredit04: "Jon Sullivan (CC BY-NC 2.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Scrophularia californica",
    common_name: "California Bee Plant",
    family_name: "Scrophulariaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Winter",
    flower_color: "Burgundy",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Coastal scrub;Riparian;Woodland;Dunes",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees;Birds;Butterflies;Hummingbirds",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Attracts Variable Checkerspot (Euphydryas chalcedona) and Common Buckeye (Junonia coenia).",
    nurseries:
      "Oaktown;Native Here;Mission Blue;Candlestick;Bay Natives;East Bay Wilds;Sutro Stewards;Watershed Nursery;",
    photocredit01: "©2013 Vernon Smith (CC BY-NC-ND 3.0)",
    photocredit02: "©2009 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit03: "©2009 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit04: "©2009 Keir Morse (CC BY-NC-SA 3.0)",
    special_list: "Sandy Soil;Shady Clay;Top 20",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1",
    super60: "Y",
    super60_int: "1",
    top20_int: "1",
    shady_clay: "Shady Clay",
    shady_clay_int: "1",
    sandy_soil: "Sandy Soil",
    sandy_soil_int: "1"
  },
  {
    latin_name: "Sedum spathulifolium",
    common_name: "Broadleaf Stonecrop",
    family_name: "Crassulaceae",
    plantType: "Succulent",
    bloomTime: "Spring;Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Coastal scrub;Woodland;Chaparral",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Low",
    habitat_value: "Buds/greens;Cover",
    associated_wildlife: "Butterflies;Insects",
    stormwater_benefit: "yes",
    stormwater_int: "1",
    appropriateLocation: "Garden;Roof",
    suggested_green_connection_routes: "14",
    street_tree_list: "NA",
    additional_characteristices_notes: "Needs water in winter / spring.",
    photocredit01: "©2009 Barry Breckling (CC BY-NC-SA 3.0)",
    photocredit02: "©2009 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit03: "©2009 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit04: "©2009 Barry Rice (CC BY-NC-SA 3.0)",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Senecio hydrophilus",
    common_name: "Marsh Ragwort",
    family_name: "Asteraceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Wetland;Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Moderate",
    associated_wildlife: "No data",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit02: "©1971 Dean Wm. Taylor (CC BY-NC-SA 3.0) ",
    photocredit03: "Anthony Baniaga (public domain)",
    photocredit04: "©2010 Zoya Akulova (CC BY-NC 3.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Sequoia sempervirens",
    common_name: "Redwood",
    family_name: "Cupressaceae",
    plantType: "Tree (evergreen)",
    bloomTime: "Summer;Fall",
    flower_color: "Yellow",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Woodland",
    suitableSiteConditions: "Sun;Part Shade;Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Moderate",
    habitat_value: "Nesting",
    associated_wildlife: "Birds;Butterflies",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "2",
    street_tree_list: "NA",
    photocredit01: "©2012 Daniel Passarini (CC BY-NC-SA 3.0)",
    photocredit02: "©2013 Zoya Akulova (CC BY-NC 3.0)",
    photocredit03: "©2013 Zoya Akulova (CC BY-NC 3.0)",
    photocredit04: "©1975 Gary A. Monroe (CC BY-NC 3.0)",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Sisyrinchium bellum",
    common_name: "Blue-eyed Grass",
    family_name: "Iridaceae",
    plantType: "Perennial",
    bloomTime: "Spring",
    flower_color: "Indigo",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Coastal scrub;Woodland;Grassland/prairie",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "None",
    associated_wildlife: "No data",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    nurseries:
      "Oaktown;Native Here;Mission Blue;Candlestick;Flora Grubb;Bay Natives;East Bay Wilds;Sutro Stewards;Watershed Nursery;",
    photocredit01: "©2010 Barry Breckling (CC BY-NC-SA 3.0)",
    photocredit02: "©2010 Steven Thorsted (CC BY-NC 3.0)",
    photocredit03: "©2010 Barry Breckling (CC BY-NC-SA 3.0)",
    photocredit04: "©2009 Keir Morse (CC BY-NC-SA 3.0)",
    habitat: "Yes",
    habitat_int: "1",
    super60: "Y",
    super60_int: "1"
  },
  {
    latin_name: "Solidago californica",
    common_name: "Goldenrod",
    family_name: "Asteraceae",
    plantType: "Perennial",
    bloomTime: "Summer;Fall",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Coastal scrub;Grassland/prairie;Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "None",
    habitat_value: "Buds/greens;Pollinator",
    associated_wildlife: "Bees;Butterflies;Insects",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    nurseries: "Oaktown;Mission Blue;Bay Natives;East Bay Wilds;",
    photocredit01: "©2008 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit02: "©2008 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit03: "©2008 Keir Morse (CC BY-NC-SA 3.0)",
    photocredit04: "©2008 Keir Morse (CC BY-NC-SA 3.0)",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1",
    super60: "Y",
    super60_int: "1"
  },
  {
    latin_name: "Solidago spathulata",
    common_name: "Dune Goldenrod",
    family_name: "Asteraceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer;Fall",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Coastal scrub;Dunes",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "None",
    habitat_value: "Buds/greens;Pollinator",
    associated_wildlife: "Bees;Butterflies;Insects",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "1",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Attracts Monarch Butterfly (Danaus plexippus).",
    nurseries: "Oaktown;Mission Blue;Candlestick;Bay Natives;East Bay Wilds;",
    photocredit01: "©2010 Zoya Akulova (CC BY-NC 3.0)",
    photocredit02: "©2012 Vernon Smith (CC BY-NC-ND 3.0)",
    photocredit03: "©2008 Jorg Fleige (CC BY-NC 3.0)",
    photocredit04: "©2008 Jorg Fleige (CC BY-NC 3.0)",
    special_list: "Sandy Soil",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1",
    super60: "Y",
    super60_int: "1",
    sandy_soil: "Sandy Soil",
    sandy_soil_int: "1"
  },
  {
    latin_name: "Stipa pulchra",
    common_name: "Purple Needlegrass",
    family_name: "Poaceae",
    former_latin_name: "Nassella pulchra",
    plantType: "Grass",
    bloomTime: "Spring",
    flower_color: "Purple;Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Chaparral;Grassland/prairie;Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "None",
    habitat_value: "Nesting",
    associated_wildlife: "Bees;Birds",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden;Roof",
    suggested_green_connection_routes: "7",
    street_tree_list: "NA",
    additional_characteristices_notes: "Indispensable.",
    nurseries:
      "Oaktown;Native Here;Candlestick;Flora Grubb;Bay Natives;East Bay Wilds;Sutro Stewards;Watershed Nursery;",
    photocredit01: "©2013 Clint Scheuerman",
    photocredit02: "©2012 Aaron Arthur",
    photocredit03: "©2012 Aaron Arthur",
    photocredit04: "©2013 Lynn Watson",
    special_list: "Top 20",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1",
    super60: "Y",
    super60_int: "1",
    top20_int: "1"
  },
  {
    latin_name: "Wyethia angustifolia",
    common_name: "Narrowleaf Mule Ears",
    family_name: "Asteraceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Chaparral;Grassland/prairie;Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees;Butterflies",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Attracts endangered Fender’s Blue Butterfly.",
    nurseries:
      "Oaktown;Native Here;Mission Blue;Candlestick;Bay Natives;East Bay Wilds;Watershed Nursery;",
    photocredit01: "©2004 Carol W. Witham",
    photocredit02: "©2011 Hattie Brown",
    photocredit03: "© Br. Alfred Brousseau, Saint Mary's College",
    photocredit04: "©2009 Zoya Akulova (CC BY-NC 3.0)",
    habitat: "Yes",
    habitat_int: "1",
    super60: "Y",
    super60_int: "1"
  },
  {
    latin_name: "Thymus spp.",
    common_name: "Thyme",
    family_name: "Lamiaceae",
    plantType: "Perennial",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Birds",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2007 Luigi Rignanese",
    photocredit02: "©2007 Luigi Rignanese",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Agrostis pallens",
    common_name: "Bentgrass",
    family_name: "Poaceae",
    plantType: "Grass",
    bloomTime: "Spring",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Chaparral;Grassland/prairie;Woodland;Coastal scrub",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Low",
    associated_wildlife: "Birds;Butterflies",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2012 Vernon Smith",
    photocredit02: "©2012 Vernon Smith",
    photocredit03: "©2008 Keir Morse",
    photocredit04: "©2008 Keir Morse",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Artemisia douglasiana",
    common_name: "Mugwort",
    family_name: "Asteraceae",
    plantType: "Perennial",
    bloomTime: "Summer;Fall",
    flower_color: "White",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Chaparral;Woodland;Riparian;Coastal scrub;Wetland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Low",
    associated_wildlife: "Birds;Butterflies",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2008 Keir Morse",
    photocredit02: "©2008 Keir Morse",
    photocredit03: "©2008 Keir Morse",
    photocredit04: "©2014 Richard Spellenberg",
    habitat: "Yes",
    habitat_int: "1",
    super60: "Y",
    super60_int: "1"
  },
  {
    latin_name: "Carex barbarae",
    common_name: "Santa Barbara sedge",
    family_name: "Cyperaceae",
    plantType: "Grass",
    bloomTime: "Summer",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Grassland/prairie;Woodland;Coastal scrub;Wetland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Moderate",
    associated_wildlife: "Birds;Butterflies",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes: "Good ground cover",
    photocredit01: "©2012 Jake Ruygt",
    photocredit02: "©2013 Robert Steers/NPS",
    photocredit03: "©2012 Jake Ruygt",
    photocredit04: "©2012 Jake Ruygt",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Carpenteria californica",
    common_name: "Bush Anemone",
    family_name: "Hydrangeaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Summer",
    flower_color: "White",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Loam;Sand",
    waterNeeds: "Moderate",
    habitat_value: "Pollinator;Cover",
    associated_wildlife: "Bees:Birds;Butterflies;insects",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "CNPS Rare Plant; State listed as Threatened",
    photocredit01: "©2007 Julie Kierstead Nelson",
    photocredit02: "©2007 Julie Kierstead Nelson",
    photocredit03: "©1999 California Academy of Sciences",
    photocredit04: "©1998 Christopher L. Christie",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Cercocarpus betuloides",
    common_name: "Mountain mahogany",
    family_name: "Rosaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring",
    flower_color: "Yellow",
    sizeAtMaturity: "7-12 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Chaparral;Woodland",
    suitableSiteConditions: "Sun",
    soil_type: "Loam;Sand;Clay",
    waterNeeds: "None",
    habitat_value: "Cover",
    associated_wildlife: "Bees,Butterflies;Hummingbirds",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©1999 California Academy of Sciences",
    photocredit02: "©1999 California Academy of Sciences",
    photocredit03: "©2001 Carol Mattsson",
    photocredit04: "©2002 Lynn Watson",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Narcissus hybrids",
    common_name: "Daffodil or Narcissus",
    family_name: "Amaryllidaceae",
    plantType: "Perennial",
    flower_color: "White",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2010 Ian Alexander Martin",
    photocredit02: "©2012 Ian Alexander Martin",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Dicentra formosa",
    common_name: "Pacific bleeding heart",
    family_name: "Papaveraceae",
    plantType: "Perennial",
    bloomTime: "Spring",
    flower_color: "Pink",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Riparian;Woodland",
    suitableSiteConditions: "Part shade;Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Moderate",
    habitat_value: "Pollinator",
    associated_wildlife: "Birds;Butterflies",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes: "Good ground cover",
    photocredit01: "©2005 Steve Matson",
    photocredit02: "©2003 Christopher L. Christie",
    photocredit03: "©2003 Christopher L. Christie",
    photocredit04: "©1995 Saint Mary's College of California",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Equisetum hyemale",
    common_name: "Scouring Rush Horsetail",
    family_name: "Equisetaceae",
    plantType: "Perennial",
    bloomTime: "Spring",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Wetland; Riparian; Woodland",
    suitableSiteConditions: "Part shade;Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Moderate",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes: "Prefers moist soils",
    photocredit01: "©2010 Hans J. Koch",
    photocredit02: "©2006 Louis-M. Landry",
    photocredit03: "©2008 Dr. Amadej Trnkoczy",
    photocredit04: "©2007 Louis-M. Landry",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Gaultheria shallon",
    common_name: "Salal",
    family_name: "Ericaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring",
    flower_color: "White;Pink",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Wetland;Coastal scrub;Woodland",
    suitableSiteConditions: "Part shade;Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Low",
    habitat_value: "Fruit",
    associated_wildlife: "Birds;Butterflies",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2001 California Academy of Sciences",
    photocredit02: "©1999 California Academy of Sciences",
    photocredit03: "©1995 Saint Mary's College of California",
    photocredit04: "©1995 Saint Mary's College of California",
    special_list: "Shady Clay",
    habitat: "Yes",
    habitat_int: "1",
    shady_clay: "Shady Clay",
    shady_clay_int: "1"
  },
  {
    latin_name: "Juglans hindsii",
    common_name: "California black walnut",
    family_name: "Juglandaceae",
    plantType: "Tree (deciduous)",
    bloomTime: "Spring",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Riparian;Woodland",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam",
    waterNeeds: "Low",
    habitat_value: "Cover;Nesting",
    associated_wildlife: "Birds",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2013 Steve Matson",
    photocredit02: "©2011 Vernon Smith",
    photocredit03: "©2015 Susan McDougall",
    photocredit04: "©2015 Susan McDougall",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Juncus patens",
    common_name: "Spreading rush",
    family_name: "Juncaceae",
    plantType: "Grass",
    bloomTime: "Spring",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Riparian;Coastal scrub;Wetland",
    suitableSiteConditions: "Sun;Part shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    associated_wildlife: "Birds",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden;Sidewalk;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2008 Neal Kramer",
    photocredit02: "©2013 Robert Steers/NPS",
    photocredit03: "©2007 Neal Kramer",
    photocredit04: "©2014 Mike Wood",
    special_list: "Shady Clay",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1",
    shady_clay: "Shady Clay",
    shady_clay_int: "1"
  },
  {
    latin_name: "Melica imperfecta",
    common_name: "Coast range melic",
    family_name: "Poaceae",
    plantType: "Grass",
    bloomTime: "Spring;Winter",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Chaparral;Coastal scrub;Woodland",
    suitableSiteConditions: "Sun;Part shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "None",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2009 Keir Morse",
    photocredit02: "©2009 Keir Morse",
    photocredit03: "©2009 Keir Morse",
    photocredit04: "©2009 Keir Morse",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Mimulus cardinalis",
    common_name: "Scarlet monkeyflower",
    family_name: "Phrymaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "Red",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Wetland;Riparian",
    suitableSiteConditions: "Sun;Part shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Moderate",
    habitat_value: "Pollinator",
    associated_wildlife: "Hummingbirds;Butterflies",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "3",
    street_tree_list: "NA",
    photocredit01: "©2002 Lynn Watson",
    photocredit02: "©2001 Steve Schoenig",
    photocredit03: "©2001 Steve Schoenig",
    photocredit04: "©2003 George W. Hartwell",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Mimulus guttatus",
    common_name: "Creek monkeyflower",
    family_name: "Phrymaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Riparian;Wetland",
    suitableSiteConditions: "Sun;Part Shade;Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Moderate",
    habitat_value: "Pollinator",
    associated_wildlife: "Hummingbirds;Butterflies",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "3",
    street_tree_list: "NA",
    additional_characteristices_notes: "Long blooming period",
    photocredit01: "©1995 Saint Mary's College of California",
    photocredit02: "©2001 George W. Hartwell",
    photocredit03: "©1995 Saint Mary's College of California",
    photocredit04: "©2005 Jim Dunn",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Oxalis oregana",
    common_name: "Redwood sorrel",
    family_name: "Oxalidaceae",
    plantType: "Perennial",
    bloomTime: "Spring",
    flower_color: "Purple",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Woodland",
    suitableSiteConditions: "Shade",
    soil_type: "Loam;Clay",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees;Butterflies",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes: "Great understory ground cover",
    photocredit01: "©1995 Saint Mary's College of California",
    photocredit02: "©2001 California Academy of Sciences",
    photocredit03: "©1999 California Academy of Sciences",
    photocredit04: "©2003 James B. Gratiot",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Panicum virgatum",
    common_name: "Switchgrass",
    family_name: "Poaceae",
    plantType: "Grass",
    bloomTime: "Summer;Fall;Winter",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Moderate",
    associated_wildlife: "Birds",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes: "Seeds are good food source in winter",
    photocredit01: "©2010 Louis-M. Landry",
    photocredit02: "©2010 Louis-M. Landry",
    photocredit03: "© 2005 Brad Wold",
    photocredit04: "© 2005 Brad Wold",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Philadelphus lewisii",
    common_name: "Native mock orange",
    family_name: "Hydrangeaceae",
    plantType: "Shrub (deciduous)",
    bloomTime: "Spring;Summer",
    flower_color: "White",
    sizeAtMaturity: "7-12 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Woodland",
    suitableSiteConditions: "Sun;Part shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Low",
    habitat_value: "Cover;Pollinator",
    associated_wildlife: "Bees;Birds;Butterflies",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©1999 California Academy of Sciences",
    photocredit02: "©2003 Christopher L. Christie",
    photocredit03: "©1995 Saint Mary's College of California",
    photocredit04: "©1995 Saint Mary's College of California",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Physocarpus capitatus",
    common_name: "Pacific ninebark",
    family_name: "Rosaceae",
    plantType: "Shrub (deciduous)",
    bloomTime: "Spring;Summer",
    flower_color: "White",
    sizeAtMaturity: "7-12 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Riparian;Woodland",
    suitableSiteConditions: "Sun;Part shade;Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Low",
    habitat_value: "Cover",
    associated_wildlife: "Birds",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2006 Matt Below",
    photocredit02: "©1998 California Academy of Sciences",
    photocredit03: "©1999 California Academy of Sciences",
    photocredit04: "©2005 John Pelafigue",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Prosartes hookeri",
    common_name: "Hooker's fairy bells",
    family_name: "Liliaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "White",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Woodland",
    suitableSiteConditions: "Part shade;Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Low",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©Margo Bors",
    photocredit02: "©2008 Steve Matson",
    photocredit03: "©2008 Jorg Fleige",
    photocredit04: "©Margo Bors ",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Quercus lobata",
    common_name: "Valley oak",
    family_name: "Fagaceae",
    plantType: "Tree (deciduous)",
    bloomTime: "Spring;Winter",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Riparian;Woodland",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam",
    waterNeeds: "Low",
    habitat_value: "Cover",
    associated_wildlife: "Hummingbirds;Butterflies",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©1995 Saint Mary's College of California",
    photocredit02: "©1995 Saint Mary's College of California",
    photocredit03: "©2002 Timothy D. Ives",
    photocredit04: " ©1999 California Academy of Sciences",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Ribes menziesii",
    common_name: "Canyon gooseberry",
    family_name: "Grossulariaceae",
    plantType: "Shrub (deciduous)",
    bloomTime: "Spring;Winter",
    flower_color: "White",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Chaparral;Woodland",
    suitableSiteConditions: "Sun;Part shade",
    soil_type: "Clay;Loam",
    waterNeeds: "None",
    habitat_value: "Cover;Pollinator",
    associated_wildlife: "Hummingbirds;Bees;Butterflies",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "3;20",
    street_tree_list: "NA",
    photocredit01: "©2006 Steve Matson",
    photocredit02: "©2012 Jean Pawek",
    photocredit03: "©2012 Jean Pawek",
    photocredit04: "©2013 Jean Pawek",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Ribes speciosum",
    common_name: "Fuchsia-flowered gooseberry",
    family_name: "Grossulariaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Winter",
    flower_color: "Red",
    sizeAtMaturity: "7-12 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Chaparral",
    suitableSiteConditions: "Sun;Part shade",
    soil_type: "Clay;Loam",
    waterNeeds: "None",
    habitat_value: "Fruit;Cover;Pollinator",
    associated_wildlife: "Bees;Birds;Small mammals",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "3;20",
    street_tree_list: "NA",
    photocredit01: "©2004 Christopher L. Christie",
    photocredit02: "©2004 Christopher L. Christie",
    photocredit03: "©2005 Michael W. Tuma",
    photocredit04: "©2004 Christopher L. Christie",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Salix laevigata",
    common_name: "Red willow",
    family_name: "Salicaceae",
    plantType: "Tree (deciduous)",
    bloomTime: "Spring",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Riparian;Wetland",
    suitableSiteConditions: "Sun;Part shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Moderate",
    habitat_value: "Cover",
    associated_wildlife: "Bees;Birds;Butterflies",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©1995 Saint Mary's College of California",
    photocredit02: "©1995 Saint Mary's College of California",
    photocredit03: "©1995 Saint Mary's College of California",
    photocredit04: "©1995 Saint Mary's College of California",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Sisyrinchium californicum",
    common_name: "Yellow-eyed grass",
    family_name: "Iridaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "> 1 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Riparian;Wetland",
    suitableSiteConditions: "Sun;Part shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Moderate",
    habitat_value: "Pollinator",
    associated_wildlife: "Hummingbirds,Butterflies",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes: "prefers moist conditions",
    photocredit01: "©2001 California Academy of Sciences",
    photocredit02: "©1999 California Academy of Sciences",
    photocredit03: "©2000 California Academy of Sciences",
    photocredit04: "©1995 Saint Mary's College of California",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Solanum umbelliferum",
    common_name: "Nightshade",
    family_name: "Solanaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer;Winter",
    flower_color: "Purple",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Chaparral;Coastal Scrub;Woodland",
    suitableSiteConditions: "Sun;Part shade",
    soil_type: "Clay;Loam",
    waterNeeds: "None",
    habitat_value: "Fruit;Pollinator",
    associated_wildlife: "Bees;Birds;Butterflies",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2007 Neal Kramer",
    photocredit02: "©2008 Ellen Tatum",
    photocredit03: "©1995 Saint Mary's College of California",
    photocredit04: "©1995 Saint Mary's College of California",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Stipa lepida",
    common_name: "Foothill needlegrass",
    family_name: "Poaceae",
    plantType: "Grass",
    bloomTime: "Spring",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Chaparral;Coastal Scrub;Grassland/prairie",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam",
    waterNeeds: "None",
    associated_wildlife: "Butterflies",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes: "Good ground cover",
    photocredit01: "©2013 Belinda Lo",
    photocredit02: "©2011 Vernon Smith",
    photocredit03: "©2009 Tom Cochrane",
    photocredit04: "©2013 Belinda Lo",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Umbellularia californica",
    common_name: "California bay laurel",
    family_name: "Lauraceae",
    plantType: "Tree (evergreen)",
    bloomTime: "Spring",
    flower_color: "Yellow",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Chaparral;Woodland",
    suitableSiteConditions: "Sun;Part shade;Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "None",
    habitat_value: "Fruit;Cover",
    associated_wildlife: "Birds",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes: "Attracts small mammals",
    photocredit01: "©2003 Michael Charters",
    photocredit02: "©1999 California Academy of Sciences",
    photocredit03: "©2001 Tony Morosco",
    photocredit04: "©1999 California Academy of Sciences",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Rosmarinus officinalis",
    common_name: "Rosemary",
    family_name: "Lamiaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Summer",
    flower_color: "Blue",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees;Butterflies",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes: "Good ground cover",
    photocredit01: "©2004 Steve Matson",
    photocredit02: "©2005 Luigi Rignanese",
    photocredit03: "©2009 Neal Kramer",
    photocredit04: "©2005 Luigi Rignanese",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Arabis blepharophylla",
    common_name: "Rock rose cress",
    family_name: "Brassicaceae",
    plantType: "Perennial",
    bloomTime: "Spring",
    flower_color: "Purple;Pink",
    sizeAtMaturity: "7-12 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Coastal scrub",
    suitableSiteConditions: "Part Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Low",
    habitat_value: "Pollinator;Cover",
    associated_wildlife: "Butterflies",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes: "Does well in a rock garden",
    photocredit01: "©1995 Saint Mary's College of California",
    photocredit02: "©1996 Margo Bors",
    photocredit03: "©2009 Larry Beckerman",
    photocredit04: "©2008 Margo Bors",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Triteleia laxa",
    common_name: "Ithuriel's Spear",
    family_name: "Themidaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "Purple",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Chaparral;Woodland;Grassland/prairie",
    suitableSiteConditions: "Sun;Part shade",
    soil_type: "Clay;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees; Butterflies",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden;Sidewalk;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2003 George W. Hartwell",
    photocredit02: "©1995 Saint Mary's College of California",
    photocredit03: "©1999 California Academy of Sciences",
    photocredit04: "©1995 Saint Mary's College of California",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Carex tumulicola",
    common_name: "Berkley sedge",
    family_name: "Cyperaceae",
    plantType: "Grass",
    bloomTime: "Spring",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Woodland;Grassland/prairie;Coastal scrub",
    suitableSiteConditions: "Part Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Low",
    associated_wildlife: "Birds",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: " ©2002 Dean Wm. Taylor",
    photocredit02: "©2015 Aaron Arthur",
    photocredit03: "©2009 Chris Wagner",
    photocredit04: "©2003 Steve Matson",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Ceanothus gloriosus",
    common_name: "Point Reyes Ceanothus",
    family_name: "Rhamnaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Winter",
    flower_color: "Blue;Purple",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Chaparral;Coastal scrub;Woodland",
    suitableSiteConditions: "Sun;Part shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Humminbirds;Bees;Butterflies;Bees",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "4",
    street_tree_list: "NA",
    photocredit01: "©2011 Thomas Reyes",
    photocredit02: "©2006 Doreen L. Smith",
    photocredit03: "©2012 Aaron Arthur",
    photocredit04: "©2008 Jorg Fleige",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Cynoglossum grande",
    common_name: "Hound's Tongue",
    family_name: "Boraginaceae",
    plantType: "Perennial",
    bloomTime: "Spring",
    flower_color: "Blue;Purple",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Woodland;Chaparral;Coastal scrub",
    suitableSiteConditions: "Part shade;Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees, Butterflies, Birds",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2003 Russell Towle",
    photocredit02: "©1995 Saint Mary's College of California",
    photocredit03: "©2002 Lindsay Herrera",
    photocredit04: "©2002 George Jackson",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Eriogonum fasciculatum",
    common_name: "California Buckwheat",
    family_name: "Polygonaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Summer;Fall",
    flower_color: "Pink;White",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Coastal scrub;Grassland;Woodland",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies;Bees,Birds",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "20",
    street_tree_list: "NA",
    photocredit01: "©2002 Lynn Watson",
    photocredit02: "©2002 Lynn Watson",
    photocredit03: "©2002 Hartmut Wisch",
    photocredit04: "©2005 California Academy of Sciences",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Eriogonum giganteum",
    common_name: "California Buckwheat;St. Catherine's Lace?",
    family_name: "Polygonaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Summer",
    flower_color: "White;Red",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Chaparral;Coastal scrub",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies; Bees",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "20",
    street_tree_list: "NA",
    photocredit01: "©2010 Gary A. Monroe",
    photocredit02: "©2003 Michael Charters",
    photocredit03: "©2009 Aaron Arthur",
    photocredit04: "©2010 Gary A. Monroe",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Eriogonum umbellatum",
    common_name: "Sulphurflower buckwheat",
    family_name: "Polygonaceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Chaparral",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies;Bees;Birds",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Roof;Sidewalk;Garden",
    suggested_green_connection_routes: "20",
    street_tree_list: "NA",
    photocredit01: "©1995 Saint Mary's College of California",
    photocredit02: "©2003 Christopher L. Christie",
    photocredit03: "©2003 Christopher L. Christie",
    photocredit04: "©2005 California Academy of Sciences",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Juncus effusus",
    common_name: "Common rush",
    family_name: "Juncaceae",
    plantType: "Grass",
    bloomTime: "Summer",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Riparian;Wetland;Woodland",
    suitableSiteConditions: "Sun;Part shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Moderate",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes: "Shelter for birds",
    photocredit01: "©2010 Louis-M. Landry",
    photocredit02: "©2008 Dr. Amadej Trnkoczy",
    photocredit03: "©2008 Dr. Amadej Trnkoczy",
    photocredit04: "©2007 Louis-M. Landry",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Lathyrus vestitus",
    common_name: "Wild Pea",
    family_name: "Fabaceae",
    plantType: "Vine",
    bloomTime: "Spring",
    flower_color: "Magenta;Purple",
    sizeAtMaturity: "7-12 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Chaparral;Woodland;Coastal scrub",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;loam",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Birds;Hummingbirds;Butterflies",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden;Sidewalk;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes: "Climbing",
    photocredit01: "©1995 Saint Mary's College of California",
    photocredit02: "©1995 Saint Mary's College of California",
    photocredit03: "©1995 Saint Mary's College of California",
    photocredit04: "©2002 Lynn Watson",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Leptosiphon grandiflorus",
    common_name: "Large-flower linanthus",
    family_name: "Polemoniaceae",
    former_latin_name: "Linanthus grandiflorus",
    plantType: "Annual",
    bloomTime: "Spring",
    flower_color: "Pink;White",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Coastal scrub;Grassland/prairie",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Sand",
    waterNeeds: "None",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Sidewalk;Garden;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes: "CNPS Rare Plant",
    photocredit01: "©2013 John Doyen",
    photocredit02: "©2009 Barry Breckling",
    photocredit03: "©1995 Saint Mary's College of California",
    photocredit04: " ©2003 Doreen L. Smith",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Ribes aureum",
    common_name: "Golden Currant",
    family_name: "Grossulariaceae",
    plantType: "Shrub (deciduous)",
    bloomTime: "Spring",
    flower_color: "Yellow",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Riparian;Woodland",
    suitableSiteConditions: "Sun;Part shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    habitat_value: "Fruit;Pollinator",
    associated_wildlife: "Birds;Hummingbirds;Butterflies;Bees",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "20",
    street_tree_list: "NA",
    photocredit01: "©1987 Gary A. Monroe",
    photocredit02: "©1987 Gary A. Monroe",
    photocredit03: "©2005 Steve Matson",
    photocredit04: "©1995 Saint Mary's College of California",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Salvia mellifera",
    common_name: "Black sage",
    family_name: "Lamiaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Summer",
    flower_color: "White;Purple",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Coastal scrub;Chaparral",
    suitableSiteConditions: "Sun;Part shade",
    soil_type: "Clay;Sand",
    waterNeeds: "None",
    habitat_value: "Fruit;Pollinator",
    associated_wildlife: "Hummingbirds;Butterflies;Birds",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden;Sidewalk;Roof",
    suggested_green_connection_routes: "20",
    street_tree_list: "NA",
    additional_characteristices_notes: "Groundcover;Good for erosion control",
    photocredit01: "©2004 Hartmut Wisch",
    photocredit02: "©2006 Steve Matson",
    photocredit03: "©2008 Gary McDonald",
    photocredit04: "©2006 Steve Matson",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Nepeta cataria",
    common_name: "Catnip",
    family_name: "Lamiaceae",
    plantType: "Perennial",
    bloomTime: "Summer;Fall",
    flower_color: "Blue;Purple;White",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2013 Al Keuter",
    photocredit02: "©2013 Al Keuter",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Silene laciniata",
    common_name: "California Indian Pink",
    family_name: "Caryophyllaceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "Red",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Coastal scrub;Chaparral",
    suitableSiteConditions: "Sun;Part shade",
    soil_type: "Clay;Sand",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Hummingbirds;Butterflies",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden;Sidewalk;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2002 Brad Kelley",
    photocredit02: "©1995 Saint Mary's College of California",
    photocredit03: "©2006 Matt Below",
    photocredit04: "©2001 California Academy of Sciences",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Lonicera hispidula",
    common_name: "California Honeysuckle",
    family_name: "Caprifoliaceae",
    plantType: "Vine",
    bloomTime: "Summer",
    flower_color: "Pink",
    sizeAtMaturity: "7-12 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Coastal scrub;Woodland;Riparian",
    suitableSiteConditions: "Sun;Part shade;Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Low",
    habitat_value: "Fruit",
    associated_wildlife: "Birds;Hummingbirds",
    stormwater_benefit: "No",
    stormwater_int: "0",
    appropriateLocation: "Garden;Roof",
    suggested_green_connection_routes: "4",
    street_tree_list: "NA",
    additional_characteristices_notes: "Climbing",
    photocredit01: "©2003 Margo Bors",
    photocredit02: "©2005 California Academy of Sciences",
    photocredit03: "©2006 Matt Below",
    photocredit04: "©2005 Jerry Murray",
    special_list: "Shady Clay",
    habitat: "Yes",
    habitat_int: "1",
    shady_clay: "Shady Clay",
    shady_clay_int: "1"
  },
  {
    latin_name: "Thuja occidentalis",
    common_name: "American arborvitae",
    family_name: "Cupressaceae",
    plantType: "Tree (evergreen)",
    bloomTime: "Spring",
    sizeAtMaturity: "Full Grown Tree",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade;Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator;Cover;Nesting",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "© 2012 Tom Potterfield",
    photocredit02: "©2009 Forest and Kim Starr"
  },
  {
    latin_name: "Lavandula angustifolia 'Hidcote Superior'",
    common_name: "English Lavender",
    family_name: "Lamiaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Summer;Fall",
    flower_color: "Indigo",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies;Bees;Hummingbirds",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2006 Luigi Rignanese",
    photocredit02: "©2006 Luigi Rignanese",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Lavandula 'Goodwin Creek Grey'",
    common_name: "Goodwin Creek Lavender",
    family_name: "Lamiaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Summer;Fall;Winter",
    flower_color: "Purple",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies;Bees;Hummingbirds",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2012 Couleur Lavande",
    photocredit02: "©2012 Couleur Lavande",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Lavandula stoechas 'Alba'",
    common_name: "Spanish White Lavender",
    family_name: "Lamiaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Fall",
    flower_color: "White",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies;Bees;Hummingbirds",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "www.smgrowers.com",
    photocredit02: "©2015 KM",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Lavandula stoechas 'Willow Vale'",
    common_name: "Spanish Lavender",
    family_name: "Lamiaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "spring;summer",
    flower_color: "Purple",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies;Bees;Hummingbirds",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "www.smgrowers.com",
    photocredit02: "www.smgrowers.com",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Lavandula dentata var. candicans",
    common_name: "French Lavender",
    family_name: "Lamiaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Summer;Fall;Winter",
    flower_color: "Purple",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies;Bees;Hummingbirds",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "www.smgrowers.com",
    photocredit02: "www.smgrowers.com",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Leucadendron Salignum",
    common_name: "Common Sunshine Conebush",
    family_name: "Proteaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Winter",
    flower_color: "Red",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2014 Zoya Akulova",
    photocredit02: "©2014 Zoya Akulova",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Lupinus propinquus  ",
    common_name: "Violet-flowered Lupine",
    family_name: "Fabaceae",
    plantType: "Perennial",
    bloomTime: "spring;summer",
    flower_color: "Purple",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies;Bees;Hummingbirds",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2010 Zoya Akulova",
    photocredit02: "©2009 James Gaither",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Malvastrum lateritium",
    common_name: "Trailing mallow",
    family_name: "Malvaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Summer;Fall",
    flower_color: "Pink",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "clay;loam",
    waterNeeds: "None",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2005 Titanium22",
    photocredit02: "©2005 Titanium22",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Muhlenbergia lindheimeri",
    common_name: "Lindheimer's muhly grass",
    family_name: "Poaceae",
    plantType: "Grass",
    bloomTime: "Fall;Winter",
    flower_color: "Gray",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay",
    waterNeeds: "None",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©1989 Katy Kramer McKinney",
    photocredit02: "©2012 Lee Page",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Myoporum parvifolium",
    common_name: "Myoporum",
    family_name: "Myoporaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "summer",
    flower_color: "White",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2011 Arthur Chapman",
    photocredit02: "©2011 Arthur Chapman",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Myrtus communis",
    common_name: "Common Myrtle",
    family_name: "Myrtaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Summer",
    flower_color: "White",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator;Fruit",
    associated_wildlife: "Birds",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2016 Zoya Akulova",
    photocredit02: "©2009 Zoya Akulova",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Oenothera elata ssp. hirsutissima",
    common_name: "Hairy Evening Primrose",
    family_name: "Onagraceae",
    plantType: "Perennial",
    bloomTime: "Summer;Fall",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Hummingbirds",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2010 Zoya Akulova",
    photocredit02: "©2010 Leonora Ellie Enking",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Pelargonium sidioides",
    common_name: "Geranium",
    family_name: "Geraniaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer;Fall;Winter",
    flower_color: "Magenta",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2009 Stickpen",
    photocredit02: "©2008 Peganum",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Philotheca verrucosa 'Starbright'",
    common_name: "Fairy Wax Flower",
    family_name: "Rutaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Winter",
    flower_color: "Pink",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Shade",
    soil_type: "Clay;Loam;Sand;Rock",
    waterNeeds: "None",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2010 Nathan Johnson",
    photocredit02: "©2012 Nuytsia@Tas",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Phlomis lanata",
    common_name: "Small Jerusalem Sage",
    family_name: "Lamiaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Summer;Fall;Winter",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Sand;Loam",
    waterNeeds: "None",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2010 Zoya Akulova",
    photocredit02: "©2013 Peganum",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Phormium 'Dusky Chief'",
    common_name: "New Zealand Flax",
    family_name: "Phormiaceae",
    plantType: "Perennial",
    flower_color: "Red/Brown",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "www.smgrowers.com",
    photocredit02: "plantexpress.com",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Phormium 'Jack Spratt'",
    common_name: "New Zealand Flax",
    family_name: "Phormiaceae",
    plantType: "Perennial",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "www.smgrowers.com",
    photocredit02: "http://shop.monrovia.com",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Phormium 'Tiny Tiger'",
    common_name: "Dwarf Variegated New Zealand Flax",
    family_name: "Phormiaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "www.smgrowers.com",
    photocredit02: "krispgarden.blogspot.com",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Phormium 'Yellow Wave'",
    common_name: "New Zealand Flax",
    family_name: "Phormiaceae",
    plantType: "Perennial",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2013 Pepinieriste 85",
    photocredit02: "©1998 forest and kim Starr",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Phyla nodiflora",
    common_name: "Turkey Tangle Fogfruit",
    family_name: "Verbenaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer;Fall",
    flower_color: "Purple;Pink",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Wetland;Riparian",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies;Bees",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2012 Jean Pawek",
    photocredit02: "©2009 Keir Morse",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Plantago erecta",
    common_name: "Prarie Plantain",
    family_name: "Plantaginaceae",
    plantType: "Annual",
    bloomTime: "Spring",
    flower_color: "White",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Coastal scrub;Woodland;Chaparral;Dunes",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Sand",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies",
    appropriateLocation: "Garden;Sidewalk;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2008 Steve Matson",
    photocredit02: "©2008 Steve Matson",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Potentilla fruticosa",
    common_name: "Shrubby Cinquefoil",
    family_name: "Rosaceae",
    former_latin_name: "Dasiphora fruticosa",
    plantType: "Shrub (evergreen)",
    bloomTime: "Summer;Fall",
    flower_color: "Yellow",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun;Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2015 Barry Breckling",
    photocredit02: "©2015 Barry Breckling",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Rhagodia spinescens",
    common_name: "Australian Thorny Saltbush",
    family_name: "Chenopodiaceae",
    plantType: "Shrub (evergreen)",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2013 Tony Rodd",
    photocredit02: "©2013 Tony Rodd",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Rhaphiolepis Indica 'Ballerina'",
    common_name: "Indian hawthorn",
    family_name: "Rosaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Fall",
    flower_color: "Pink",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies;Bees",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2006 Stan Shebs",
    photocredit02: "©2006 Stan Shebs",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Rosmarinus officinalis 'Prostratus'",
    common_name: "Prostrate Rosemary, Creeping Rosemary",
    family_name: "Lamiaceae",
    plantType: "Perennial",
    bloomTime: "Summer;Fall",
    flower_color: "Blue;Purple",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies;Bees",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2012 Leonora Ellie Enking",
    photocredit02: "©2010 Leonora Ellie Enking",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Rubus pentalobus",
    common_name: "Bramble",
    family_name: "Rosaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Summer",
    flower_color: "White",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2015 Andrey Zharkikh",
    photocredit02: "©2015 Andrey Zharkikh",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Rudbeckia californica",
    common_name: "California coneflower",
    family_name: "Asteraceae",
    plantType: "Perennial",
    bloomTime: "Summer;Fall",
    flower_color: "Yellow",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun;Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2010 Jean Pawek",
    photocredit02: "©2010 Jean Pawek",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Salvia 'Bee's Bliss'",
    common_name: "Bee's Bliss Sage",
    family_name: "Lamiaceae",
    plantType: "Perennial",
    bloomTime: "Spring",
    flower_color: "purple;blue",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies;Bees;Hummingbirds",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2016 Katie Hetrick",
    photocredit02: "©2016 Katie Hetrick",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Salvia greggii 'Alba'",
    common_name: "Autumn Sage",
    family_name: "Lamiaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Summer;Fall",
    flower_color: "White",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies;Bees;Hummingbirds",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2016 Meneerke Bloem",
    photocredit02: "©2016 Meneerke Bloem",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Salvia greggii 'Salmon'",
    common_name: "Salmon Texas Sage",
    family_name: "Lamiaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Fall",
    flower_color: "Pink",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies;Bees;Hummingbirds",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "www.smgrowers.com",
    photocredit02: "www.smgrowers.com",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Salvia leucantha",
    common_name: "Mexican Bush Sage, Velvet Sage",
    family_name: "Lamiaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Summer;Fall",
    flower_color: "White;Purple",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies;Bees;Hummingbirds",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2006 Eric Hunt",
    photocredit02: "©2010 Mathesont",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Santolina chamaecyparissus",
    common_name: "Lavender Cotton",
    family_name: "Asteraceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2006 Luigi Rignanese",
    photocredit02: "©2012 Richard Spellenberg",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Scilla peruviana    ",
    common_name: "Peruvian Scilla",
    family_name: "Hyacinthaceae",
    plantType: "Perennial",
    bloomTime: "Spring",
    flower_color: "Purple;blue",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2012 Zoya Akulova",
    photocredit02: "©2008 Sean A. O'Hara",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Sedum pachyphyllum",
    common_name: "Stonecrop",
    family_name: "Crassulaceae",
    plantType: "Succulent",
    bloomTime: "Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    appropriateLocation: "Roof;Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2008 Ecos de Pedra",
    photocredit02: "©2008 Joe Mud",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Sedum palmeri",
    common_name: "Hens and Chicks",
    family_name: "Crassulaceae",
    plantType: "Succulent",
    bloomTime: "Spring;Winter",
    flower_color: "Yellow",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    appropriateLocation: "Roof;Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2013 Zoya Akulova",
    photocredit02: "©2013 Zoya Akulova",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Sedum rubrotinctum",
    common_name: "Pork and Beans",
    family_name: "Crassulaceae",
    plantType: "Succulent",
    bloomTime: "Spring;Winter",
    flower_color: "Yellow",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Part Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    appropriateLocation: "Garden;Sidewalk;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2014 Zoya Akulova",
    photocredit02: "©2010 Zoya Akulova",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Silene californica ",
    common_name: "California Indian Pink",
    family_name: "Caryophyllaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "Red",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Coastal scrub;Chaparral;Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Hummingbirds",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2015 Barry Breckling",
    photocredit02: "©2015 Barry Breckling",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Sempervivum montanum",
    common_name: "Houseleek",
    family_name: "Crassulaceae",
    plantType: "Succulent",
    bloomTime: "Summer",
    flower_color: "Magenta",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2010 Joan Simon",
    photocredit02: "©2010 Joan Simon",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Senecio amaniensis",
    common_name: "Amani Senecio",
    family_name: "Asteraceae",
    plantType: "Succulent",
    bloomTime: "Summer",
    flower_color: "Orange",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2015 Gael Rakotovao",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Senecio cineraria",
    common_name: "Dusty Miller",
    family_name: "Asteraceae",
    former_latin_name: "Jacobaea maritima",
    plantType: "Succulent",
    bloomTime: "Spring;Summer;Fall;Winter",
    flower_color: "White",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2014 Cerlin Ng",
    photocredit02: "©2010 Ecos De Pedra",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Senecio mandraliscae",
    common_name: "Blue finger",
    family_name: "Asteraceae",
    plantType: "Succulent",
    bloomTime: "Summer",
    flower_color: "White",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Cover",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2008 Zoya Akulova",
    photocredit02: "©2008 Zoya Akulova",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Senecio scaposus",
    family_name: "Asteraceae",
    plantType: "Succulent",
    bloomTime: "Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2008 Zoya Akulova",
    photocredit02: "©2014 Ezequiel Coelho",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Solanum jasminoides",
    common_name: "Potato Vine",
    family_name: "Solanaceae",
    former_latin_name: " Solanum laxum",
    plantType: "Vine",
    bloomTime: "Summer;Fall",
    flower_color: "White",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Sand;Loam;Clay",
    waterNeeds: "None",
    habitat_value: "Cover;Nesting",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2010 Bri Weldon",
    photocredit02: "©2010 Bri Weldon",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Sollya heterophylla (Billardiera heterophylla)",
    common_name: "Australian Bluebell Creeper",
    family_name: "Pittosporaceae",
    former_latin_name: "Billardiera heterophylla",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Summer;Fall",
    flower_color: "Blue",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Part shade;Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2016 Alwyn Ladell",
    photocredit02: "©2016 Alwyn Ladell",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Stipa arundinacea",
    common_name: "Feather Grass",
    family_name: "Poaceae",
    plantType: "Grass",
    bloomTime: "Summer",
    flower_color: "Brown;Green;Purple",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam;Clay",
    waterNeeds: "None",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2010 Bri Weldon",
    photocredit02: "©2001 Bri Weldon",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Tecomaria capensis",
    common_name: "Cape Honeysuckle",
    family_name: "Bignoniaceae",
    former_latin_name: "Tecoma capensis",
    plantType: "Vine",
    bloomTime: "Spring;Fall;Winter",
    flower_color: "Orange;Red",
    sizeAtMaturity: "13-24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator;Cover;Nesting",
    associated_wildlife: "Hummingbirds;butterflies",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2006 Luigi Rignanese",
    photocredit02: "©2006 Luigi Rignanese",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Thymus serpyllum (var. 'Elfin')",
    common_name: "Creeping Thyme",
    family_name: "Lamiaceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "Pink",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator;Cover",
    associated_wildlife: "Bees",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2009 Jason Hollinger",
    photocredit02: "©2014 Andreas Rockstein",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Trifolium repens",
    common_name: "White Clover",
    family_name: "Fabaceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "White",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2007 Steve Matson",
    photocredit02: " ©2011 Zoya Akulova",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Verbena lilacina 'De La Mina'",
    common_name: "Cedros Island Verbena",
    family_name: "Verbenaceae",
    plantType: "Perennial",
    bloomTime: "Spring;summer",
    flower_color: "Purple",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun;Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies;bees",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2015 Jean Pawek",
    photocredit02: "©2015 Jean Pawek",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Penstemon heterophyllus varieties",
    common_name: "Penstemon",
    family_name: "Plantaginaceae",
    plantType: "Perennial",
    bloomTime: "Spring;summer",
    flower_color: "Indigo;Blue;Ourple",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies;Bees;Hummingbirds",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2010 Jean Pawek",
    photocredit02: "©2016 Steven Thorsted",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Linaria purpurea",
    common_name: "Purple Toadflax",
    family_name: "Plantaginaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer;Fall;Winter",
    flower_color: "Purple",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies;bees",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2014 Zoya Akulova",
    photocredit02: "©2014 Zoya Akulova",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Rudbeckia hirta",
    common_name: "Black-eyed Susan",
    family_name: "Asteraceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer;Fall;Winter",
    flower_color: "Yellow;Orange",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies;bees",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: " ©Nick Kurzenko",
    photocredit02: "©Nick Kurzenko",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Salvia 'Indigo Spires'",
    common_name: "Indigo Spires sage",
    family_name: "Lamiaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer;Fall;Winter",
    flower_color: "Blue;Purple;White",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;part shade",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2009 Sage K.",
    photocredit02: "©2008 Jeffrey",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Phacelia cicutaria",
    common_name: "Caterpillar phacelia",
    family_name: "Boraginaceae",
    plantType: "Annual",
    bloomTime: "Spring;Summer",
    flower_color: "White",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2004 Cheri Miller",
    photocredit02: "©2011 Jean Pawek",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Phacelia tanacetifolia",
    common_name: "Tansy phacelia",
    family_name: "Boraginaceae",
    plantType: "Annual",
    bloomTime: "Spring;Summer",
    flower_color: "Purple",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2007 Luigi Rignanese",
    photocredit02: "©2007 Luigi Rignanese",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Salvia brandegeei",
    common_name: "Brandegee sage",
    family_name: "Lamiaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer;Fall;Winter",
    flower_color: "White",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Coastal scrub;Chaparral;Woodland",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies;Bees;Hummingbirds",
    appropriateLocation: "Garden;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2010 Gary A. Monroe",
    photocredit02: "©2010 Gary A. Monroe",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Salvia munzii",
    common_name: "Munz's sage",
    family_name: "Lamiaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer;Fall;Winter",
    flower_color: "Purple",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies;Bees;Hummingbirds",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2002 Dean Wm. Taylor",
    photocredit02: "©2007 Dick Clubert",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Phacelia distans",
    common_name: "Distant phacelia",
    family_name: "Boraginaceae",
    plantType: "Annual",
    bloomTime: "Spring;Summer",
    flower_color: "White;Ourple",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Grassland/prairie;Chaparral;Woodland",
    suitableSiteConditions: "Sun;Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2012 Keir Morse",
    photocredit02: "©2012 Keir Morse",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Phacelia viscida",
    common_name: "Sticky phacelia",
    family_name: "Boraginaceae",
    plantType: "Annual",
    bloomTime: "Spring;Summer",
    flower_color: "Indigo",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2012 Jean Pawek",
    photocredit02: "©2012 Jean Pawek",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Bidens aurea",
    common_name: "Arizona beggarticks",
    family_name: "Asteraceae",
    plantType: "Perennial",
    bloomTime: "Summer;Fall",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2016 Wynn Anderson",
    photocredit02: "©2016 Wynn Anderson",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Gaillardia aristata",
    common_name: "Blanket flower",
    family_name: "Asteraceae",
    plantType: "Perennial",
    bloomTime: "Summer;Fall",
    flower_color: "Orange;Yellow;Red",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2012 Zoya Akulova",
    photocredit02: "©2012 Zoya Akulova",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Helianthus annuus",
    common_name: "Sunflower",
    family_name: "Asteraceae",
    plantType: "Annual",
    bloomTime: "Summer;Fall",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Wetland;riparian",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies;bees",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2016 Richard Spellenberg",
    photocredit02: "©2016 Steven Thorsted",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Salvia uliginosa",
    common_name: "Bog sage",
    family_name: "Lamiaceae",
    plantType: "Perennial",
    bloomTime: "Summer;Fall",
    flower_color: "Blue",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "sun;part shade",
    soil_type: "Sand;Loam",
    waterNeeds: "Moderate",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2012 Zoya Akulova",
    photocredit02: "©2014 Scott Zona",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Borago officinalis",
    common_name: "Borage",
    family_name: "Boraginaceae",
    plantType: "Annual",
    bloomTime: "Spring",
    flower_color: "Blue",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;part shade",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2007 Luigi Rignanese",
    photocredit02: "©2005 Luigi Rignanese",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Calendula spp.",
    common_name: "Pot Marigold",
    family_name: "Asteraceae",
    plantType: "Perennial",
    bloomTime: "Spring",
    flower_color: "Yellow;Orange",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2008 Naitokz",
    photocredit02: "©2007 Keith Roper",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Chilopsis linearis",
    common_name: "Desert Willow",
    family_name: "Bignoniaceae",
    plantType: "Perennial",
    bloomTime: "Spring",
    flower_color: "White;pink",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Hummingbirds;Bees",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2013 Jean Pawek",
    photocredit02: "©2012 Keir Morse",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Coreopsis verticillata",
    common_name: "Whorled Tickseed",
    family_name: "Asteraceae",
    plantType: "Perennial",
    bloomTime: "Spring;summer",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies;bees",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2012 Zoya Akulova",
    photocredit02: "©2007 Carl Lewis",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Echium plantagineum",
    common_name: "Salvation Jane",
    family_name: "Boraginaceae",
    plantType: "Perennial",
    bloomTime: "Spring",
    flower_color: "Indigo",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies;bees",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2005 Luigi Rignanese",
    photocredit02: "©2006 Luigi Rignanese",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Encelia farinosa",
    common_name: "Brittlebush",
    family_name: "Asteraceae",
    plantType: "Perennial",
    bloomTime: "Spring",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©1992 Gary A. Monroe",
    photocredit02: "©2006 Steven Thorsted",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Gilia achilleifolia",
    common_name: "California Gilia",
    family_name: "Polemoniaceae",
    plantType: "Annual",
    bloomTime: "Spring",
    flower_color: "Purple",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun;Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2009 Keir Morse",
    photocredit02: "©2016 Steven Thorsted",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Helenium puberulum",
    common_name: "Sneezeweed",
    family_name: "Asteraceae",
    plantType: "Perennial",
    bloomTime: "Spring",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "wetland;riparian",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "Moderate",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2010 Zoya Akulova",
    photocredit02: "©2008 Keir Morse",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Layia platyglossa",
    common_name: "Tidytips",
    family_name: "Asteraceae",
    plantType: "Annual",
    bloomTime: "Spring",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Coastal scrub;Woodland;Chaparral;Grassland",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees",
    appropriateLocation: "Garden;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2009 Barry Breckling",
    photocredit02: "©2009 Barry Breckling",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Mentzelia lindleyi",
    common_name: "Blazing Star",
    family_name: "Loasaceae",
    plantType: "Annual",
    bloomTime: "Spring",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2015 Richard Spellenberg",
    photocredit02: "©2015 Richard Spellenberg",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Penstemon parryi",
    common_name: "Desert Penstemon, Parry's beardtongue",
    family_name: "Scrophulariaceae",
    plantType: "Perennial",
    bloomTime: "Spring",
    flower_color: "Pink;red",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Shade",
    soil_type: "Clay;Loam;Sand;Rock",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2010 Anthony Mendoza",
    photocredit02: "©2015 Anthony Mendoza",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Phacelia grandiflora",
    common_name: "Large-flower phacelia",
    family_name: "Boraginaceae",
    plantType: "Annual",
    bloomTime: "Spring",
    flower_color: "Purple",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun",
    soil_type: "Loam;Sand",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2007 Debra Hansen",
    photocredit02: "©2005 Victoria Marshall",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Schoenoplectus maritimus",
    common_name: "Prairie bulrush",
    family_name: "Cyperaceae",
    plantType: "Grass",
    bloomTime: "Fall",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Riparian;Wetland",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Moderate",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2015 Andrey Zharkikh",
    photocredit02: "©2015 Andrey Zharkikh",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Santolina spp.",
    common_name: "santolina",
    family_name: "Asteraceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2009 Jimmy Thomas",
    photocredit02: "©2011 Leonora Enking",
    additional_species_cultivars_varieties: "chamaecyparissus,  rosmarinifolia",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Poa unilateralis",
    common_name: "San Francisco bluegrass",
    family_name: "Poaceae",
    plantType: "Grass",
    bloomTime: "Spring",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Coastal scrub",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2009 Eric Wrubel",
    photocredit02: "©2016dgreenberger",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Platanus racemosa",
    common_name: "California sycamore",
    family_name: "Platanaceae",
    plantType: "Tree (deciduous)",
    bloomTime: "Spring;Winter",
    sizeAtMaturity: "Full Grown Tree",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Riparian",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Moderate",
    habitat_value: "Pollinator;Cover;Nesting",
    associated_wildlife: "butterflies;birds",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2013 Laura Camp",
    photocredit02: "©2014 J. Maughn",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Platanus occidentalis",
    common_name: "American sycamore",
    family_name: "Platanaceae",
    plantType: "Tree (deciduous)",
    bloomTime: "Spring",
    sizeAtMaturity: "Full Grown Tree",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    habitat_value: "Cover;Nesting",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2015 Zoya Akulova",
    photocredit02: "©2006 Phillip Merritt, 2006"
  },
  {
    latin_name: "Plantago subnuda",
    common_name: "tall coastal plantain",
    family_name: "Plantaginaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Wetland;Riparian;Coastal scrub;Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Moderate",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2014 Zoya Akulova",
    photocredit02: "©2015 Jean Pawek",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Pellaea andromedifolia",
    common_name: "coffee fern",
    family_name: "Pteridaceae",
    plantType: "Fern",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Grassland/prairie;Woodland;Chaparral",
    suitableSiteConditions: "Sun",
    soil_type: "Sand",
    waterNeeds: "None",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2008 Keir Morse",
    photocredit02: "©2008 Gary A. Monroe",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Nyssa sylvatica",
    common_name: "tupelo, black gum",
    family_name: "Cornaceae",
    plantType: "Tree (deciduous)",
    bloomTime: "Spring;summer",
    flower_color: "White;Green;Brown",
    sizeAtMaturity: "Full Grown Tree",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade;Shade",
    soil_type: "Loam;Sand",
    waterNeeds: "Moderate",
    habitat_value: "Pollinator;Cover;Nesting",
    associated_wildlife: "birds;bees",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2011 Chuck B.",
    photocredit02: "2013 Will Pollard"
  },
  {
    latin_name: "Nepeta spp.",
    common_name: "catmint",
    family_name: "Lamiaceae",
    plantType: "Perennial",
    bloomTime: "Summer;Fall",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2012 Jean Pawek ",
    photocredit02: "©2011 Jean Pawek",
    additional_species_cultivars_varieties: "cataria,racemosa, x faassenii"
  },
  {
    latin_name: "Morus alba",
    common_name: "White mulberry",
    family_name: "Moraceae",
    plantType: "Tree (deciduous)",
    bloomTime: "Spring",
    flower_color: "Green",
    sizeAtMaturity: "Full Grown Tree",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Loam;Sand",
    waterNeeds: "Moderate",
    habitat_value: "Cover;Nesting",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2016 Zoya Akulova",
    photocredit02: "©2016 Zoya Akulova"
  },
  {
    latin_name: "Monardella villosa franciscana",
    common_name: "San Francisco coyote mint",
    family_name: "Lamiaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "Purple",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "coastal scrub;chaparral",
    suitableSiteConditions: "Sun",
    soil_type: "Loam;Sand",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "hummingbirds;butterflies;birds;bees",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2009 John Rusk",
    photocredit02: "©2013 John Rusk",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Carya illinoinensis",
    common_name: "pecan",
    family_name: "Juglandaceae",
    plantType: "Tree (deciduous)",
    bloomTime: "Spring",
    flower_color: "Yellow",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Loam;Sand",
    waterNeeds: "Moderate",
    habitat_value: "Fruit;Cover;Nesting",
    associated_wildlife: "butterflies;birds",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2016 Steve Matson",
    photocredit02: "©2016 Steve Matson"
  },
  {
    latin_name: "Melaleuca viridiflora rubriflora (Melaleuca quinquenervia)",
    common_name: "Cajeput tree",
    family_name: "Myrtaceae",
    former_latin_name: "Melaleuca quinquenervia",
    plantType: "Tree (evergreen)",
    bloomTime: "Summer;Fall",
    flower_color: "white",
    sizeAtMaturity: "Full Grown Tree",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    habitat_value: "Cover;Nesting",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2015 Zoya Akulova",
    photocredit02: "©2015 Zoya Akulova"
  },
  {
    latin_name: "Melaleuca viminalis (Callistemon viminalis)",
    common_name: "Weeping bottle brush",
    family_name: "Myrtaceae",
    former_latin_name: "Callistemon viminalis",
    plantType: "Tree (evergreen)",
    bloomTime: "Spring;Summer;Fall;Winter",
    flower_color: "Red",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    habitat_value: "Cover;Nesting",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2008 Josh s. Jackson",
    photocredit02: "©2014, Tatters"
  },
  {
    latin_name: "Eucalyptus erythrocorys",
    common_name: "red-cap gum",
    family_name: "Myrtaceae",
    plantType: "Tree (evergreen)",
    bloomTime: "Spring;Summer;Fall;Winter",
    flower_color: "red;yellow",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    habitat_value: "Cover;Nesting",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2009 Zoya Akulova",
    photocredit02: "©2009 Zoya Akulova"
  },
  {
    latin_name: "Eriogonum parvifolium",
    common_name: "dune buckwheat",
    family_name: "Polygonaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer;Fall;Winter",
    flower_color: "Pink;White",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Coastal scrub",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "None",
    associated_wildlife: "butterflies;bees",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2006 Steve Matson",
    photocredit02: "©2006 Steve Matson",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Erigeron foliosus var. franciscensis",
    common_name: "San Francisco leafy fleabane",
    family_name: "Asteraceae",
    plantType: "Perennial",
    bloomTime: "Summer;Fall",
    flower_color: "Pink",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities:
      "Grassland/prairie;chaparral;woodland;wetland;coastal scrub",
    suitableSiteConditions: "Sun;Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "None",
    associated_wildlife: "Butterflies",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2014 gatoraceae",
    photocredit02: "©2015 dgreenberger",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Elymus trachycaulus",
    common_name: "slender wheatgrass",
    family_name: "Poaceae",
    plantType: "Grass",
    bloomTime: "Spring",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "riparian;wetland",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Moderate",
    associated_wildlife: "Butterflies",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2012 Jean Pawek",
    photocredit02: "©2013 Jean Pawek",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Eleocharis palustris",
    common_name: "creeping spikerush",
    family_name: "Cyperaceae",
    plantType: "Grass",
    bloomTime: "Summer",
    flower_color: "White",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "riparian;wetland",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Moderate",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "© 2006 Brecht De Causmaecker",
    photocredit02: "© 2012, Andrey Zharkikh",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Dryopteris expansa",
    common_name: "spreading wood fern",
    family_name: "Dryopteridaceae",
    plantType: "Fern",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Riparian;Wetland;Woodland",
    suitableSiteConditions: "Part shade;Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Low",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "© 2014 Joan Simon",
    photocredit02: "©2014 Joan Simon",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Delphinium californicum",
    common_name: "California larkspur",
    family_name: "Ranunculaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "White",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Woodland",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "None",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2004 Keir Morse",
    photocredit02: "©2004 Keir Morse",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Corymbia citriodora (Eucalyptus citriodora)",
    common_name: "lemon-scented gum",
    family_name: "Myrtaceae",
    former_latin_name: "Eucalyptus citriodora",
    plantType: "Tree (evergreen)",
    bloomTime: "Summer;Fall;Winter",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    habitat_value: "Cover;Nesting",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2016 Zoya Akulova",
    photocredit02: "©2016 Zoya Akulova"
  },
  {
    latin_name: "Cistus spp.",
    common_name: "rockrose",
    family_name: "Cistaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Summer",
    flower_color: "white;pink;purple",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Loam;Sand",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Birds",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2007 Luigi Rignanese",
    photocredit02: "©2007 Luigi Rignanese",
    additional_species_cultivars_varieties: "'Sunset', x skanbergii",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Chionanthus retusus",
    common_name: "Chinese fringe tree",
    family_name: "Oleaceae",
    plantType: "Tree (deciduous)",
    bloomTime: "Spring;Summer;Fall",
    flower_color: "White",
    sizeAtMaturity: "Full Grown Tree",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Loam",
    waterNeeds: "Low",
    habitat_value: "Fruit;Cover;Nesting",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2016 Zoya Akulova",
    photocredit02: "©2016 Zoya Akulova"
  },
  {
    latin_name: "Chamaemelum nobile",
    common_name: "chamomile",
    family_name: "Asteraceae",
    plantType: "Perennial",
    bloomTime: "Summer;Fall",
    flower_color: "White",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2008 Zoya Akulova",
    photocredit02: "©2008 Zoya Akulova",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Cerastium arvense",
    common_name: "field chickweed",
    family_name: "Caryophyllaceae",
    plantType: "Perennial",
    bloomTime: "Spring",
    flower_color: "White",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Riparian;Wetland;Coastal scrub;Woodland",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "None",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2012 Jean Pawek",
    photocredit02: "©2012 Jean Pawek",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Cephalanthus occidentalis",
    common_name: "buttonbush",
    family_name: "Rubiaceae",
    plantType: "Shrub (deciduous)",
    bloomTime: "Fall",
    flower_color: "White",
    sizeAtMaturity: "7-12 ft",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Loam",
    waterNeeds: "Moderate",
    habitat_value: "Pollinator;Cover;Nesting",
    associated_wildlife: "Bees",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2016 Keir Morse",
    photocredit02: "©2016 Keir Morse",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Celtis laevigata",
    common_name: "common hackberry",
    family_name: "Cannabaceae",
    plantType: "Tree (deciduous)",
    bloomTime: "Spring;Winter",
    flower_color: "Green",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Loam",
    waterNeeds: "Low",
    habitat_value: "Cover;Nesting",
    associated_wildlife: "Butterflies",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2009 Matt Lavin",
    photocredit02: "©2009 Matt Lavin",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Casuarina cunninghamiana",
    common_name: "river she-oak",
    family_name: "Casuarinaceae",
    plantType: "Tree (deciduous)",
    bloomTime: "Spring;Summer",
    flower_color: "Brown",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Sand",
    waterNeeds: "Low",
    habitat_value: "Cover;Nesting",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2014 John Tann",
    photocredit02: "©2014 John Tann"
  },
  {
    latin_name: "Carex obnupta",
    common_name: "slough sedge",
    family_name: "Cyperaceae",
    plantType: "Perennial",
    bloomTime: "Spring",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Grassland/prairie;Wetland;Riparian",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Moderate",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2010 Dana York",
    photocredit02: "©2010 Dana York",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Carex densa",
    common_name: "dense sedge",
    family_name: "Cyperaceae",
    former_latin_name: "Carex dudleyi",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Low",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2009 Zoya Akulova",
    photocredit02: "©2008 Dean Wm. Taylor, Ph.D.",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Bouteloua gracilis",
    common_name: "blue grama",
    family_name: "Poaceae",
    plantType: "Grass",
    bloomTime: "Summer",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "None",
    associated_wildlife: "Butterflies",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2011 Zoya Akulova",
    photocredit02: "©2011 Zoya Akulova",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Betula spp.",
    common_name: "birch",
    family_name: "Betulaceae",
    plantType: "Tree (deciduous)",
    bloomTime: "Spring",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam",
    waterNeeds: "Moderate",
    habitat_value: "Cover;Nesting",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2016 Keir Morse",
    photocredit02: "©2016 Keir Morse",
    additional_species_cultivars_varieties: "occidentalis",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Betula occidentalis",
    common_name: "water birch",
    family_name: "Betulaceae",
    plantType: "Tree (deciduous)",
    bloomTime: "Spring",
    sizeAtMaturity: "7-12 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Riparian;Wetland;Woodland",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam",
    waterNeeds: "Moderate",
    habitat_value: "Cover;Nesting",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2008 Steve Matson",
    photocredit02: "©2008 Steve Matson",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Berberis thunbergii",
    common_name: "Japanese barberry",
    family_name: "Berberidaceae",
    plantType: "Shrub (deciduous)",
    bloomTime: "Spring",
    flower_color: "Yellow",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Loam;Sand",
    waterNeeds: "Low",
    habitat_value: "Fruit",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©Image by Nick Kurzenko",
    photocredit02: "©2009 James Gaither",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Berberis aquifolium repens",
    common_name: "creeping Oregon grape",
    family_name: "Berberidaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Chaparral;Woodland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Low",
    habitat_value: "Fruit",
    associated_wildlife: "Bees",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2012 Jerry Oldenettel",
    photocredit02: "©2012 Jerry Oldenettel",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Berberis aquifolium",
    common_name: "barberry;Mountain grape",
    family_name: "Berberidaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Winter",
    flower_color: "Yellow",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Woodland",
    suitableSiteConditions: "Sun;Part Shade;Shade",
    soil_type: "Loam;Sand",
    waterNeeds: "Low",
    habitat_value: "Fruit",
    associated_wildlife: "Bees",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2016 Zoya Akulova",
    photocredit02: "©2015 Jean Pawek",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Baccharis salicifolia",
    common_name: "mule-fat",
    family_name: "Asteraceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Summer;Fall;Winter",
    flower_color: "White",
    sizeAtMaturity: "7-12 ft",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    habitat_value: "Cover;Nesting",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2008 Keir Morse",
    photocredit02: "©2008 Keir Morse",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Extriplex californica",
    common_name: "California saltbush",
    family_name: "Chenopodiaceae",
    former_latin_name: "Atriplex californica",
    plantType: "Perennial",
    bloomTime: "Spring",
    flower_color: "Yellow;Pink",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Wetland;Riparian;Coastal scrub",
    suitableSiteConditions: "Sun",
    soil_type: "Clay",
    waterNeeds: "Low",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2009 NatureShutterbug",
    photocredit02: "©2009 NatureShutterbug",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Eurybia radulina",
    common_name: "rough-leaved aster",
    family_name: "Asteraceae",
    former_latin_name: "Aster radulinus",
    plantType: "Perennial",
    bloomTime: "Summer;Fall",
    flower_color: "White",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Woodland",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2013 Barry Breckling",
    photocredit02: "©2013 Barry Breckling",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Asclepias speciosa",
    common_name: "showy milkweed",
    family_name: "Apocynaceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "white;pink",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    associated_wildlife: "butterflies;bees",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2008 Keir Morse",
    photocredit02: "©2008 Keir Morse",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name:
      "Arctostaphylos tomentosa crustacea Arctostaphylos 'Emerald Carpet'",
    common_name: "Lake Merced brittleleaf manzanita",
    family_name: "Ericaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Winter",
    flower_color: "White",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Chaparral",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Loam",
    waterNeeds: "Low",
    associated_wildlife: "Hummingbirds",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2010 John Rusk",
    photocredit02: "©2011 Mathesont",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Alopecurus saccatus",
    common_name: "Pacific foxtail",
    family_name: "Poaceae",
    plantType: "Grass",
    bloomTime: "Spring",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities:
      "Grassland/prairie;Coastal scrub;Chaparral;Wetland;Riparian;Woodland",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam",
    waterNeeds: "Low",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2008 Zoya Akulova",
    photocredit02: "©2008 Zoya Akulova ",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Agrostis hallii",
    common_name: "Hall's bentgrass",
    family_name: "Poaceae",
    plantType: "Grass",
    bloomTime: "Spring;Summer",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Woodland",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "None",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2016 TJ Gehling",
    photocredit02: "©2005 Steve Matson",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Agrostis exarata",
    common_name: "bentgrass",
    family_name: "Poaceae",
    plantType: "Grass",
    bloomTime: "Spring",
    flower_color: "White",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Grassland/prairie;Chaparral;Woodland;Wetland;Riparian",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2008 Keir Morse",
    photocredit02: "©2008 Keir Morse",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Adiantum jordanii",
    common_name: "California maidenhair fern",
    family_name: "Pteridaceae",
    plantType: "Fern",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Grassland/prairie;Woodland;Wetland;Riparian;Chaparral",
    suitableSiteConditions: "Part shade;Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Moderate",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2008 Keir Morse",
    photocredit02: "©2008 Keir Morse",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Banksia integrifolia",
    common_name: "Coastal Banksia",
    family_name: "Proteaceae",
    plantType: "Tree (evergreen)",
    bloomTime: "Spring",
    flower_color: "Yellow",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "clay;Sand",
    waterNeeds: "Moderate",
    habitat_value: "Cover;Nesting",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2016 Zoya Akulova",
    photocredit02: "©2016 Zoya Akulova",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Brachychiton populneum",
    common_name: "Kurrajong, Bottle Tree",
    family_name: "Sterculiaceae",
    plantType: "Tree (evergreen)",
    bloomTime: "Summer",
    flower_color: "White;Yellow",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "clay;Sand",
    waterNeeds: "Moderate",
    habitat_value: "Cover;Nesting",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2016 Zoya Akulova",
    photocredit02: "©2016 Zoya Akulova",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Cassia leptophylla",
    common_name: "Gold Medallion Tree",
    family_name: "Caesalpiniaceae (~Fabales)",
    plantType: "Tree (evergreen)",
    bloomTime: "Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    habitat_value: "Cover;Nesting",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2016 Zoya Akulova",
    photocredit02: "©2016 Zoya Akulova",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Eucalyptus conferruminata - Eucalyptus Lehmanni",
    common_name: "Spider gum, bushy yate",
    family_name: "Myrtaceae",
    plantType: "Tree (deciduous)",
    bloomTime: "Spring;Summer",
    sizeAtMaturity: "13-24 ft",
    climate_appropriate_plants: "Exotic",
    soil_type: "Sand;Loam",
    waterNeeds: "Low",
    habitat_value: "Cover;Nesting",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2006 Steve Matson",
    photocredit02: "©2006 Steve Matson",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Magnolia x soulangeana ‘Rustica Rubra’ and other varieties",
    common_name: "saucer Magnolia",
    family_name: "Magnoliaceae",
    plantType: "Tree (deciduous)",
    bloomTime: "Spring;Summer;Fall",
    flower_color: "Pink;White",
    sizeAtMaturity: "13-24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Shade",
    soil_type: "Clay",
    waterNeeds: "Moderate",
    habitat_value: "Cover;Nesting",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2016 Zoya Akulova",
    photocredit02: "©2016 Zoya Akulova",
    additional_species_cultivars_varieties: "Rustica Rubra and other varieties",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Pittosporum eugenioides",
    common_name: "Lemonwood, Tarata",
    family_name: "Pittosporaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring",
    flower_color: "Yellow",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Shade",
    waterNeeds: "Low",
    habitat_value: "Cover;Nesting",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2016 Zoya Akulova",
    photocredit02: "©2016 Zoya Akulova",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Pittosporum rhombifolium (Auranticarpa rhombifolia)",
    common_name: "Diamond Leaf Pittosporum",
    family_name: "Pittosporaceae",
    plantType: "Tree (evergreen)",
    bloomTime: "Spring;Summer",
    flower_color: "White",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Shade",
    waterNeeds: "Moderate",
    habitat_value: "Cover;Nesting",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2009 Arthur Chapman",
    photocredit02: "©2009 Arthur Chapman",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Platanus x acerifolia ‘Liberty’",
    common_name: "London Plane Tree",
    family_name: "Platanaceae",
    plantType: "Tree (deciduous)",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Moderate",
    habitat_value: "Cover;Nesting",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©1999 U.S. National Arboretum,",
    photocredit02: "©1999 U.S. National Arboretum,",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Quercus frainetto ‘Forest Green’",
    common_name: "Hungarian oak",
    family_name: "Fagaceae",
    plantType: "Tree (deciduous)",
    bloomTime: "Spring",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Clay",
    waterNeeds: "Low",
    habitat_value: "Cover;Nesting",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2012 S.Rae",
    photocredit02: "©2012 Tim Sheerman-Chase",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Quercus ilex",
    common_name: "Holly oak",
    family_name: "Fagaceae",
    plantType: "Tree (evergreen)",
    bloomTime: "Spring;Summer",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    soil_type: "Sand;Loam",
    waterNeeds: "Low",
    habitat_value: "Cover;Nesting;Pollinator",
    associated_wildlife: "Butterflies;Birds",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2016 Zoya Akulova",
    photocredit02: "©2016 Zoya Akulova",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Quercus phellos",
    common_name: "Willow Oak",
    family_name: "Fagaceae",
    plantType: "Tree (deciduous)",
    bloomTime: "Spring;Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam",
    waterNeeds: "Moderate",
    habitat_value: "Cover;Nesting",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2012 Matt Tillet",
    photocredit02: "©2016 Zoya Akulova",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Quercus tomentella",
    common_name: "Island Live Oak",
    family_name: "Fagaceae",
    plantType: "Tree (evergreen)",
    bloomTime: "Spring;Summer",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun;Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "Low",
    habitat_value: "Cover;Nesting;Pollinator",
    associated_wildlife: "Butterflies;Birds",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2014 Steve Matson",
    photocredit02: "©2014 Steve Matson",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Quercus virginiana",
    common_name: "Virginia Live Oak",
    family_name: "Fagaceae",
    plantType: "Tree (evergreen)",
    bloomTime: "Spring",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    habitat_value: "Cover;Nesting",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2016 Zoya Akulova",
    photocredit02: "©2016 Zoya Akulova",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Quillaja saponaria",
    common_name: "Soapbark Tree",
    family_name: "Rosaceae",
    plantType: "Tree (evergreen)",
    bloomTime: "Summer",
    flower_color: "White",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    habitat_value: "Cover;Buds/greens;Nesting",
    associated_wildlife: "Insects",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2016 Zoya Akulova",
    photocredit02: "©2016 Zoya Akulova",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Ulmus japonica x wilsoniana ‘Accolade’",
    common_name: "Accolade Elm",
    family_name: "Ulmaecae",
    plantType: "Tree (deciduous)",
    bloomTime: "Spring",
    flower_color: "Green",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    habitat_value: "Cover;Nesting;Buds/greens;Pollinator",
    associated_wildlife: "Birds",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "http://www.midwestgardentips.com",
    photocredit02: "http://www.midwestgardentips.com",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Ulmus parvifolia x carpinifolia ‘Frontier’",
    common_name: "Frontier Hybrid Elm",
    family_name: "Ulmaceae",
    plantType: "Tree (deciduous)",
    bloomTime: "Fall",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Moderate",
    habitat_value: "Cover;Nesting;Buds/greens",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "http://www.usna.usda.gov",
    photocredit02: "http://www.usna.usda.gov",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Hebe spp.",
    common_name: "Hebe",
    family_name: "Scrophulariaceae",
    plantType: "Shrub (evergreen)",
    flower_color: "Red",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part shade",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Birds;Bees",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2008 Zoya Akulova",
    photocredit02: "©2008 Zoya Akulova",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Helianthemum nummularium",
    common_name: "Sunrose",
    family_name: "Cistaceae",
    plantType: "Shrub (evergreen)",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Birds;Bees",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2006 Luigi Rignanese",
    photocredit02: "©2006 Luigi Rignanese",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Ilex vomitoria",
    common_name: "Yaupon",
    family_name: "Aquifoliaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring",
    flower_color: "White",
    sizeAtMaturity: "7-12 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part shade",
    soil_type: "Clay;Sand;Loam",
    waterNeeds: "Low",
    habitat_value: "Pollinator;Cover;Nesting",
    associated_wildlife: "Birds; Butterflies",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2010 Jean Pawek",
    photocredit02: "©2010 Jean Pawek",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Lantana montevidensis",
    common_name: "Trailing Lantana",
    family_name: "Verbenaceae (Vervains)",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Summer;Fall;Winter",
    flower_color: "Purple",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Birds;Bees;Butterflies",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2010 Moorea Biocode",
    photocredit02: "2012 Mauricio Mercadante",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Larrea tridentata",
    common_name: "Creosote Bush",
    family_name: "Zygophyllaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Summer;Fall;Winter",
    flower_color: "Yellow",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun",
    soil_type: "Many",
    waterNeeds: "None",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2012 Keir Morse",
    photocredit02: "©2012 Keir Morse",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Lavatera  assurgentiflora",
    common_name: "Tree Mallow",
    family_name: "Malvaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Summer;Fall",
    flower_color: "Red",
    sizeAtMaturity: "7-12 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Coastal scrub",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator;Cover;Nesting",
    associated_wildlife: "Birds;Butterflies",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2009 Zoya Akulova",
    photocredit02: "©2009 Zoya Akulova",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Leonotis leonurus",
    common_name: "Lion's Tail",
    family_name: "Lamiaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Summer;Fall;Winter",
    flower_color: "Orange",
    sizeAtMaturity: "13-24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    habitat_value: "Pollinator;Cover;Nesting",
    associated_wildlife: "Birds;Bees",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2016 Simon J. Tonge",
    photocredit02: "©2016 Simon J. Tonge",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Viguiera parishii",
    common_name: "Desert Goldeneye",
    family_name: "Asteraceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2013 Laura Camp",
    photocredit02: "©2013 Laura Camp",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Leucophyllum spp.",
    common_name: "Texas Ranger",
    family_name: "Scrophulariaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Summer;Fall",
    flower_color: "Purple",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Birds;Bees",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2015 Wynn Anderson ",
    photocredit02: "©2015 Wynn Anderson ",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Leucospermum spp.          ",
    common_name: "Pincushion Plant",
    family_name: "Proteaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Winter",
    flower_color: "Red;Orange;Pink",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2000 Derek Keats",
    photocredit02: "©2009 Forest and Kim Starr",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Lupinus spp.",
    common_name: "Lupine",
    family_name: "Fabaceae",
    plantType: "Shrub (evergreen)",
    sizeAtMaturity: "Varies",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Birds;Bees",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©1995 Gary A. Monroe",
    photocredit02: "©1995 Gary A. Monroe",
    additional_species_cultivars_varieties:
      "arboreus, argenteus, albifrons, bicolor, chamissonis, propinquus , variicolor",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Lupinus argenteus",
    common_name: "Silvery Lupine",
    family_name: "Fabaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Summer",
    flower_color: "Blue",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun",
    soil_type: "Loam;Sand",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Birds;Bees;Butterflies",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2011 Jean Pawek",
    photocredit02: "©2011 Jean Pawek",
    habitat: "yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Melianthus major",
    common_name: "Honey Bush",
    family_name: "Melianthaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Winter",
    flower_color: "Magenta",
    sizeAtMaturity: "7-12 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Part Shade",
    waterNeeds: "Low",
    habitat_value: "Cover;Nesting",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2010 Zoya Akulova",
    photocredit02: "©2010 Zoya Akulova",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Nandina domestica",
    common_name: "\tHeavenly Bamboo",
    family_name: "Berberidaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Summer",
    flower_color: "Pink;White",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Part Shade",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2006 Luigi Rignanese",
    photocredit02: "©2006 Luigi Rignanese ",
    additional_species_cultivars_varieties: "Harbour Dwarf, Nana, Nana Dwarf",
    thrifty150: "Yes",
    thrifty150_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Polygala fruticosa ‘petite butterflies'",
    common_name: "Sweet Pea Shrub",
    family_name: "Polygalaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Summer;Fall;Winter",
    flower_color: "Purple",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part shade",
    waterNeeds: "Low",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2012 James Gaither",
    photocredit02: "©2014 Ernest McGray, Jr.",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Prunus lusitanica",
    common_name: "Portugal Laurel",
    family_name: "Rosaceae",
    plantType: "Tree (evergreen)",
    sizeAtMaturity: "13-24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    habitat_value: "Pollinator;Cover;Nesting",
    associated_wildlife: "Birds;Bees;Butterflies",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2008 Josh S. Jackson",
    photocredit02: "©2008 Josh S. Jackson",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Punica granatum 'nana'",
    common_name: "Dwarf Pomegranate",
    family_name: "Lythraceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Summer;Fall;Winter",
    flower_color: "Orange;Red",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2003 Scott Zona",
    photocredit02: "©2015 Georgios Liakopoulos",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Rhaphiolepis indica",
    common_name: "Indian Hawthorne",
    family_name: "Rosaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring",
    flower_color: "White",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2008 Zoya Akulova",
    photocredit02: "©2008 Zoya Akulova",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Rhus integrifolia",
    common_name: "Lemonade Berry",
    family_name: "Anacardiaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring",
    flower_color: "White",
    sizeAtMaturity: "7-12 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Coastal scrub;Dunes;Chaparral",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator;Cover;Nesting",
    associated_wildlife: "Birds",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2013 Keir Morse",
    photocredit02: "©2013 Keir Morse",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Ribes malvaceum",
    common_name: "chaparral currant",
    family_name: "Grossulariaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Winter",
    flower_color: "Pink",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Chaparral;Woodland",
    suitableSiteConditions: "Sun;Part shade",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Birds;Bees;Butterflies",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2012 Jean Pawek",
    photocredit02: "©2011 Vernon Smith",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Sarcococca hookeriana humilis",
    common_name: "Sweet Sarcococca",
    family_name: "Buxaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring",
    flower_color: "White",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Part Shade;Shade",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2012 Megan Hansen",
    photocredit02: "©2012 Leonora (Ellie) Enking",
    thrifty150: "Yes",
    thrifty150_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Teucrium fruticans",
    common_name: "Bush Germander",
    family_name: "Lamiaceae ",
    plantType: "Shrub (evergreen)",
    bloomTime: "Summer;Fall",
    flower_color: "Indigo",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2005 Luigi Rignanese",
    photocredit02: "©2005 Luigi Rignanese",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Trichostema lanatum",
    common_name: "Woolly Blue Curls",
    family_name: "Lamiaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Summer",
    flower_color: "Indigo",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Chaparral",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Birds;Bees",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2009 Keir Morse ",
    photocredit02: "©2009 Keir Morse",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Vitex agnus-castus",
    common_name: "Chaste Tree",
    family_name: "Verbenaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Summer",
    flower_color: "Purple",
    sizeAtMaturity: "7-12 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    habitat_value: "Pollinator;Cover;Nesting",
    associated_wildlife: "Birds",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2016 Zoya Akulova",
    photocredit02: "©2016 Zoya Akulova",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Westringia fruticosa",
    common_name: "Coast Rosemary",
    family_name: "Lamiaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Summer;Fall;Winter",
    flower_color: "White",
    sizeAtMaturity: "7-12 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    habitat_value: "Cover;Nesting",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2009 Drew Avery",
    photocredit02: "©2009 Drew Avery",
    thrifty150: "Yes",
    thrifty150_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Achillea spp.",
    common_name: "Yarrow",
    family_name: "Asteraceae",
    plantType: "Perennial",
    sizeAtMaturity: "Varies",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Birds",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2012 Zoya Akulova",
    photocredit02: "©2011 Zoya Akulova",
    additional_species_cultivars_varieties:
      "millefolium, tomentosa, filipendulina, 'moonshine'",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Anigozanthos spp.",
    common_name: "Kangaroo Paw",
    family_name: "Haemodoraceae",
    plantType: "Perennial",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Birds",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2010 Tony Rodd",
    photocredit02: "©2014 John Rusk",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Arum italicum",
    common_name: "Italian Arum",
    family_name: "Araceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "White",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Part Shade;Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2015 Barry Breckling",
    photocredit02: "©2014 Scott Batiuk",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Ballota pseudodictamnus",
    common_name: "Grecian Horehound",
    family_name: "Lamiaceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "White",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "None",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2015 Zoya Akulova",
    photocredit02: "©2011 Peganum",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Beaucarnea recurvata",
    common_name: "Ponytail palm",
    family_name: "Arecaceae",
    former_latin_name: "Nolina recurvata",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "White",
    sizeAtMaturity: "7-12 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam;Clay",
    waterNeeds: "None",
    habitat_value: "Cover;Nesting",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2011 Forest and Kim Starr",
    photocredit02: "©2009 Forest and Kim Starr",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Brodiaea spp.",
    common_name: "Brodiaea",
    family_name: "Themidaceae",
    plantType: "Perennial",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun",
    soil_type: "Rock",
    waterNeeds: "None",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2016 Steven Thorsted ",
    photocredit02: "©2016 Steven Thorsted",
    thrifty150: "Yes",
    thrifty150_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Calandrinia spectabilis",
    common_name: "Rock Purslane",
    family_name: "Portulacaceae",
    plantType: "Succulent",
    bloomTime: "Spring;Summer",
    flower_color: "Purple",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part shade",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Birds",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2012 Zoya Akulova",
    photocredit02: "©2012 Zoya Akulova",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Erigeron spp.",
    common_name: "Seaside Daisy",
    family_name: "Asteraceae",
    plantType: "Perennial",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2014 Jean Pawek",
    photocredit02: "©2012 Jean Pawek",
    additional_species_cultivars_varieties:
      "karvinskianus, glaucus,  foliosus var. franciscensis",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Dymondia margaretae",
    common_name: "Dymondia",
    family_name: "Asteraceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part shade",
    soil_type: "Sand;Loam",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2007 Tony Rodd",
    photocredit02: "©2009pasiparkkila",
    thrifty150: "Yes",
    thrifty150_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Erigeron karvinskianus",
    common_name: "Latin American fleabane",
    family_name: "Asteraceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "White",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Birds;Bees",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2015 Barry Breckling",
    photocredit02: "©2015 Barry Breckling",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Geranium incanum",
    common_name: "Carpet Geranium",
    family_name: "Geraniaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "Purple",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part shade",
    soil_type: "Sand;Loam",
    waterNeeds: "Low",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2003 Tony Rodd",
    photocredit02: "©2003 Tony Rodd",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Helleborus argutifolius",
    common_name: "Corsican Hellebore",
    family_name: "Ranunculaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Fall",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Part Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "Low",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2008 Zoya Akulova",
    photocredit02: "©2009 James Gaither",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Iberis sempervirens",
    common_name: "Evergreen Candy Tuft",
    family_name: "Brassicaceae",
    plantType: "Perennial",
    bloomTime: "Spring",
    flower_color: "White",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part shade",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies; Birds",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2013 Zoya Akulova",
    photocredit02: "©2011 Melanie J Watts",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Kniphofia uvaria",
    common_name: "Red Hot Poker",
    family_name: "Asphodelaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer;Winter",
    flower_color: "Red",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part shade",
    soil_type: "Sand;Loam",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Birds",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2009 Jorg Fleige",
    photocredit02: "©2014 Kerry Woods",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Lessingia filaginifolia",
    common_name: "California Aster",
    family_name: "Asteraceae",
    plantType: "Perennial",
    bloomTime: "Summer;Fall",
    flower_color: "Purple",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "coastal scrub;dunes",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Birds;butterflies",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2015 Barry Breckling",
    photocredit02: "©2015 John Rusk",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Libertia peregrinans",
    common_name: "Orange Libertia",
    family_name: "Iridaceae",
    plantType: "Perennial",
    bloomTime: "Spring",
    flower_color: "White",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part shade",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2008 Zoya Akulova",
    photocredit02: "©2010 Tony Rodd",
    thrifty150: "Yes",
    thrifty150_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Limonium perezii            ",
    common_name: "Sea Lavender",
    family_name: "Plumbaginaceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "Purple",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "Low",
    associated_wildlife: "Bees",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2016 Vahe Martirosyan",
    photocredit02: "©2016 Vahe Martirosyan",
    habitat: "yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Lobelia laxiflora",
    common_name: "Mexican Lobelia",
    family_name: "Lobelioideae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "red;yellow",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part shade",
    soil_type: "Sand;Loam;Clay",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Birds",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2012 Zoya Akulova",
    photocredit02: "©2012 Zoya Akulova",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Lomandra Longifolia ‘Breeze’",
    common_name: "Lomondra",
    family_name: "Asparagaceae",
    plantType: "Grass",
    bloomTime: "Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part shade",
    waterNeeds: "Low",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2005 Eric Hunt",
    photocredit02: "©2013 Lance",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Lotus berthelotii",
    common_name: "Parrot's Beak",
    family_name: "Fabaceae",
    former_latin_name: "Silene coronaria",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "Red",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part shade",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2010 Zoya Akulova",
    photocredit02: "©2016 John Rusk",
    thrifty150: "Yes",
    thrifty150_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Monardella macrantha",
    common_name: "red monardella",
    family_name: "Lamiaceae",
    plantType: "Perennial",
    bloomTime: "Summer;Fall",
    flower_color: "Red",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Chaparral",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies;birds;bees",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2015 Jean Pawek",
    photocredit02: "©2015 Jean Pawek",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Nepeta x faassenii",
    common_name: "Catmint",
    family_name: "Lamiaceae",
    plantType: "Perennial",
    bloomTime: "Summer;Fall",
    flower_color: "Purple;blue",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part shade",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2011 Andrey Zharkikh",
    photocredit02: "©2012 Andrey Zharkikh",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Oenothera speciosa",
    common_name: "Mexican Evening Primrose",
    family_name: "Onagraceae",
    plantType: "Perennial",
    bloomTime: "Summer;Fall",
    flower_color: "Purple;pink",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part shade",
    soil_type: "Sand;Loam",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2010 Zoya Akulova",
    photocredit02: "©2010 Leonora Ellie Enking",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Osteospermum fruitcosum",
    common_name: "African Daisy",
    family_name: "Asteraceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "White;Pink;Magenta",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2010 Lance",
    photocredit02: "©2010 Pauline Rosenberg",
    thrifty150: "Yes",
    thrifty150_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Penstemon spp.",
    common_name: "Penstemon",
    family_name: "Scrophulariaceae",
    plantType: "Perennial",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part shade",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Birds",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2014 Barry Rice",
    photocredit02: "©2014 Barry Rice",
    additional_species_cultivars_varieties:
      "centranthifolius, heterophyllus,  'midnight', parryi, pinifolius",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Penstemon pinifolius",
    common_name: "Pineneedle Beardtongue",
    family_name: "Scrophulariaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "Orange",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Part Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Birds;butterflies",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2012 Gary A. Monroe",
    photocredit02: "©2012 Gary A. Monroe",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Perovskia atriplicifolia",
    common_name: "Russian Sage",
    family_name: "Lamiaceae",
    plantType: "Perennial",
    bloomTime: "Summer;Fall",
    flower_color: "Blue",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Birds",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2006 Phillip Merritt",
    photocredit02: "©2006 Phillip Merritt",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Phormium tenax",
    common_name: "New Zealand Flax",
    family_name: "Phormiaceae",
    plantType: "Perennial",
    bloomTime: "Spring",
    flower_color: "orange;red",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2010 Forest and Kim Starr",
    photocredit02: "©2010 Forest and Kim Starr",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Puya spp.",
    common_name: "Puya",
    family_name: "Bromeliaceae",
    plantType: "Perennial",
    sizeAtMaturity: "7-12 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "None",
    habitat_value: "Cover;Nesting",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2007 Zoya Akulova",
    photocredit02: "©2011 Leonora (Ellie) Enking",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Romneya coulteri",
    common_name: "Matilija Poppy",
    family_name: "Papaveraceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "White",
    sizeAtMaturity: "7-12 ft",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Cover;Nesting",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2013 Jean Pawek",
    photocredit02: "©2013 Jean Pawek",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Salvia  spp.",
    common_name: "Salvia",
    family_name: "Lamiaceae",
    plantType: "Perennial",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Birds",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2006 Steve Matson",
    photocredit02: "©2006 Steve Matson",
    additional_species_cultivars_varieties:
      "'Bee's Bliss', chamaedryoides, leucophylla, mellifera, uliginosa,  munzii, clevelandii, sonomensis,  'Dara's choice', greggii, greggii 'Alba',greggii 'Salmon', leucantha, spathacea,laevigata,lasiandra,lasiolepis, lutea,apiana,brandegeei,  coahuilensis,",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Sphaeralcea ambigua",
    common_name: "Globe Mallow",
    family_name: "Malvaceae",
    plantType: "Perennial",
    bloomTime: "Spring",
    flower_color: "Orange",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Birds;bees;butterflies",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2016 Steven Thorsted",
    photocredit02: "©2016 Steven Thorsted",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Tanacetum parthenium",
    common_name: "Feverfew",
    family_name: "Asteraceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "White",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "Low",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2014 Richard Spellenberg",
    photocredit02: "©2014 Richard Spellenberg",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Aeonium spp.",
    common_name: "Canary Island Rose",
    family_name: "Crassulaceae",
    plantType: "Shrub (evergreen)",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part shade",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2016 Zoya Akulova",
    photocredit02: "©2016 Zoya Akulova",
    additional_species_cultivars_varieties: "arboreum, haworthii",
    thrifty150: "Yes",
    thrifty150_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Agave spp.",
    common_name: "Agave",
    family_name: "Agavaceae",
    plantType: "Shrub (evergreen)",
    sizeAtMaturity: "Varies",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun;Part shade",
    waterNeeds: "None",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2015 Zoya Akulova ",
    photocredit02: "©2015 Zoya Akulova",
    additional_species_cultivars_varieties:
      "attenuata, americana, desmettiana, vilmoriniana, x. 'Blue Flame', x. 'Blue Glow',bracteosa 'Mediopicta Alba'",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Aloe spp.",
    common_name: "Aloe",
    family_name: "Aloeaceae",
    plantType: "Shrub (evergreen)",
    sizeAtMaturity: "Varies",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part shade",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Birds",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2012 Zoya Akulova ",
    photocredit02: "©2013 Zoya Akulova",
    additional_species_cultivars_varieties:
      "arborescens, brevifolia, 'Johnson's hybrid', striata",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Blechnum spicant",
    common_name: "Deer Fern",
    family_name: "Blechnaceae",
    plantType: "Fern",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Part Shade;Shade",
    soil_type: "Loam;sand",
    waterNeeds: "Low",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2016 Zoya Akulova",
    photocredit02: "©2016 Zoya Akulova",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Bulbine frutescens",
    common_name: "Stalked bulbine",
    family_name: "Asphodelaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Part Shade",
    waterNeeds: "Low",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2010 Zoya Akulova",
    photocredit02: "©2009 Forest and Kim Starr",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Bougainvillea spp.",
    common_name: "Bougainvillea",
    family_name: "Nyctaginaceae",
    plantType: "Vine",
    sizeAtMaturity: "13-24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    habitat_value: "Cover;Nesting",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2012 Zoya Akulova",
    photocredit02: "©2012 Zoya Akulova",
    additional_species_cultivars_varieties: "'Barbara Karst'",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Calystegia macrostegia",
    common_name: "California Morning Glory",
    family_name: "Convolvulacea",
    plantType: "Vine",
    bloomTime: "Spring;Summer",
    sizeAtMaturity: "13-24 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "coastal scrub;chaparral",
    suitableSiteConditions: "Sun",
    soil_type: "Loam;sand",
    waterNeeds: "None",
    habitat_value: "Cover;Nesting",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2013 Zoya Akulova",
    photocredit02: "©2013 Zoya Akulova",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Chamaerops humilis",
    common_name: "Mediterranean Fan Palm ",
    family_name: "Arecaceae",
    plantType: "Palm",
    flower_color: "Yellow",
    sizeAtMaturity: "13-24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    waterNeeds: "Low",
    habitat_value: "Cover;Nesting",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2005 Luigi Rignanese",
    photocredit02: "©2005 Luigi Rignanese",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Clematis spp.",
    common_name: "Clematis",
    family_name: "Ranunculaceae",
    plantType: "Vine",
    sizeAtMaturity: "7-12 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    habitat_value: "Pollinator;Cover;Nesting",
    associated_wildlife: "Birds",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2008 Mike Ireland",
    photocredit02: "©2008 Mike Ireland",
    additional_species_cultivars_varieties:
      "lasiantha, armandii, ligusticifolia",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Cotyledon orbiculata      ",
    common_name: "Cotyledon, Pigs Ears",
    family_name: "Crassulaceae",
    plantType: "Succulent",
    bloomTime: "Summer",
    flower_color: "Orange",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2011 FarOutFlora",
    photocredit02: "©2011 FarOutFlora",
    thrifty150: "Yes",
    thrifty150_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Delosperma cooperi",
    common_name: "Cooper's Ice Plant",
    family_name: "Aizoaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Summer;Fall",
    flower_color: "Magenta",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Sand",
    waterNeeds: "Low",
    habitat_value: "Cover",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2005 Luigi Rignanese",
    photocredit02: "©2005 Luigi Rignanese",
    thrifty150: "Yes",
    thrifty150_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Echeveria elegans",
    common_name: "Hens and Chicks",
    family_name: "Crassulaceae",
    plantType: "succulent",
    bloomTime: "Spring;summer",
    flower_color: "Pink",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2010 Zoya Akulova",
    photocredit02: "©2014 Zoya Akulova",
    thrifty150: "Yes",
    thrifty150_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Graptopetalum paraguayense",
    common_name: "Ghost Plant",
    family_name: "Crassulaceae",
    plantType: "succulent",
    bloomTime: "Spring",
    flower_color: "White",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    waterNeeds: "None",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2015 Zoya Akulova",
    photocredit02: "©2015 Zoya Akulova",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Gomphrena globosa",
    common_name: "Globe Amaranth",
    family_name: "Amaranthaceae",
    plantType: "Annual",
    bloomTime: "Summer;Fall",
    flower_color: "Magenta",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part shade",
    soil_type: "Clay;Sand;Loam",
    waterNeeds: "Low",
    associated_wildlife: "Butterflies",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2010 Moorea Biocode ",
    photocredit02: "©2009 Forest and Kim Starr",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Helicotrichon sempervirons",
    common_name: "Blue Oat Grass",
    family_name: "Poaceae",
    plantType: "Grass",
    bloomTime: "Summer",
    flower_color: "Blue;Brown",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    habitat_value: "Cover",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2009 Drew Avery",
    photocredit02: "©2011 Andrey Zharkikh",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Lampranthus spectabilis",
    common_name: "Trailing Ice Plant",
    family_name: "Aizoaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;summer",
    flower_color: "Magenta;Pink;Purple",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2007 Leonora (Ellie) Enking",
    photocredit02: "©2005 Spiegel",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Elymus condensatus",
    common_name: "Giant Wild Rye",
    family_name: "Poaceae",
    plantType: "Grass",
    bloomTime: "Summer",
    flower_color: "White",
    sizeAtMaturity: "7-12 ft",
    climate_appropriate_plants: "SF Native",
    suitableSiteConditions: "Sun;Part shade",
    soil_type: "Clay;loam",
    waterNeeds: "None",
    habitat_value: "Cover;Nesting",
    appropriateLocation: "Garden;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2013 Peggy A. Lopipero-Langmo",
    photocredit02: "©2013 John Rusk",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Rhodocoma arida",
    common_name: "Rhodocoma",
    family_name: "Restionaceae",
    plantType: "Grass",
    bloomTime: "Fall;Winter",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    waterNeeds: "Low",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2007Eric Hunt",
    photocredit02: "©2015 NickyVB",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Rosa banksiae",
    common_name: "Lady Banks Rose",
    family_name: "Rosaceae",
    plantType: "Vine",
    bloomTime: "Spring",
    flower_color: "Yellow",
    sizeAtMaturity: "13-24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    habitat_value: "Pollinator;Cover;Nesting",
    associated_wildlife: "Birds",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2011 Stefano",
    photocredit02: "©2006 Andreas Phillip",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Sedum spp.",
    common_name: "Stonecrop",
    family_name: "Crassulaceae",
    plantType: "Shrub (evergreen)",
    sizeAtMaturity: "Varies",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun;Part Shade",
    waterNeeds: "Low",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2015 Zoya Akulova",
    photocredit02: "©2013 Zoya Akulova",
    additional_species_cultivars_varieties:
      "x. rubrotinctum, spectabile,dendroideum,  pachyphyllum, palmeri, spathulifolium",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Senecio spp.",
    common_name: "Blue Chalk Stalks",
    family_name: "Asteraceae",
    plantType: "Shrub (evergreen)",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    waterNeeds: "Low",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2005 Robert Sivinski",
    photocredit02: "©2005 Robert Sivinski",
    additional_species_cultivars_varieties:
      "mandraliscae, serpens, vitalis, amaniensis,  cineraria, hydrophilus, scaposus",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Yucca spp.",
    common_name: "Bright Star Yucca",
    family_name: "Agavaceae",
    plantType: "Shrub (evergreen)",
    flower_color: "Yellow;White",
    sizeAtMaturity: "Varies",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun",
    waterNeeds: "None",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2010 Megan Hansen",
    photocredit02: "©2012 FarOutFlora",
    additional_species_cultivars_varieties:
      "'Bright Star', filamentosa, recurvifolia",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Ribes spp.",
    common_name: "Red flowering currant",
    family_name: "Grossulariaceae",
    plantType: "Shrub (evergreen)",
    flower_color: "Purple;Pink;White;Yellow;Red",
    sizeAtMaturity: "7-12 ft",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun;Part Shade",
    waterNeeds: "Low",
    habitat_value: "Pollinator;Cover;Nesting",
    associated_wildlife: "Birds",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2006 Steve Matson",
    photocredit02: "©2006 Steve Matson",
    additional_species_cultivars_varieties:
      "aureum, sanguineum, malvaceum, menziesii, sanguineum var. glutinosum,  speciosum",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Eriogonum spp.",
    common_name: "California Buckwheat",
    family_name: "Polygonaceae",
    plantType: "Perennial",
    flower_color: "White;Pink;Purple;Yellow",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Birds",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2005 Keir Morse",
    photocredit02: "©2009 Keir Morse",
    additional_species_cultivars_varieties:
      "fasciculatum, latifolium, giganteum,grande rubescens ,nudum, parvifolium, umbellatum",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Achillea clavennae",
    common_name: "Silver yarrow",
    family_name: "Asteraceae",
    plantType: "Perennial",
    bloomTime: "Summer;Fall",
    flower_color: "White",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies;bees;birds",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2010 Hermann Falkner",
    photocredit02: "©2010 Hermann Falkner",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Aeonium arboreum varieties",
    common_name: "Tree aenium",
    family_name: "Crassulaceae",
    plantType: "Succulent",
    bloomTime: "Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2013 Zoya Akulova",
    photocredit02: "©2011 Zoya Akulova",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Aeonium decorum varieties",
    common_name: "Green pinwheel",
    family_name: "Crassulaceae",
    plantType: "Succulent",
    bloomTime: "Summer",
    flower_color: "Pink",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2013 Salchuiwt",
    photocredit02: "©2007 Mike Keeling",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Agapanthus hybrids",
    common_name: "Lily-of-the-Nile",
    family_name: "Liliaceae",
    plantType: "Perennial",
    bloomTime: "Summer;Fall",
    flower_color: "Blue",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Sand;Loam;Clay",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies;Bees",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2012 Gary Churchman",
    photocredit02: "©2012 Gary Churchman",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Agave bracteosa 'Mediopicta Alba'",
    common_name: "'Mediopicta Alba' Squid",
    family_name: "Agavaceae",
    plantType: "Succulent",
    flower_color: "Yellow;Green",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2003 Carlos Velazco",
    photocredit02: "©2010 Robyn Turner",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Agave attenuata 'Nova'",
    common_name: "Nova",
    family_name: "Agavaceae",
    plantType: "Succulent",
    flower_color: "Yellow;Green",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade;Shade",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2009 Enbodenumer",
    photocredit02: "©2009 Enbodenumer",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Aloe 'Johnson's hybrid'",
    common_name: "'Johnson's hybrid'",
    family_name: "Aloeaceae",
    plantType: "Succulent",
    bloomTime: "Spring;Summer;Fall;Winter",
    flower_color: "Orange",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Loam;sand",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "www.smgrowers.com",
    photocredit02: "www.smgrowers.com",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Anemone x hybrida",
    common_name: "Japanese Anemon",
    family_name: "Ranunculaceae",
    plantType: "Perennial",
    bloomTime: "Summer;Fall",
    flower_color: "Pink",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2007 Tim Waters",
    photocredit02: "©2011 Andrey Zharkikh",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Anigozanthos hybrids",
    common_name: "Kangaroo Paw",
    family_name: "Haemodoraceae",
    plantType: "Perennial",
    flower_color: "Red;Orange;Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Sand",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2010 Tony Rodd",
    photocredit02: "©2014 John Rusk",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Arctostaphylos uva ursi 'Radiant'",
    common_name: "Radiant Bearberry",
    family_name: "Ericaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Winter",
    flower_color: "Pink",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Many",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies;Bees;Hummingbirds",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2011 FarOutFlora",
    photocredit02: "©2007 Cliff",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Artemisia 'Powis Castle'",
    common_name: "Silver Sage",
    family_name: "Asteraceae",
    plantType: "Perennial",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies;Bees",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2006 Patrick Standish",
    photocredit02: "©2007 Patrick Standish",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Artemisia pycnocephala 'David's Choice '",
    common_name: "Sandhill sage",
    family_name: "Asteraceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun",
    waterNeeds: "None",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2010 Jacki-dee",
    photocredit02: "©2010 CountryMouse13",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Bouteloua dactyloides",
    common_name: "Buffalo Grass",
    family_name: "Poaceae",
    plantType: "Perennial",
    bloomTime: "Spring",
    flower_color: "Brown",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "clay;loam",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2011 Andrey Zharkikh",
    photocredit02: "©2011 Andrey Zharkikh",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Calamagrostis foliosa",
    common_name: "Cape Mendicino Reed Grass",
    family_name: "Poaceae",
    plantType: "Perennial",
    bloomTime: "Summer;Fall",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Coastal scrub;Woodland",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2011 Zoya Akulova",
    photocredit02: "©2011 Zoya Akulova",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Carex buchananii",
    common_name: "Leather leaf sedge",
    family_name: "Cyperaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Summer",
    flower_color: "Brown",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    waterNeeds: "Moderate",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2012 Forest and Kim Starr",
    photocredit02: "©2012 Forest and Kim Starr",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Carex texensis",
    common_name: "Catlin sedge",
    family_name: "Cyperaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Summer",
    flower_color: "Green",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Part shade;Shade",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2003 Steve Matson",
    photocredit02: "©2003 Steve Matson",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Ceanothus griseus horizontalis",
    common_name: "Carmel creeper",
    family_name: "Rhamnaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Winter",
    flower_color: "Blue",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies;Bees;Hummingbirds",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2013 Peggy A. Lopipero-Langmo",
    photocredit02: "©2013 Peggy A. Lopipero-Langmo",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Ceanothus thyrsiflorus repens",
    common_name: "Low Blue Blossom",
    family_name: "Rhamnaceae",
    plantType: "Shrub (evergreen)",
    flower_color: "Blue",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;loam;Sand",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies;Bees;Hummingbirds",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2013 John Rusk",
    photocredit02: "©2015 John Rusk",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Cistus 'Sunset'",
    common_name: "Magenta rockrose",
    family_name: "Cistaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Summer",
    flower_color: "Magenta",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2014 Leonora (Ellie) Enking",
    photocredit02: "©2010 Leonora (Ellie) Enking",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Clivia miniata",
    common_name: "Orange Clivia",
    family_name: "Amaryllidaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer;Fall;Winter",
    flower_color: "Yellow;orange",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Part shade;Shade",
    appropriateLocation: "Garden;Potted Plant;Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2014 Zoya Akulova",
    photocredit02: "©2014 Zoya Akulova",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Convolvulus cneorum",
    common_name: "Bush morninglory",
    family_name: "Convolvulacea",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Summer;Fall",
    flower_color: "White",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2005 Eric Hunt",
    photocredit02: "©2003 Peganum",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Coprosma petriei  'Verde Vista'",
    common_name: "Creeping coprosma",
    family_name: "Rubiaceae",
    plantType: "Shrub (evergreen)",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    waterNeeds: "None",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "www.contracosta.watersavingplants.com",
    photocredit02: "©2013 Daderot",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Cordyline 'Electric Pink'",
    common_name: "Electric Pink Grass Tree",
    family_name: "Dracaenaceae",
    plantType: "Perennial",
    bloomTime: "Spring",
    flower_color: "White",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2012 FarOutFlora",
    photocredit02: "©2015 Mr&Mrs Stickyfingers",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Correa 'Dusky Bells'",
    common_name: "Australian fuschia",
    family_name: "Rutaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring",
    flower_color: "Pink;Red",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade;Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "Low",
    associated_wildlife: "Hummingbirds",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2015 Jim Hoffman",
    photocredit02: "©2009 Percita",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Correa 'Ivory Bells'",
    common_name: "Australian fuschia",
    family_name: "Rutaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring",
    flower_color: "White",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "Low",
    associated_wildlife: "Hummingbirds",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2004 The Ruth Bancroft Garden",
    photocredit02: "©2008 James Gaither",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Cotoneaster dammeri 'LowFast'",
    common_name: "Bearberry Cotoneaster",
    family_name: "Rosaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring",
    flower_color: "White",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade;Shade",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2009 Andrey Zharkikh",
    photocredit02: "©2009 Andrey Zharkikh",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Crassula ovata",
    common_name: "Jade Plant",
    family_name: "Crassulaceae",
    plantType: "Succulent",
    bloomTime: "Spring;Winter",
    flower_color: "White",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2013 Zoya Akulova",
    photocredit02: "©2013 Zoya Akulova",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Crassula multicava",
    common_name: "Cape Province Pygmyweed",
    family_name: "Crassulaceae",
    plantType: "Succulent",
    bloomTime: "Winter",
    flower_color: "Pink;White",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade;Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2012 Dean Wm. Taylor, Ph.D.",
    photocredit02: "©2012 Dean Wm. Taylor, Ph.D.",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Cyclamen hederifolium",
    common_name: "Persian Violet",
    family_name: "Primulaceae",
    plantType: "Perennial",
    bloomTime: "Summer;Fall",
    flower_color: "Pink",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade;Shade",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2005 Luigi Rignanese",
    photocredit02: "©2005 Luigi Rignanese",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Dianella tasmanica variegata",
    common_name: "Flax Lily",
    family_name: "Phormiaceae",
    plantType: "Grass",
    bloomTime: "Spring",
    flower_color: "Blue",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2008 Richard Parker",
    photocredit02: "©2013 Allison Duell",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Dietes bicolor",
    common_name: "Fortnight Lily",
    family_name: "Iridaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Fall",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "Low;Moderate",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2012 Zoya Akulova",
    photocredit02: "©2006 Nathan Gibbs",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Dietes iridioides",
    common_name: "African Iris",
    family_name: "Iridaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Fall",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2012 Alpha",
    photocredit02: "©2010 Erick Lux",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Distictis buccinatoria",
    common_name: "Blood Red Trumpet Vine",
    family_name: "Bignoniaceae",
    plantType: "Vine",
    bloomTime: "Summer",
    flower_color: "Red",
    sizeAtMaturity: "13-24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    habitat_value: "Cover;Nesting",
    associated_wildlife: "Hummingbirds",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2010 Bri Weldon",
    photocredit02: "©2005 Bri Weldon",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Drosanthemum floribundum",
    common_name: "Rosea Ice Plant",
    family_name: "Aizoaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "Pink",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2015 Zoya Akulova",
    photocredit02: "©2015 Zoya Akulova",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Dryas octopetala",
    common_name: "Mountain Dryad",
    family_name: "Rosaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "White",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies;bees;birds",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2015 Barry Breckling",
    photocredit02: "©2015 Barry Breckling",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Echeveria agavoides 'Maria'",
    common_name: "Wax Agave",
    family_name: "Crassulaceae",
    plantType: "Succulent",
    bloomTime: "Spring;Summer",
    flower_color: "Yellow;red",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    waterNeeds: "Low",
    appropriateLocation: "Garden;Sidewalk;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2012 JD_Succulent",
    photocredit02: "©2014 Zoya Akulova",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Echeveria 'Blue Bedder'",
    family_name: "Crassulaceae",
    plantType: "Succulent",
    bloomTime: "Spring;Winter",
    flower_color: "Orange",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "www.smgrowers.com",
    photocredit02: "www.budgetplants.com/shrubs",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Echeveria harmsii",
    common_name: "Echeveria",
    family_name: "Crassulaceae",
    plantType: "Succulent",
    bloomTime: "Spring;Summer;Fall;Winter",
    flower_color: "Orange",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    waterNeeds: "Low",
    associated_wildlife: "Butterflies;bees;birds",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2012 Zoya Akulova",
    photocredit02: "©2012 Zoya Akulova",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Echeveria imbricata",
    common_name: "Hens and Chicks",
    family_name: "Crassulaceae",
    plantType: "Succulent",
    bloomTime: "Spring;Summer",
    flower_color: "red;yellow",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    waterNeeds: "Low",
    appropriateLocation: "Garden;Sidewalk;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2012 Zoya Akulova",
    photocredit02: "©2005 LynnK827",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Echeveria juarezensis",
    common_name: "Juareze Hens and Chicks",
    family_name: "Crassulaceae",
    plantType: "Succulent",
    bloomTime: "summer",
    flower_color: "Pink",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "www.smgrowers.com",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Elymus magellanicus",
    common_name: "Blue Wheatgrass, Magellan Wheatgrass",
    family_name: "Poaceae",
    plantType: "Grass",
    bloomTime: "Spring;Summer",
    flower_color: "Blue",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "Low",
    associated_wildlife: "Butterflies",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2015 Matt Lavin",
    photocredit02: "©2015 Matt Lavin",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Epilobium septentrionale",
    common_name: "Humboldt County Fuchsia",
    family_name: "Onagraceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "Red",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "Low",
    associated_wildlife: "Hummingbirds",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "© BLM,Arcata Field Office",
    photocredit02: "© BLM,Arcata Field Office",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Eriogonum grande rubescens  ",
    common_name: "Red buckwheat",
    family_name: "Polygonaceae",
    plantType: "Perennial",
    bloomTime: "Summer;Fall",
    flower_color: "Pink",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam;Clay",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies;Bees",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2011 FarOutFlora",
    photocredit02: "©2011 Mathesont",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Euphorbia amygdaloides",
    common_name: "wood spurge",
    family_name: "Euphorbiaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Part Shade",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2013 Dean Morley",
    photocredit02: "©2011 Manuel",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Euphorbia x. martinii",
    family_name: "Euphorbiaceae",
    plantType: "Perennial",
    bloomTime: "Spring",
    flower_color: "Green",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    waterNeeds: "Moderate",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2013 FarOutFlora",
    photocredit02: "©2010 emily jmat",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Graptosedum 'Vera Higgins'",
    family_name: "Crassulaceae",
    plantType: "Succulent",
    bloomTime: "Spring;Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2010 Megan Hansen",
    photocredit02: "©2010 Stephen Boisvert",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Graptoveria 'Fred Ives'",
    family_name: "Crassulaceae",
    plantType: "Succulent",
    bloomTime: "Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2015 Rosa Say",
    photocredit02: "©2015 Rosa Say",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Graptoveria 'Debbi'",
    family_name: "Crassulaceae",
    plantType: "Succulent",
    bloomTime: "Spring;Summer;Winter",
    flower_color: "Magenta",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2016 Salchuiwt",
    photocredit02: "©2016 Salchuiwt",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Grevillea lanigera 'Coastal Gem'",
    family_name: "Proteaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Summer;Fall;Winter",
    flower_color: "Pink;White",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Birds",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2011 FarOutFlora",
    photocredit02: "©2011 FarOutFlora",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Grevillea 'Magic Lantern'",
    family_name: "Proteaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Fall",
    flower_color: "Red",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Sand",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2013 MomentsforZen",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Hardenbergia violacea",
    common_name: "Lilac Vine",
    family_name: "Fabaceae",
    plantType: "Vine",
    bloomTime: "Spring;Winter",
    flower_color: "Purple",
    sizeAtMaturity: "7-12 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    waterNeeds: "Low",
    habitat_value: "Cover;Nesting",
    associated_wildlife: "Bees",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2016 Clare Snow",
    photocredit02: "©2010 Brent Miller",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Helleborus orientalis",
    common_name: "Lenten Rose",
    family_name: "Ranunculaceae",
    plantType: "Perennial",
    bloomTime: "Spring",
    flower_color: "White;pink",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    waterNeeds: "Low;Moderate",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2013 Zoya Akulova",
    photocredit02: "©2014 Zoya Akulova",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Helianthemum 'Apricot'",
    common_name: "Apricot Sunrose",
    family_name: "Cistaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring",
    flower_color: "Orange",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "www.smgrowers.com",
    photocredit02: "www.smgrowers.com",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Helianthemum 'Belgravia Rose'",
    family_name: "Cistaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring",
    flower_color: "Purple;pink",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "www.smgrowers.com",
    photocredit02: "www.diggingdog.com",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Heuchera maxima",
    common_name: "Island Alum Root",
    family_name: "Saxifragaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "White",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "coastal scrub;chaparral",
    suitableSiteConditions: "Part shade;Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Hummingbirds",
    appropriateLocation: "Sidewalk;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2014 Steve Matson",
    photocredit02: "©2013 Gary A. Monroe",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Heuchera 'Santa Ana Cardinal'",
    common_name: "Coral Bell hybrids",
    family_name: "Saxifragaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "Pink",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Part shade;Shade",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Hummingbirds",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "www.smgrowers.com",
    additional_species_cultivars_varieties: "'Wendy'",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Heuchera sanguinea ",
    common_name: "Coral Bells",
    family_name: "Saxifragaceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "Red;Oink",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Part shade;Shade",
    soil_type: "Rock",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Hummingbirds",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2004 Robert Sivinski",
    photocredit02: "©2013 Gail Hampshire",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Hyacinthoides hispanica",
    common_name: "Spanish squill",
    family_name: "Asparagaceae",
    plantType: "Perennial",
    bloomTime: "Spring",
    flower_color: "Purple",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade;Shade",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2004 Tony Hammond",
    photocredit02: "©2014 Tony Hammond",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Juncus effusus var. brunneus",
    common_name: "Green Rush",
    family_name: "Juncaceae",
    former_latin_name: "Juncus hesperius",
    plantType: "Grass",
    bloomTime: "Summer",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Riparian;wetland",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "Moderate",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2008 Terrie Schweitzer",
    photocredit02: "©2014C.Michael Hogan",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Kalanchoe orgyalis",
    common_name: "Copper Spoons",
    family_name: "Crassulaceae",
    plantType: "Succulent",
    bloomTime: "Winter",
    flower_color: "Yellow",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2009 Zoya Akulova",
    photocredit02: "©2009 Zoya Akulova",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Kalanchoe thyrsiflora",
    common_name: "Paddle Plant",
    family_name: "Crassulaceae",
    plantType: "Succulent",
    bloomTime: "Spring;Winter",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2010 Megan Hansen",
    photocredit02: "©2010 Megan Hansen",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Phacelia minor",
    common_name: "California bluebell",
    family_name: "Boraginaceae",
    plantType: "Annual",
    bloomTime: "Spring",
    flower_color: "Indigo",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun",
    soil_type: "Loam;Sand",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2007 Debra Hansen",
    photocredit02: "©2009 Barry Breckling",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Calamintha spp.",
    common_name: "Calamint",
    family_name: "Lamiaceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Shade",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2010 Pixeltoo",
    photocredit02: "©2011 Elizabeth Sellers",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Caryopteris x clandonensis",
    common_name: "Blue Mist, Blue Beard",
    family_name: "Lamiaceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "Blue",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2007 John Brandauer",
    photocredit02: "©2010 pris.sears",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Duranta erecta",
    common_name: "Skyflower",
    family_name: "Verbenaceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "purple;white;blue",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Shade",
    soil_type: "Loam;Sand;Clay",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2015 Zoya Akulova",
    photocredit02: "©2010 Moorea Biocode",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Echinacea purpurea",
    common_name: "Purple Coneflower",
    family_name: "Asteraceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "pink;purple",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Shade",
    soil_type: "Loam;Sand;Clay",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies;bees;birds",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2012 Zoya Akulova",
    photocredit02: "©2015 Barry Breckling",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Eriogonum nudum",
    common_name: "Naked Buckwheat",
    family_name: "Polygonaceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "white;yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Woodland;Chaparral;Grassland",
    suitableSiteConditions: "Sun",
    soil_type: "Loam;Sand",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies;bees",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2013 Jean Pawek",
    photocredit02: "©2013 Jean Pawek",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Gaillardia 'Oranges & Lemons'",
    common_name: "Oranges & Lemons",
    family_name: "Asteraceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "Orange;Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Loam;Sand",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies;bees",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2006 Sheri Ann Richerson",
    photocredit02: "©2011 FarOutFlora",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Helenium 'Mardi Gras'",
    common_name: "Mardi Gras",
    family_name: "Asteraceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "Orange;Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Loam;Sand;Clay",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "hummingbirds;Butterflies;bees",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2012 Alwyn Lydell",
    photocredit02: "©2012 Alwyn Lydell",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Madia elegans densiflora",
    common_name: "Elegant Madia",
    family_name: "Asteraceae",
    plantType: "Annual",
    bloomTime: "Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Woodland",
    suitableSiteConditions: "Sun;Shade",
    soil_type: "Loam;Sand",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2009 Clarire Woods",
    photocredit02: "©2012 J. Maughn",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Mentha spicata",
    common_name: "Spearmint",
    family_name: "Lamiaceae",
    plantType: "Perennial",
    bloomTime: "Summer;Fall",
    flower_color: "White",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    soil_type: "Loam;Sand",
    waterNeeds: "Moderate",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2008 Keir Morse",
    photocredit02: "©2008 Keir Morse",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Monardella odoratissima",
    common_name: "Mountain Monardella",
    family_name: "Lamiaceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "White",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Woodland",
    suitableSiteConditions: "Sun",
    soil_type: "Loam;Sand",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "hummingbirds;Butterflies;bees",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2010 Jean Pawek",
    photocredit02: "©2010 Jean Pawek",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Scabiosa atropurpurea",
    common_name: "Pincushion flower",
    family_name: "Dipsacaceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "Purple",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Loam;Sand",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies;bees",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2012 Zoya Akulova",
    photocredit02: "©2012 Zoya Akulova",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Eriodictyon californicum",
    common_name: "California yerba santa,  Yerba santa ",
    family_name: "Boraginaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Summer",
    flower_color: "White;Purple",
    sizeAtMaturity: "7-12 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities:
      "Chaparral;Foothill Woodland;Yellow Pine Forest;Northern Oak Woodland;Mixed Evergreen Forest;Redwood Forest",
    suitableSiteConditions: "Sun",
    soil_type: "Loam;Sand",
    waterNeeds: "None",
    habitat_value: "Pollinator;Cover;Nesting",
    associated_wildlife: "Bees",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2009 Barry Breckling",
    photocredit02: "©2009 Barry Breckling",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Oemleria cerasiformis",
    common_name: "Indian plum,  Oso berry ",
    family_name: "Rosaceae",
    plantType: "Shrub (deciduous)",
    bloomTime: "Spring;Summer",
    flower_color: "White",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Coastal scrub",
    suitableSiteConditions: "Sun",
    soil_type: "Loam;Sand",
    waterNeeds: "Low",
    habitat_value: "Fruit;pollinator",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2007 Steve Matson ",
    photocredit02: "©2007 Steve Matson",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Baccharis pilularis 'Twin Peaks'",
    common_name: "Coyotebrush prostrate form",
    family_name: "Asteraceae",
    plantType: "Shrub (evergreen)",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Coastal scrub",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand;Rock",
    waterNeeds: "None",
    habitat_value: "Buds/greens;Pollinator;Nesting",
    associated_wildlife: "Bees;Birds;Butterflies;Insects",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden;Sidewalk;Roof",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2008 Ken Gilliland",
    photocredit02: "©2010 Arvind Kumar",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Achillea filipendulina",
    common_name: "Fernleaf Yarrow",
    family_name: "Asteraceae",
    plantType: "Perennial",
    bloomTime: "Summer;Fall",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand;Rock",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2013 Cristina\n2013 Cristina",
    photocredit02: "©2012 Cristina",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Arctotis hybrids",
    common_name: "\tAfrican Daisy",
    family_name: "Asteraceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "Pink",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2007 Keth Daly\n2007 Keith Daly",
    photocredit02: "©2010 Natalie Tapson",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Festuca amethystina",
    common_name: "tufted fescue",
    family_name: "Poaceae",
    plantType: "Grass",
    bloomTime: "Summer",
    flower_color: "Green",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2005 Stan Shebs",
    photocredit02: "©2014 Carolyn Willitts\n2014 Carolyn Willitts",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Cedrela fissilis",
    common_name: "Brazilian Cedarwood  \nBrazilian Cedarwood",
    family_name: "Meliaceae",
    plantType: "Tree (deciduous)",
    bloomTime: "Spring;Summer",
    flower_color: "Yellow;Green",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Shade",
    waterNeeds: "Moderate",
    habitat_value: "Cover;Nesting",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2008 Mauroguanandi",
    photocredit02: "©2009 Mauroguanandi",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Amaryllis belladonna",
    common_name: "Naked Lady",
    family_name: "Amaryllidaceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "Pink;Purple",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2014 Zoya Akulova",
    photocredit02: "©2009 Erick Lux\n2010 Erick Lux",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Sidalcea malviflora",
    common_name: "Checkerbloom",
    family_name: "Malvaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "Pink",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native ",
    plant_communities: "Grassland/prairie",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "clay;loam",
    waterNeeds: "None",
    habitat_value: "Buds/greens;Pollinator",
    associated_wildlife: "Bees;Butterflies",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden;Roof",
    suggested_green_connection_routes: "16",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "Attracts West Coast Painted Lady (Vanessa annabella).",
    photocredit01: "©2010 Gary A. Monroe (CC BY-NC 3.0)",
    photocredit02: "©2010 John J. Kehoe (CC BY 3.0)",
    photocredit03:
      "2010 Gary A. Monroe (CC BY-NC 3.0)\n 2010 Gary A. Monroe (CC BY-NC 3.0)\n ©2010 Gary A. Monroe (CC BY-NC 3.0)\n ©2010 Gary A. Monroe (CC BY-NC 3.0)\n ©2010 Gary A. Monroe (CC BY-NC 3.0)",
    photocredit04: "©2011 Jean Pawek ",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Erysimum  'Bowles Mauve'",
    common_name: "Bowles Mauve Wallflower",
    family_name: "Brassicaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer;Fall",
    flower_color: "Purple",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies;birds",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2012 Leonora Enking\n2012 Leonora (Ellie) Enking",
    photocredit02: "©2012 Leonora Enking\n2012 Leonora (Ellie) Enking",
    thrifty150: "Yes",
    thrifty150_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Phlomis fruticosa",
    common_name: "\tJerusalem Sage",
    family_name: "Lamiaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2006 Hans Hillewaert\n2006 Hans Hillewaert",
    photocredit02: "©2014 Kenneth",
    thrifty150: "Yes",
    thrifty150_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Pelargonium peltatum",
    common_name: "Ivy Geranium",
    family_name: "Geraniaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer;Fall;Winter",
    flower_color: "Purple;Pink;Red;White",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2009 DowianA\n2009 DowianA\n2009 DowianA",
    photocredit02: "©2015 Jon Sullivan",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Grevillea robusta",
    common_name: "Silk oak",
    family_name: "Proteaceae",
    plantType: "Tree (evergreen)",
    bloomTime: "Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "Full Grown Tree",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    habitat_value: "Cover;Nesting",
    associated_wildlife: "Birds",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2016 Zoya Akulova \n©2016 Zoya Akulova",
    photocredit02:
      "© 2016 Zoya Akulova\n© 2016 Zoya Akulova \n©2016 Zoya Akulova\n©2016 Zoya Akulova\n©2016 Zoya Akulova"
  },
  {
    latin_name: "Acer circinatum",
    common_name: "Vine maple tree",
    family_name: "Sapindaceae",
    plantType: "Tree (evergreen)",
    bloomTime: "Spring",
    flower_color: "White;Red;Green;Purple",
    sizeAtMaturity: "Full Grown Tree",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Riparian;Wetland;Woodland",
    suitableSiteConditions: "Part shade;Shade",
    soil_type: "Loam",
    waterNeeds: "Moderate",
    habitat_value: "Cover;Nesting",
    associated_wildlife: "Birds",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01:
      "©2009 Debbie Ballentine\n2009 Debbie Ballentine\n2009 Debbie Ballentine",
    photocredit02: "©2011 Phillip"
  },
  {
    latin_name: "Pleuropogon californicus",
    common_name: "semaphore grass",
    family_name: "Poaceae",
    plantType: "Grass",
    bloomTime: "Spring;Summer",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Wetland;Woodland;Riparian",
    suitableSiteConditions: "Sun;Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Moderate",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2009 Jason Krantz\n2009 Jason Krantz",
    photocredit02: "©2011 Zoya Akulova",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Arctostaphylos 'Emerald Carpet'",
    common_name: "Emerald Carpet",
    family_name: "Ericaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Winter",
    flower_color: "White",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Loam;Sand",
    waterNeeds: "None",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2010 Pluckytree\n2010 pLuckytree \n2010 pLuckytree",
    photocredit02: "©2010 Pluckytree \n2010 pLuckytree \n2010 pLuckytree",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Ceiba speciosa-Chorisia speciosa",
    common_name: "Silk Floss Tree",
    family_name: "Bombacaceae",
    former_latin_name: " Chorisia speciosa",
    plantType: "Tree (deciduous)",
    bloomTime: "Winter",
    flower_color: "Pink;White",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    habitat_value: "Cover;Nesting",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2015 Forest and Kim Starr",
    photocredit02: "©2010 Erick Lux\n©2010 Erick Lux",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Cistus x skanbergii",
    common_name: "Pink rockrose",
    family_name: "Cistaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring",
    flower_color: "Pink",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2005 Eric Hunt",
    photocredit02:
      "©2015 Lazaregagndize\n2015 Lazaregagnidze\n2015 Lazaregagnidze\n2015 Lazaregagnidze\n2015 Lazaregagnidze\n2015 Lazaregagnidze\n2015 Lazaregagnidze\n2015 Lazaregagnidze\n2015 Lazaregagnidze\n2015 Lazaregagnidze\n2015 Lazaregagnidze\n2015 Lazaregagnidze",
    additional_species_cultivars_varieties: "\n2015 Lazaregagnidze",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Pelargonium graveolens",
    common_name: "Scented Geranium\n  Scented Geranium",
    family_name: "Geraniaceae",
    plantType: "Perennial",
    bloomTime: "Spring",
    flower_color: "Pink",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Loam;Sand;Clay",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2013 Sean O'Hara",
    photocredit02: "©2011 Forest and Kim Starr",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Euphorbia myrsinites",
    common_name: "Myrtle Spurge",
    family_name: "Euphorbiaceae",
    plantType: "Succulent",
    bloomTime: "Spring;Winter",
    flower_color: "Green;Yellow",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2013 FarOutFlora",
    photocredit02: "©2014 Lotus Johnson\n2014 Lotus Johnson",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Phacelia campanularia",
    common_name: "CA. Desert Bluebells\nBluebells",
    family_name: "Boraginaceae",
    plantType: "Annual",
    bloomTime: "Spring",
    flower_color: "Blue",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    plant_communities: "Chaparral",
    suitableSiteConditions: "Sun",
    soil_type: "Loam;Sand",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2010 Steven Thorsted",
    photocredit02: "©2010 Steven Thorsted",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Lavandula x. intermedia 'Grosso'",
    common_name: "Purple Lavander",
    family_name: "Lamiaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Summer;Fall",
    flower_color: "Indigo",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies;Bees;Hummingbirds",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2014 Couleur Lavande",
    photocredit02:
      "©2009American College of Heathcare Sciences\n2009 American College of Heathcare Sciences",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Populus fremontii",
    common_name: "Fremont's Cottonwood",
    family_name: "Salicaceae",
    plantType: "Shrub (deciduous)",
    bloomTime: "Spring",
    flower_color: "White",
    sizeAtMaturity: "Full Grown Tree",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun",
    soil_type: "Loam;Sand",
    waterNeeds: "Moderate",
    habitat_value: "Cover;Nesting",
    associated_wildlife: "butterflies;birds;bees",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01:
      "©2014 Bri Weldon\n2014 Bri Weldon\n2014 Bri Weldon\n2014 Bri Weldon",
    photocredit02:
      "©2014 Bri Weldon\n2014 Bri Weldon\n2014 Bri Weldon\n2014 Bri Weldon",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Dietes spp.",
    common_name: "fortnight lily",
    family_name: "Iridaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer;Fall",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Low",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2012 Zoya Akulova",
    photocredit02:
      "© 2008 Elizabeth Donoghue\n2008 Elizabeth Donoghue  \n2008 Elizabeth Donoghue\n2008 Elizabeth Donoghue",
    additional_species_cultivars_varieties: "bicolor, iridioides",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Daphne odora",
    common_name: "Winter Daphne",
    family_name: "Thymelaeaceae",
    plantType: "Shrub (evergreen)",
    flower_color: "Pink",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Part Shade",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    additional_characteristices_notes:
      "All parts of this species are toxic and may be fatal if ingested.",
    photocredit01: "©2010 Henry Figueroa",
    photocredit02: " ©2010 Bri Weldon\n   2010 Bri Weldon\n2010 Bri Weldon",
    thrifty150: "Yes",
    thrifty150_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Festuca glauca",
    common_name: "Common Blue Fescue",
    family_name: "Poaceae",
    plantType: "Grass",
    bloomTime: "Summer",
    flower_color: "Blue;Silver;White",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2016 Zoya Akulova",
    photocredit02:
      "©2013 Peggy A. Lopipero-Langmo\n2013 Peggy A. Lopipero-Langmo",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Helleborus foetidus",
    common_name: "Bear's foot hellebore",
    family_name: "Ranunculaceae",
    plantType: "Perennial",
    bloomTime: "Spring",
    flower_color: "Green;Purple",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Loam",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01:
      "©2014 Ferran Turmo Gort\n2014 Ferran Turmo Gort\n2014 Ferran Turmo Gort\n2014 Ferran Turmo Gort",
    photocredit02: "©2011 Joan Simon",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Coreopsis lanceolata",
    common_name: "Lanceleaf Tickseed\nTickseed",
    family_name: "Asteraceae",
    plantType: "Perennial",
    bloomTime: "Spring",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2013 Richard Spellenberg",
    photocredit02: "©2013 Richard Spellenberg ",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Prunus lyonii",
    common_name: "Catalina Cherry",
    family_name: "Rosaceae",
    former_latin_name: "Prunus ilicifolia ssp. Lyonii",
    plantType: "Tree (evergreen)",
    bloomTime: "Spring",
    flower_color: "White",
    sizeAtMaturity: "13-24 ft",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun;Shade",
    waterNeeds: "Low",
    habitat_value: "Cover;Nesting",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2011 Bri Weldon\n2011 Bri Weldon",
    photocredit02: "©2011 Bri Weldon\n2011 Bri Weldon",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Santolina rosmarinifolia",
    common_name: "Green Santolina",
    family_name: "Asteraceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01:
      "©2009 Drew Avery\n2009 Drew Avery\n2009 Drew Avery\n2009 Drew Avery",
    photocredit02: "©2011 Andrey Zharkikh",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Zelkova serrata",
    common_name: "Japanese zelkova",
    family_name: "Ulmaceae",
    plantType: "Tree (deciduous)",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    habitat_value: "Cover;Nesting",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2015 Zoya Akulova",
    photocredit02: "©2015 Zoya Akulova\n©2015 Zoya Akulova",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Ulmus wilsoniana ‘Prospector’",
    common_name: "Wilson Elm",
    family_name: "Ulmaceae",
    plantType: "Tree (deciduous)",
    bloomTime: "Spring",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    habitat_value: "Cover;Nesting",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2010 Susan Bentz\n2010 Susan Bentz",
    photocredit02: "©2010 Ptelea",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Festuca spp.",
    common_name: "Fescue",
    family_name: "Poaceae",
    plantType: "Grass",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2009 Forest and Kim Starr",
    photocredit02: "©2011 Bri Weldon\n2011 Bri Weldon",
    additional_species_cultivars_varieties:
      "californica, cinerea, glauca, idahoensis, rubra, amethystina,muelleri, 'Point Molate'",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Pittosporum tobira",
    common_name: "mock orange",
    family_name: "Pittosporaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring",
    flower_color: "White",
    sizeAtMaturity: "7-12 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    habitat_value: "Pollinator;Cover;Nesting",
    associated_wildlife: "Bees",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01:
      "©2016 Zoya Akulova\n ©2016 Zoya Akulova\n ©2016 Zoya Akulova\n ©2016 Zoya Akulova",
    photocredit02: "©2016 Zoya Akulova\n ©2016 Zoya Akulova"
  },
  {
    latin_name: "Artemisia schmidtiana 'Silvermound'",
    common_name: "\nAngel's Hair\"",
    family_name: "Asteraceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "White",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam;Clay",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies;Bees",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2016 Alicia Garbelman\n2016 Alicia Garbelman",
    photocredit02: "©2011 C-S-M",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Magnolia virginiana",
    common_name: "Sweet bay magnolia",
    family_name: "Magnoliaceae",
    plantType: "Tree",
    bloomTime: "Summer",
    flower_color: "white;yellow",
    sizeAtMaturity: "Full Grown Tree",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Moderate",
    habitat_value: "Cover;Nesting",
    associated_wildlife: "Birds",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01:
      "© 2010 Karen Christine Hibbard\n\n\n2010, Karen Christine Hibbard \n2010, Karen Christine Hibbard\n2010, Karen Christine Hibbard \n2010, Karen Christine Hibbard",
    photocredit02:
      "©2010 Karen Christine Hibbard\n2010, Karen Christine Hibbard"
  },
  {
    latin_name: "Quercus macrocarpa",
    common_name: "bur oak",
    family_name: "Fagaceae",
    plantType: "Tree (deciduous)",
    bloomTime: "Spring",
    flower_color: "Yellow",
    sizeAtMaturity: "Full Grown Tree",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Moderate",
    habitat_value: "Pollinator;Cover;Nesting",
    associated_wildlife: "Butterflies",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01:
      "©2010 Joshua Mayer\n 2010 Joshua Mayer\n2010 Joshua Mayer\n2010 Joshua Mayer\n2010 Joshua Mayer",
    photocredit02: "©2009 Justin Meissen",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Clematis armandii",
    common_name: "Evergreen Clematis",
    family_name: "Ranunculaceae",
    plantType: "Vine",
    bloomTime: "Spring;Winter",
    flower_color: "White",
    sizeAtMaturity: "7-12 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    habitat_value: "Pollinator;Cover;Nesting",
    associated_wildlife: "Birds;bees;butterflies",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2012 Manuel\n2012 Manuel",
    photocredit02: "©2010 Manuel\n2012 Manuel",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Kniphofia 'Dwarf Yellow'",
    common_name: "Poker Plant",
    family_name: "Asphodelaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Hummingbirds",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2006 David Midgley",
    photocredit02: "©2010 Megan Hansen\n2010 Megan Hansen",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Calystegia purpurata",
    common_name: "morning glory",
    family_name: "Convolvulaceae",
    plantType: "Vine",
    bloomTime: "Spring;Summer;Fall",
    flower_color: "White;Ourple",
    sizeAtMaturity: "7-12 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "coastal scrub;chaparral",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Sand",
    waterNeeds: "None",
    habitat_value: "Cover;Nesting",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2013 Paul Asman and JIll Lenoble",
    photocredit02:
      "©2013 Jeb Bjerke\nJeb Bjerke, 2013 \nJeb Bjerke, 2013 \nJeb Bjerke, 2013\nJeb Bjerke, 2013 \nJeb Bjerke, 2013 \nJeb Bjerke, 2013 \nJeb Bjerke, 2013",
    special_list: "Top 20",
    habitat: "Yes",
    habitat_int: "1",
    top20_int: "1"
  },
  {
    latin_name: "Hesperaloe parviflora",
    common_name: "Yellow/Red Yucca",
    family_name: "Agavaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "Red",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part shade",
    soil_type: "Sand",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Birds",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2008Tavi P",
    photocredit02: "©2009 Michael Bechtold\n2009 Michael Bechtold",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Thymus pseudolanuginosus",
    common_name: "Wooly thyme",
    family_name: "Lamiaceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "Pink",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand",
    pruning_needs: "Minimal",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01:
      "© 2007 Kingsbrae Garden\n2007 Kingsbrae Garden\n\n2007 Kingsbrae Garden\n\n2007 Kingsbrae Garden\n\n2007 Kingsbrae Garden",
    photocredit02:
      "© 2008 Patrick Standish\n2008 Patrick Standish\n2008 Patrick Standish",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Carissa macrocarpa",
    common_name: "Natal Plum",
    family_name: "Apocynaceae",
    plantType: "Shrub (evergreen)",
    flower_color: "White",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2013 Zoya Akulova",
    photocredit02:
      " ©2009 Forest and Kim Starr\n2009 Forest and Kim Starr2009 Forest and Kim Starr\n2009 Forest and Kim Starr",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Acacia cognata 'Cousin Itt'",
    common_name: "Bower Wattle",
    family_name: "Mimosaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Shade",
    waterNeeds: "Low",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2015 KM",
    photocredit02: "©2009 Graibeard\n2009 Graibeard\n2009 Graibeard",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Nolina bigelovii",
    common_name: "Bear Grass",
    family_name: "Ruscaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Summer",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2013 Lon&Queta\n2013 Lon&Queta",
    photocredit02: "©2006Larry Hogue",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Sedum dendroideum",
    common_name: "Bush Sedum  \n Bush Sedum",
    family_name: "Crassulaceae",
    plantType: "Succulent",
    bloomTime: "Spring;Winter",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    appropriateLocation: "Roof;Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2009 Zoya Akulova",
    photocredit02:
      "©2005 The Ruth Bancroft Garden\n2005 The Ruth Bancroft Garden",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Diospyros virginiana",
    common_name: "persimmon",
    family_name: "Ebenaceae",
    plantType: "Tree (deciduous)",
    bloomTime: "Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade;Shade",
    soil_type: "Clay;Loam;sand",
    waterNeeds: "Low",
    habitat_value: "Fruit;Cover;Nesting",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2007 Scott Zona",
    photocredit02: "© 2015 Will Pollard\n2015 Will Pollard  \n2015 Will Pollard"
  },
  {
    latin_name: "Lychnis coronaria            ",
    common_name: "Rose Campion",
    family_name: "Caryophyllaceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "Magenta",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part shade",
    soil_type: "Clay;Sand;Loam;Rock",
    waterNeeds: "Low",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2011 Stefano",
    photocredit02: "©2008 Pony Lauricella\n2008 Pony Lauricella",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Lavandula multifida",
    common_name: "Fern Leaf Lavender",
    family_name: "Lamiaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Summer;Fall",
    flower_color: "Indigo",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies;Bees;Hummingbirds",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01:
      "©2006 Forest and Kim Starr\n2006 Forest and Kim Starr\n2006 Forest and Kim Starr",
    photocredit02:
      "©2006 Forest and Kim Starr\n2006 Forest and Kim Starr\n2006 Forest and Kim Starr",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Myrtus communis compacta",
    common_name: "Dwarf Myrtle",
    family_name: "Myrtaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;summer",
    flower_color: "White",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Fruit;Pollinator",
    associated_wildlife: "Birds",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2011 Bri Weldon\n2011 Bri Weldon",
    photocredit02: "©2011 Bri Weldon",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Thymus 'Victor Reiter'",
    common_name: "Victor Reiter Thyme",
    family_name: "Lamiaceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "Pink",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Bees",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01:
      "©2014 Andreas Rockstein\n2014 Andreas Rockstein\n2014 Andreas Rockstein",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Polypodium scouleri",
    common_name: "leather leaf fern",
    family_name: "Polypodiaceae",
    plantType: "Fern",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "woodland",
    suitableSiteConditions: "Part shade;Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Low",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2009 Tab Tannery",
    photocredit02:
      "©2012 John Game\n2012 John Game\n2012 John Game\n2012 John Game\n2012 John Game",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Stachys byzantina",
    common_name: "Lambs ears",
    family_name: "Lamiaceae",
    plantType: "Perennial",
    bloomTime: "Spring",
    flower_color: "Magenta",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "None",
    associated_wildlife: "Hummingbirds",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01:
      "© 2007 Carl Lewis\n2007 Carl Lewis\n2007 Carl Lewis\n2007 Carl Lewis\n2007 Carl Lewis",
    photocredit02: "©2008 Dauvit Alexander",
    thrifty150: "Yes",
    thrifty150_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Dudleya spp.",
    common_name: "Live Forever",
    family_name: "Crassulaceae",
    plantType: "Succulent",
    sizeAtMaturity: "Varies",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2014 FarOutFlora\n2014 FarOutFlora",
    photocredit02: "©2012 Anthony Mendoza",
    additional_species_cultivars_varieties:
      "brittonii, caespitosa, pulverulenta, farinosa",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Gaura lindheimeri",
    common_name: "Butterfly Gaura",
    family_name: "Onagraceae",
    former_latin_name: "Oenothera lindheimeri",
    plantType: "Perennial",
    bloomTime: "Summer;Fall",
    flower_color: "White;pink",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "Moderate",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2012 Stefano",
    photocredit02: "©2012 Stefano\n2012 Stefano",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Leucadendron hybrids",
    common_name: "Leucadendron",
    family_name: "Proteaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Summer;Winter",
    flower_color: "Burgundy",
    sizeAtMaturity: "7-12 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    habitat_value: "Cover;Nesting",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2011 FarOutFlora",
    photocredit02: "©2010 Laura Blanchard\n2010 Laura Blanchard",
    additional_species_cultivars_varieties: "'Jester', 'Safari Sunset'",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Tanacetum haradjanii",
    common_name: "Silver Tansy",
    family_name: "Asteraceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam;Clay",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2007 Dusanima",
    photocredit02: "©2008 Klasse Im Garten\n2008 Klasse Im Garten",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Salvia chamaedryoides",
    common_name: "Germander Sage  \n Germander Sage  \n Germander Sage",
    family_name: "Lamiaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer;Fall;Winter",
    flower_color: "Indigo",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Shade",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies;Bees;Hummingbirds",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2012 Zoya Akulova",
    photocredit02: "©2009 Zoya Akulova",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Monardella spp.",
    common_name: "Scarlet Monardella",
    family_name: "Lamiaceae",
    plantType: "Perennial",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Birds",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2013 J. Maughn\n2013 J. Maughn 2013 J. Maughn",
    photocredit02: "©2013 J. Maughn\n2013 J. Maughn2013 J. Maughn",
    additional_species_cultivars_varieties:
      "macrantha, odoratissima,  villosa, villosa  franciscana",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Festuca muelleri",
    common_name: "Mueller's Fescue",
    family_name: "Poaceae",
    plantType: "Grass",
    bloomTime: "Summer",
    flower_color: "Brown",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "www.smgrowers.com",
    photocredit02: "©2015 Russel Best\n2015 Russell Best \n2015 Russell Best",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Acer x freemanii",
    common_name: "Autumn Blaze Maple, Freeman Maple",
    family_name: "Aceraceae",
    plantType: "Tree (deciduous)",
    bloomTime: "Spring",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Moderate",
    habitat_value: "Cover;Nesting",
    appropriateLocation: "Sidewalk",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2009 MPRB Forestry",
    photocredit02: "©2007 Michael Dawes\n2007 Michael Dawes",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Woodwardia frimbiata",
    common_name: "Giant chain fern",
    family_name: "Blechnaceae",
    plantType: "Fern",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Riparian;wetland",
    suitableSiteConditions: "Part shade;Shade",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Moderate",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "© 2014 FaroutFlora\n2014 FaroutFlora \n2014 FaroutFlora",
    photocredit02: "©2014 John Rusk",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Fuchsia procumbens",
    common_name: "Prostrate fuschia",
    family_name: "Onagraceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Summer;Fall",
    flower_color: "Purple;Green;Yellow",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Part Shade",
    habitat_value: "Pollinator",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2009 Tony Foster\n2009 Tony Foster\n2009 Tony Foster",
    photocredit02: "©2011 FarOutFlora",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Cerastium tomentosum",
    common_name: "Snow-in-Summer",
    family_name: "Caryophyllaceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "White",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Sand;Rock;Loam",
    waterNeeds: "None",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2016 Stefano",
    photocredit02: "©2010 Phil Sellens\n2010 Phil Sellens",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Schoenoplectus americanus",
    common_name: "three square",
    family_name: "Cyperaceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Wetland;Riparian",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam",
    waterNeeds: "Moderate",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01:
      "©2015 Zoya Akulova\n2015 Zoya Akulova \n2015 Zoya Akulova \n2015 Zoya Akulova \n2015 Zoya Akulova \n2015 Zoya Akulova \n2015 Zoya Akulova \n2015 Zoya Akulova",
    photocredit02: "©2013 Dendroica Cerulea",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Calamagrostis x acutiflora",
    common_name: "Feather Reed Grass",
    family_name: "Poaceae",
    plantType: "Grass",
    bloomTime: "Summer",
    flower_color: "Purple",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part shade",
    waterNeeds: "Low",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2009 Daryl Mitchell",
    photocredit02: "©2008 Patrick Standish\n2008 Patrick Standish",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Lepechinia calycina",
    common_name: "White Pitcher Sage, Woodbalm, Pitcher Sage",
    family_name: "Lamiaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Summer",
    flower_color: "White",
    sizeAtMaturity: "7-12 ft",
    climate_appropriate_plants: "SF Native",
    plant_communities: "Woodland,Chaparral",
    suitableSiteConditions: "Sun",
    soil_type: "Loam;Sand",
    waterNeeds: "None",
    habitat_value: "Cover;Nesting",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2006 Steve Matson",
    photocredit02: "©2006 Steve Matson\n2015 Enbodenumer",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Jasminum polyanthum",
    common_name: "Pink Jasmine",
    family_name: "Oleaceae",
    plantType: "Vine",
    bloomTime: "Spring;Winter",
    flower_color: "White",
    sizeAtMaturity: "13-24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Shade",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01:
      "©2008 Forest and Kim Starr\n2008 Forest and Kim Starr2008 Forest and Kim Starr",
    photocredit02: "©2012 Forest and Kim Starr\n2008 Forest and Kim Starr",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Miscanthus sinensis",
    common_name: "\tJapanese silver grass",
    family_name: "Poaceae",
    plantType: "Grass",
    bloomTime: "Summer",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand",
    waterNeeds: "Moderate",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©Nick Kurzenko",
    photocredit02: "©Nick Kurzenko \nNick Kurzenko"
  },
  {
    latin_name: "Chondropetalum tectorum",
    common_name: "cape rush",
    family_name: "Restionaceae",
    plantType: "Grass",
    bloomTime: "Spring;Summer;Fall;Winter",
    flower_color: "Brown",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    soil_type: "Clay;Loam",
    waterNeeds: "Low",
    stormwater_benefit: "Yes",
    stormwater_int: "1",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2011 FarOutFlora",
    photocredit02: "©2011 FarOutFlora\n2011 FarOutFlora"
  },
  {
    latin_name: "Alyogyne  huegelii",
    common_name: "Blue Hibiscus",
    family_name: "Malvaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Summer;Fall;Winter",
    flower_color: "Purple;Blue",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Birds",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2012 Stefano\n2012 Stefano",
    photocredit02: "©2005 Reciprocity D Parture",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Macfadyena unguis-cati",
    common_name: "Yellow Trumpet Vine",
    family_name: "Bignoniaceae",
    plantType: "Vine",
    bloomTime: "Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "> 24 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    habitat_value: "Cover;Nesting",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©1998 Forest and Kim Starr",
    photocredit02:
      "©2006 Forest and Kim Starr\n2006 Forest and Kim Starr\n2006 Forest and Kim Starr",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Correa spp.",
    common_name: "Australian Fuchsia",
    family_name: "Rutaceae",
    plantType: "Shrub (evergreen)",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "Birds;Bees",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2015 Zoya Akulova",
    photocredit02: " 2006 Idmaer Saxon\n2006 Idmaer Saxon\n2006 Idmaer Saxon",
    additional_species_cultivars_varieties:
      "indivisa, pulchella, x. 'Ray's Tangerine', 'Dusky Bells','Ivory Bells'",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Abutilon palmeri",
    common_name: "Indian Mallow",
    family_name: "Malvaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring",
    flower_color: "Orange",
    sizeAtMaturity: "4-6 ft",
    climate_appropriate_plants: "CA Native",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Birds",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2015 Jean Pawek\n\n©2015 Jean Pawek",
    photocredit02: "©2015 Jean Pawek",
    habitat: "Yes",
    habitat_int: "1",
    thrifty150: "Yes",
    thrifty150_int: "1"
  },
  {
    latin_name: "Salvia coahuilensis",
    common_name: "Coahuila Sage",
    family_name: "Lamiaceae",
    plantType: "Perennial",
    bloomTime: "Summer;Fall",
    flower_color: "Indigo",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies;Bees;Hummingbirds",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2010 Mathesont\n2010 Mathesont",
    photocredit02: "©2008 Scott Zona",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Echeveria pumila glauca",
    common_name: "Hens and Chicks",
    family_name: "Crassulaceae",
    plantType: "Succulent",
    bloomTime: "summer",
    flower_color: "red;yellow",
    sizeAtMaturity: "< 1 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01:
      "©2011 Forest and Kim Starr\n2011 Forest and Kim Starr\n2011 Forest and Kim Starr",
    photocredit02: "©2011 Forest and Kim Starr\n2011 Forest and Kim Starr",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Teucrium x lucidrys",
    common_name: "Wall Germander",
    family_name: "Lamiaceae",
    former_latin_name: "Teucrium chamaedrys",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "Magenta",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Clay;Loam;Sand;Rock",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Butterflies;bees",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2015 Enbodenumer\n2015 Enbodenumer\n2015 Enbodenumer",
    photocredit02: "©2015 Enbodenumer\n2015 Enbodenumer\n2015 Enbodenumer",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Cistus salvifolius 'Prostratus'",
    common_name: "Sageleaf Rockrose",
    family_name: "Cistaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring",
    flower_color: "White",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01:
      "©2011 Peter A. Mansfield\n2011 Peter A. Mansfield\n2011 Peter A. Mansfield",
    photocredit02: "©2011 Sarah Murray\n2011 Peter A. Mansfield",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Acanthus mollis",
    common_name: "Bear's Breech",
    family_name: "Acanthaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "White;Pink;Purple",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Part shade;Shade",
    waterNeeds: "Low",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2011 Stefano\n2011 Stefano",
    photocredit02: "©2011 Stefano\n2011 Stefano",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Knifphofia galpinii 'Orange Flame'",
    common_name: "Orange Flame Poker Plant",
    family_name: "Asphodelaceae",
    plantType: "Perennial",
    bloomTime: "Spring;Summer",
    flower_color: "Orange",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Sand;Loam",
    waterNeeds: "None",
    habitat_value: "Pollinator",
    associated_wildlife: "Hummingbirds",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2014 Anne Dunham\n2014 Anne Dunham",
    photocredit02: "©2009 M. Fletcher\n2009 M. Fletcher",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  },
  {
    latin_name: "Helianthus 'Lemon Queen'",
    common_name: "Lemon Queen",
    family_name: "Asteraceae",
    plantType: "Perennial",
    bloomTime: "Summer",
    flower_color: "Yellow",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun",
    soil_type: "Clay",
    waterNeeds: "Low",
    habitat_value: "Pollinator",
    associated_wildlife: "hummingbirds;Butterflies;bees",
    appropriateLocation: "Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2007 underwaterer",
    photocredit02: "©2009 Klasse Im Garten\n2009 Klasse Im Garten",
    habitat: "Yes",
    habitat_int: "1"
  },
  {
    latin_name: "Escallonia 'Compakta'",
    family_name: "Escalloniaceae",
    plantType: "Shrub (evergreen)",
    bloomTime: "Spring;Fall",
    flower_color: "Pink;Red",
    sizeAtMaturity: "1-3 ft",
    climate_appropriate_plants: "Exotic",
    suitableSiteConditions: "Sun;Part Shade",
    waterNeeds: "Low",
    associated_wildlife: "Bees",
    appropriateLocation: "Sidewalk;Garden",
    suggested_green_connection_routes: "NA",
    street_tree_list: "NA",
    photocredit01: "©2009 Stanislav Sedov\n2009 Stanislav Sedov",
    photocredit02: "©2013 Tortie tude",
    habitat: "Yes",
    habitat_int: "1",
    sidewalk_landscaping_plants: "Yes",
    sidewalk_landscaping_plants_int: "1"
  }
];

export default items;
